import { Component, OnInit, Input } from '@angular/core';

import { ILibrary } from '@dink/core/models/library.model';


@Component({
  selector: 'dwa-sidebar-tree',
  templateUrl: './sidebar-tree.component.html',
  styleUrls: ['./sidebar-tree.component.sass']
})
export class SidebarTreeComponent implements OnInit {

  @Input() data: ILibrary[];
  @Input() color: string;


  constructor() {
  }


  ngOnInit() {
  }

}
