import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IAlertOption } from '@dink/core/models/alert.model';

import { AlertComponent } from '@dink/shared/components/dialogs/alert/alert.component';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private dialog: MatDialog) {
  }

  open<T>(title: string, message: string, options: IAlertOption[]): Promise<T> {
    const reference = this.dialog.open(AlertComponent, {
      width: '300px',
      // height: '275px',
      disableClose: true,
      closeOnNavigation: false,
      panelClass: 'dwa-confirm-dialog-wrapper',
      data: { title, message, options }
    });

    return reference.afterClosed().toPromise();
  }

}
