<div class="dwa-content-main dwa-crm">
  <div class="dwa-content-header">
    <div class="dwa-breadcrumb">
      <a class="dwa-breadcrumb-link" [routerLink]="['/dashboard']" [style.color]="enterprise?.applicationSettings?.titleTextColor">
        {{'SIDEBAR.ITEMS.DASHBOARD' | translate}}
      </a>
      <span class="dwa-breadcrumb-separator">/</span>
      <p class="dwa-breadcrumb-text">{{'SIDEBAR.ITEMS.CRM' | translate}}</p>
    </div>
  </div>

  <div class="dwa-crm-content">
    <div class="dwa-crm-login" *ngIf="login else show">
      <div class="dwa-crm-login-form">
        <label class="dwa-field">
          <span class="dwa-field-label">{{'CRM.LOGIN.USERNAME' | translate}}</span>
          <input class="dwa-field-input" autofocus autocomplete="on" type="email"
          placeholder="{{'CRM.LOGIN.USERNAME' | translate}}" [disabled]="loading" [(ngModel)]="credentials.username">
        </label>

        <label class="dwa-field">
          <span class="dwa-field-label">{{'CRM.LOGIN.PASSWORD' | translate}}</span>
          <input class="dwa-field-input" autocomplete="on" type="password"
          placeholder="{{'CRM.LOGIN.PASSWORD' | translate}}" [disabled]="loading" [(ngModel)]="credentials.password">
        </label>

        <button class="dwa-button" (click)="onLoginClick()" [disabled]="loading">
          {{'CRM.LOGIN.SUBMIT' | translate}}
        </button>
      </div>
    </div>
    <ng-template #show>
      <iframe #iframe class="dwa-crm-iframe" *ngIf="url" [src]="url"></iframe>
    </ng-template>
  </div>

</div>
