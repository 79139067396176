import { Directive, Input, HostListener, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

import { INewsArticle } from '@dink/core/models/news.model';


@Directive({
  selector: '[dwaArticleLink]'
})
export class ArticleLinkDirective {

  private article: INewsArticle;


  @HostBinding('attr.href') href: string;
  @HostBinding('attr.target') target: string;

  @Input('dwaArticleLink') set input (article: INewsArticle) {
    if (article) {
      this.article = article;

      if (article.externalURL) {
        this.href = article.externalURL;
        this.target = '_blank';
      }
    }
  }


  constructor(private router: Router) {
  }


  @HostListener('click') async onClick() {
    if (!this.article.externalURL) {
      this.router.navigate([ '/news', this.article.id ]);
    }
  }

}
