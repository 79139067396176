import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { IEnterprise } from '@dink/core/models/enterprise.model';
import { DataService } from '@dink/core/services/data.service';


@Component({
  selector: 'dwa-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.sass']
})
export class ViewComponent implements OnInit {

  enterprise$: Observable<IEnterprise>;


  constructor(private data: DataService) {
  }


  ngOnInit() {
    this.enterprise$ = this.data.getEnterprise();
  }

}
