import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { appAnimations } from './app.animations';


@Component({
  selector: 'dwa-root',
  templateUrl: 'app.component.html',
  animations: [ appAnimations ]
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'D!nk Webapp';


  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }


  ngOnInit() {
  }

  ngAfterViewInit() {
    const banner = new SmartBanner({
      daysHidden: 30,
      daysReminder: 90,
      appStoreLanguage: 'us',
      title: 'dink - Engage. Prove. Close.',
      author: 'd!nk',
      icon: '/assets/images/android-icon.png'
    });

    if ((<any>banner).type === 'android') {
      console.log('{app} BANNER', banner);
    }
  }


  getState(outlet: RouterOutlet) {
    return outlet.activatedRouteData.state;
  }

}
