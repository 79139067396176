import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ContentDownloadEventType } from '@dink/core/enums/content-download-event-type.enum';
import { IContent } from '@dink/core/models/content.model';
import { PublicationDownloadService } from '@dink/core/services/publication-download.service';

@Component({
  selector: 'dwa-content-progress-bar',
  templateUrl: './content-progress-bar.component.html',
  styleUrls: ['./content-progress-bar.component.sass']
})
export class ContentProgressBarComponent implements OnInit, OnDestroy {

  isDownloading: boolean = false;
  isAvailable: boolean = false;
  @Input() publication: IContent;

  // See https://medium.com/angular-in-depth/how-to-create-a-memory-leak-in-angular-4c583ad78b8b
  private $destroy: Subject<void> = new Subject<void>();

  constructor(private downloadService: PublicationDownloadService) {
  }


  ngOnInit() {

    this.downloadService.contentIsAvailable(this.publication).then(x => this.isAvailable = x);
    this.isDownloading = this.downloadService.isCurrentlyDownloading(this.publication.currentContentVersion.id);

    this.downloadService.downloadEvents$.pipe(takeUntil(this.$destroy)).subscribe(evt => {
      if (evt.content.id == this.publication.id) {
        if (evt.eventType == ContentDownloadEventType.STARTED) {
          this.isDownloading = true;
        } else if (evt.eventType == ContentDownloadEventType.FAILED) {
          console.log("Download failed");
          this.isDownloading = false;
          this.isAvailable = false;
        } else {
          this.isDownloading = false;
          this.isAvailable = true;
        }
      }
    });
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

}
