<div class="dwa-sidebar" *ngIf="enterprise$ | async as enterprise" [style.background-color]="enterprise.applicationSettings?.toolbarColor">

  <a class="dwa-sidebar-logo" [routerLink]="['/dashboard']" [style.background-color]="enterprise.applicationSettings?.toolbarColor">
    <img *ngIf="enterprise" class="dwa-sidebar-logo-image" [src]="enterprise.adminLogo | fixImage">
  </a>

  <div class="dwa-sidebar-list">
    <div class="dwa-sidebar-item open-sub-1" routerLinkActive="active"
        [style.border-top-color]="enterprise.applicationSettings?.toolbarExpandedColor">
      <a [routerLink]="['/', 'libraries']" style="display:none"></a>

      <div class="dwa-sidebar-item-link disabled" [style.color]="enterprise.applicationSettings?.libraryNamesColor">
        <svg-icon-sprite class="dwa-sidebar-item-icon" src="/assets/svg/sprite1.svg#kiosks"></svg-icon-sprite>
        <span class="dwa-sidebar-item-text">{{'SIDEBAR.ITEMS.KIOSKS' | translate}}</span>

        <span class="dwa-sidebar-item-link-background"
            [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"></span>
        <span class="dwa-sidebar-item-link-arrow"
            [style.border-color]="enterprise.applicationSettings?.toolbarColor"
            [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"></span>
      </div>

      <div class="dwa-sidebar-sublist-1" [style.height]="height" [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"
          [style.border-top-color]="enterprise.applicationSettings?.toolbarColor">
        <ng-container *ngIf="libraries$ | async as libraries; else loading">
          <ng-container *ngIf="libraries.length > 0; else empty">
            <div *ngFor="let library of libraries; let j = index" class="dwa-sidebar-sublist-1-item"
                [class.open-sub-2]="library.sublibraries?.length > 0" [class.last]="j === libraries.length - 1">
              <a class="dwa-sidebar-sublist-1-link" [routerLink]="['/', 'libraries', library.id]"
                  #rla1="routerLinkActive" routerLinkActive="active" [style.color]="enterprise.applicationSettings?.libraryNamesColor"
                  [style.background-color]="rla1.isActive && enterprise ? enterprise.applicationSettings?.toolbarColor : null">
                <dwa-image class="dwa-sidebar-sublist-1-image" [path]="library.imageUrl | fixImage"></dwa-image>
                {{ library.displayName }}
              </a>

              <div *ngIf="library.sublibraries?.length > 0" class="dwa-sidebar-sublist-2">
                <div *ngFor="let sublibrary of library.sublibraries" class="dwa-sidebar-sublist-2-item">
                  <a class="dwa-sidebar-sublist-2-link" [routerLink]="['/', 'libraries', sublibrary.id]" routerLinkActive="active"
                      [style.color]="enterprise.applicationSettings?.libraryNamesColor">
                    <dwa-image class="dwa-sidebar-sublist-2-image" [path]="sublibrary.imageUrl | fixImage"></dwa-image>
                    {{ sublibrary.displayName }}
                  </a>

                  <dwa-sidebar-tree *ngIf="sublibrary.sublibraries.length > 0" [data]="sublibrary.sublibraries"
                    [color]="enterprise.applicationSettings?.libraryNamesColor" class="dwa-sidebar-tree"></dwa-sidebar-tree>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #empty>
              <div class="dwa-sidebar-sublist-1-loader" innerHTML="{{'SIDEBAR.EMPTY' | translate}}"></div>
          </ng-template>
        </ng-container>
        <ng-template #loading>
          <div class="dwa-sidebar-sublist-1-loader">{{'SIDEBAR.LOADING' | translate}}</div>
        </ng-template>
      </div>
    </div>
  </div>

</div>
