import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { DataService } from '@dink/core/services/data.service';
import { CacheService } from '@dink/core/services/cache.service';
import { AuthService } from '@dink/core/services/auth.service';
import { ApiService } from '@dink/core/services/api.service';


@Component({
  selector: 'dwa-favorites-button',
  templateUrl: './favorites-button.component.html',
  styleUrls: ['./favorites-button.component.sass']
})
export class FavoritesButtonComponent implements OnInit, OnDestroy {

  loading = false;
  favorite: boolean;
  cssPrefix = 'favorites-button';
  @Input() publicationKey: string;
  @Input() labels: boolean;

  private subscription: Subscription;


  @Input('class') set cssClass(v: string) {
    this.cssPrefix = v;
  }


  constructor(
    private data: DataService,
    private cache: CacheService,
    private auth: AuthService,
    private api: ApiService,
    private detector: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    if (this.publicationKey) {
      this.subscription = this.data.isFavorite(this.publicationKey).subscribe(f => {
        if (!this.loading) {
          this.favorite = f;
          this.detector.markForCheck();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  async onClick() {
    let updated: boolean;

    this.loading = true;

    try {
      if (this.favorite) {
        updated = await this.api.removeFavorite(this.publicationKey).toPromise();
      } else {
        updated = await this.api.addFavorite(this.publicationKey).toPromise();
      }

      if (updated) {
        const list = await this.api.getFavorites().toPromise();
        this.cache.updateFavorites(list);
      }
    } catch (err) {
      console.log('{favorites-button} ERROR', err);
      this.auth.renew();
    }

    this.loading = false;
  }

}
