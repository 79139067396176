import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'background'
})
export class BackgroundPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(image: string): SafeStyle {
    const url = `url('${image}')`;
    return this.sanitizer.bypassSecurityTrustStyle(url);
  }

}
