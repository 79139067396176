<div class="dwa-content-main dwa-crm" *ngIf="enterprise$ | async as enterprise">
  <div class="dwa-content-header">
    <div class="dwa-breadcrumb">
      <a class="dwa-breadcrumb-link" [routerLink]="['/dashboard']" [style.color]="enterprise.applicationSettings?.titleTextColor">
        {{'SIDEBAR.ITEMS.DASHBOARD' | translate}}
      </a>
      <span class="dwa-breadcrumb-separator">/</span>
      <p class="dwa-breadcrumb-text">{{'SIDEBAR.ITEMS.VIEWER' | translate}}</p>
    </div>
  </div>
</div>
