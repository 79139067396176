import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private sanitize: DomSanitizer) {
  }

  transform(origin: string): SafeResourceUrl {
    return this.sanitize.bypassSecurityTrustResourceUrl(origin);
  }

}
