<div class="wrapper" *ngIf="enterprise$ | async as enterprise">
  <button class="{{cssPrefix}}-button button mail" [class.active]="active || showOptions" [disabled]="loading"
      (click)="onClick()">
    <span class="{{cssPrefix}}-icon icon"></span>
    <div class="{{cssPrefix}}-label label" *ngIf="labels">
      <span *ngIf="!active">{{'PUBLICATION.MAIL.ADD' | translate}}</span>
      <span *ngIf="active">{{'PUBLICATION.MAIL.REMOVE' | translate}}</span>
    </div>
  </button>

  <div *ngIf="showOptions" class="{{cssPrefix}}-submenu">
    <button class="{{cssPrefix}}-submenu-item" [disabled]="loading" (click)="onClick(0)">
      <span class="{{cssPrefix}}-submenu-title">{{ 'PUBLICATION.MAIL.OPTIONS.0' | translate }}</span>
    </button>

    <button class="{{cssPrefix}}-submenu-item" [disabled]="loading" (click)="onClick(1)">
      <span class="{{cssPrefix}}-submenu-title">{{ 'PUBLICATION.MAIL.OPTIONS.1' | translate }}</span>
    </button>
  </div>
</div>
