import { Component, OnInit } from '@angular/core';

import { CacheService } from '@dink/core/services/cache.service';
import { AuthService } from '@dink/core/services/auth.service';


@Component({
  selector: 'dwa-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(
    private cache: CacheService,
    private auth: AuthService
  ) {
  }


  async ngOnInit() {
    await this.cache.updateUser(null);
    await this.cache.reset();

    this.auth.logout();
  }

}
