import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { LocalStorage } from '@ngx-pwa/local-storage';

import { environment } from '@dink/env/environment';

import { ApiService } from '@dink/core/services/api.service';
import { IProfile, IProfileFile } from '@dink/core/models/profile.model';
import { IEnterprise } from '@dink/core/models/enterprise.model';
import { ILibrary } from '@dink/core/models/library.model';
import { INewsArticle } from '@dink/core/models/news.model';
import { ICacheStorage, ICacheGroup, ICacheMap } from '@dink/core/models/cache.model';
import { IContent, IContentAccessibility } from '@dink/core/models/content.model';
import { IMicrositeCacheItem, IMicrositeMessage } from '@dink/core/models/account-hub.model';
import { INotification } from '@dink/core/models/notification.model';
import { orderAlphabetically } from '@dink/core/helpers/sort.helper';


@Injectable({
  providedIn: 'root'
})
export class CacheService {

  readonly status$ = new BehaviorSubject<boolean | null>(null);
  readonly loading$ = new BehaviorSubject<[number, number]>([0, 0]);

  readonly user$ = new BehaviorSubject<string>(null);
  readonly profile$ = new BehaviorSubject<IProfile>(null);
  readonly enterprise$ = new BehaviorSubject<IEnterprise>(null);
  readonly libraries$ = new BehaviorSubject<ILibrary[]>(null);
  readonly news$ = new BehaviorSubject<INewsArticle[]>(null);
  readonly indexes$ = new BehaviorSubject<ICacheGroup>(null);
  readonly recent$ = new BehaviorSubject<IContent[]>(null);
  readonly shared$ = new BehaviorSubject<IMicrositeCacheItem[]>(null);
  readonly mail$ = new BehaviorSubject<IContent[]>(null);
  readonly favorites$ = new BehaviorSubject<IContent[]>(null);
  readonly accessibility$ = new BehaviorSubject<IContentAccessibility>(null);
  readonly message$ = new BehaviorSubject<IMicrositeMessage>(null);
  readonly notifications$ = new BehaviorSubject<INotification[]>(null);

  private cache: ICacheMap;
  private favorites: string[];
  private requests = 0;
  private loaded = false;
  private remaining = 0;
  private total = 0;
  private cancel = false;


  constructor(
    private api: ApiService,
    private storage: LocalStorage,
    private activated: ActivatedRoute
  ) {
    this.initialize();
  }


  async verify() {
    if (!this.loaded) {
      const reload = this.activated.snapshot.queryParamMap.get('reload') || 'false';

      if (reload !== 'true') {
        let ready = true;

        const [profile, enterprise, libraries, news, indexes] = await Promise.all([
          this.retrieve<IProfile>('profile', this.profile$),
          this.retrieve<IEnterprise>('enterprise', this.enterprise$),
          this.retrieve<ILibrary[]>('libraries', this.libraries$),
          this.retrieve<INewsArticle[]>('news', this.news$),
          this.retrieve<ICacheGroup>('indexes', this.indexes$)
        ]);

        const [favorites, mail, recent, shared, message, accessibility, notifications] = await Promise.all([
          this.retrieve<IContent[]>('favorites', this.favorites$),
          this.retrieve<IContent[]>('mail', this.mail$),
          this.retrieve<IContent[]>('recent', this.recent$),
          this.retrieve<IMicrositeCacheItem[]>('shared', this.shared$),
          this.retrieve<IMicrositeMessage>('message', this.message$),
          this.retrieve<IContentAccessibility>('accessibility', this.accessibility$),
          this.retrieve<INotification[]>('notifications', this.notifications$)
        ]);


        if (!profile || !enterprise || !libraries || !news || !indexes) {
          ready = false;
        }

        if (!mail) {
          this.persist<IContent[]>('mail', [], this.mail$);
        }

        if (!recent) {
          this.persist<IContent[]>('recent', [], this.recent$);
        }

        if (!shared) {
          this.persist<IMicrositeCacheItem[]>('shared', [], this.shared$);
        }

        if (!accessibility) {
          await this.persist<IContentAccessibility>('accessibility', IContentAccessibility.NULL, this.accessibility$);

          if (ready) {
            this.persist<IContentAccessibility>('accessibility', IContentAccessibility.CONFIDENTIAL, this.accessibility$);
          }
        }

        if (!notifications) {
          this.persist<INotification[]>('notifications', [], this.notifications$);
        }


        console.log('[cache] verify', ready, { favorites, message });

        this.status$.next(ready);
      } else {
        console.log('[cache] verify: force reload (false)');

        this.status$.next(false);
      }
    } else {
      console.log('[cache] verify: already loaded (true)');

      this.status$.next(true);
    }
  }

  async load(info: boolean = true, content: boolean = true, notifications: boolean = true) {
    if (this.loaded) {
      console.warn('[cache] load() call after loaded');
    }

    const methods: Promise<boolean>[] = [];


    if (info) {
      methods.push(this.requestInfo());
    }

    if (content) {
      methods.push(this.requestContent());
    }

    if (notifications) {
      methods.push(this.requestNotifications());
    }

    const result = await Promise.all(methods);
    const error = result.reduce((a, b) => a - Number(b), methods.length);

    if (error === 0) {
      console.log('[cache] loaded');
    } else {
      throw new Error('Data not loaded');
    }
  }

  async reset(recent: boolean = true) {
    this.cache = null;
    this.loaded = false;

    this.update(0, 0, 0);

    await Promise.all([
      this.persist<IProfile>('profile', null, this.profile$),
      this.persist<IEnterprise>('enterprise', null, this.enterprise$),
      this.persist<ILibrary[]>('libraries', null, this.libraries$),
      this.persist<INewsArticle[]>('news', null, this.news$),
      this.persist<ICacheGroup>('indexes', null, this.indexes$),
      this.persist<IContent[]>('favorites', null, this.favorites$),
      this.persist<INotification[]>('notifications', null, this.notifications$)
    ]);

    if (recent) {
      await Promise.all([
        this.persist<IContentAccessibility>('accessibility', null, this.accessibility$),
        this.persist<IMicrositeCacheItem[]>('shared', null, this.shared$),
        this.persist<IContent[]>('recent', null, this.recent$),
        this.persist<IContent[]>('mail', null, this.mail$)
      ]);
    }

    console.log('[cache] reset');

    this.status$.next(false);
  }

  async requestInfo(): Promise<boolean> {
    console.log('[cache] requestInfo() - start');

    try {
      const [profile, enterprise, logo] = await Promise.all([
        this.api.getProfile().toPromise(),
        this.api.getEnterprise().toPromise(),
        this.api.getAdminLogo().toPromise()
      ]);

      const image = environment.api.methods.profile.image;

      if (!profile.profileImage) {
        profile.profileImage = <IProfileFile>{ url: image };
      } else {
        profile.profileImage.url = image;
      }

      enterprise.adminLogo = logo;

      this.persist<IProfile>('profile', profile, this.profile$);
      this.persist<IEnterprise>('enterprise', enterprise, this.enterprise$);

      console.log('[cache] requestInfo() - ok');
      return true;
    } catch (err) {
      console.log('[cache] requestInfo() - error', err);
    }

    return false;
  }

  async requestContent(): Promise<boolean> {
    console.log('[cache] requestContent() - start');

    try {
      const [news, libraries, favorites] = await Promise.all([
        this.api.getNews().toPromise(),
        this.api.getLibraries().toPromise(),
        this.api.getFavorites().toPromise()
      ]);

      this.favorites = favorites;

      this.cache = <ICacheMap>{
        news,
        indexes: {
          keys: { libraries: {}, contents: {} },
          names: { libraries: {}, contents: {} }
        }
      };

      this.cache.indexes.keys.news = news.reduce((r, e) => {
        r[e.id] = e;
        return r;
      }, {});

      this.cache.libraries = this.parseLibraries(libraries);

      console.log('[cache] requestContent() - ok');

      if (this.cache.libraries.length === 0) {
        this.save();
      }

      if (this.accessibility$.getValue() === IContentAccessibility.NULL) {
        await this.persist<IContentAccessibility>('accessibility', IContentAccessibility.CONFIDENTIAL, this.accessibility$);
        console.log('[cache] requestContent() - accessibility set');
      }

      return true;
    } catch (err) {
      console.log('[cache] requestContent() - error', err);
      this.cancel = true;
      return false;
    }
  }

  async requestNotifications() {
    console.log('[cache] requestNotifications() - start');

    try {
      const [notifications, recent] = await Promise.all([
        this.api.getNotifications().toPromise(),
        this.api.getNewNotifications().toPromise()
      ]);

      notifications.forEach(notification => {
        notification.viewed = recent.indexOf(notification.id) === -1;
      });

      await this.persist<INotification[]>('notifications', notifications, this.notifications$);

      return true;
    } catch (err) {
      console.log('[cache] requestNotifications() - error', err);
      this.cancel = true;
      return false;
    }
  }

  async registerContentOpening(key: string) {
    const cache = this.indexes$.getValue();

    if (cache) {
      const recent = this.recent$.getValue() || [];

      const index = recent.map(p => p.id).indexOf(key);

      if (recent.length > 0 && index !== -1) {
        recent.splice(index, 1);
      }

      const reference = cache.keys.contents[key];

      if (reference) {
        recent.push(reference);

        await this.persist<IContent[]>('recent', recent, this.recent$);

        console.log('[cache] recent saved!', index, recent[recent.length - 1]);
      }
    } else {
      console.log('[cache] content opening not registered');
    }
  }

  async updateFavorites(contents: string[]) {
    const cache = this.indexes$.getValue();
    const keys = cache.keys.contents;
    const favorites = contents.map(p => keys[p]);

    await this.persist<IContent[]>('favorites', favorites, this.favorites$);

    console.log('[cache] favorites saved!');
  }

  async updateShared(contents: IMicrositeCacheItem[]) {
    await this.persist<IMicrositeCacheItem[]>('shared', contents, this.shared$);

    console.log('[cache] shared saved!');
  }

  async updateMessage(message: IMicrositeMessage) {
    await this.persist<IMicrositeMessage>('message', message, this.message$);

    console.log('[cache] message saved!');
  }

  async updateMail(contents: IContent[]) {
    await this.persist<IContent[]>('mail', contents, this.mail$);

    console.log('[cache] mail saved!');
  }

  async updateAccessibility(value: IContentAccessibility) {
    await this.persist<IContentAccessibility>('accessibility', value, this.accessibility$);

    console.log('[cache] accessibility saved!');
  }

  async updateUser(value: string) {
    const val = value === null ? 'NULL' : value;
    await this.persist<string>('user', val, this.user$);

    console.log('[cache] user saved!', { value });
  }

  async readNotification(id: string) {
    await this.api.readNotification(id).toPromise();
    await this.requestNotifications();

    console.log('[cache] notification marked as read:', id);
  }


  private async initialize() {
    const has = await this.retrieve<string>('user', this.user$, true);

    if (!has) {
      console.log('[cache] will erase user');
      this.persist<string>('user', 'NULL', this.user$);
    }
  }

  private parseLibraries(libraries: ILibrary[], parent?: ILibrary): ILibrary[] {
    libraries.forEach(l => {
      this.cache.indexes.keys.libraries[l.id] = l;
      this.cache.indexes.names.libraries[l.displayName] = l;

      if (parent) {
        l.parent = parent;
      }

      this.getContents(l);
    });

    return libraries
      .filter(k => !k.isLearningLibrary)
      .sort((a, b) => {
        if ('sortOrder' in a && 'sortOrder' in b && a.sortOrder !== b.sortOrder) {
          return a.sortOrder > b.sortOrder ? 1 : -1;
        }

        return orderAlphabetically('displayName')(a, b);
      });
  }

  private async getContents(parent: ILibrary) {
    this.update(this.remaining, this.total + 1, this.requests + 1);

    let contents = <IContent[]>[];

    try {
      const result = await this.api.getContents(parent.id).toPromise();

      contents = result.contents;
      parent.collections = result.collections;
    } catch (err) {
      if (!this.cancel) {
        throw err;
      }

      console.log('[cache] getContents() - error', err);
    }

    this.update(this.remaining + 1, this.total, this.requests - 1);


    if (contents.length > 0) {
      parent.contents = contents;

      contents.forEach(p => {
        p.library = parent;
        this.cache.indexes.keys.contents[p.id] = p;
        this.cache.indexes.names.contents[p.title] = p;
      });
    } else {
      parent.contents = [];
    }

    if (parent.sublibraries && parent.sublibraries.length > 0) {
      parent.sublibraries = this.parseLibraries(parent.sublibraries, parent);
    } else {
      parent.sublibraries = [];

      if (this.requests === 0) {
        this.save();
      }
    }
  }

  private async persist<T>(key: string, value: T, reference: BehaviorSubject<T>): Promise<boolean> {
    if (value) {
      const data = <ICacheStorage<T>>{
        data: value,
        modified: Date.now()
      };

      await this.storage.setItem(key, data).toPromise();
      reference.next(value);

      console.log('[cache] persisted', key);
    } else {
      await this.storage.removeItem(key).toPromise();
      reference.next(null);

      console.log('[cache] removed', key);
    }

    return true;
  }

  private async retrieve<T>(key: string, reference: BehaviorSubject<T>, ignoreExpiration: boolean = false): Promise<boolean> {
    const cached = await this.storage.getUnsafeItem<ICacheStorage<T>>(key).toPromise();
    const time = environment.api.cache * 60 * 1000;
    const now = Date.now();

    if (cached) {
      if (ignoreExpiration || cached.modified + time >= now) {
        reference.next(cached.data);

        console.log('[cache] retrieved', key);

        return true;
      } else {
        console.log('[cache] EXPIRED', key);
      }
    } else {
      console.log('[cache] not cached', key);
    }

    return false;
  }

  private async save() {
    const keys = this.cache.indexes.keys.contents;
    const favorites = this.favorites.map(f => keys[f] || null).filter(f => !!f);

    await Promise.all([
      this.persist<IContent[]>('favorites', favorites, this.favorites$),
      this.persist<ICacheGroup>('indexes', this.cache.indexes, this.indexes$),
      this.persist<INewsArticle[]>('news', this.cache.news, this.news$),
      this.persist<ILibrary[]>('libraries', this.cache.libraries, this.libraries$)
    ]);

    this.finish();
  }

  private finish() {
    this.cache = null;
    this.favorites = null;
    this.cancel = false;

    if (!this.loaded) {
      this.loaded = true;
      this.status$.next(true);
    }
  }

  private update(remaining: number, total: number, requests: number) {
    this.remaining = remaining;
    this.total = total;
    this.requests = requests;

    this.loading$.next([remaining, total]);
  }

}
