import { Component, OnInit, OnDestroy, Input, Output, HostBinding, ChangeDetectorRef, EventEmitter } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { ApiService } from '@dink/core/services/api.service';
import { DataService } from '@dink/core/services/data.service';
import { IEnterprise } from '@dink/core/models/enterprise.model';
import { hasEmailShare } from '@dink/core/helpers/enterprise.helper';


@Component({
  selector: 'dwa-mail-button',
  templateUrl: './mail-button.component.html',
  styleUrls: ['./mail-button.component.sass']
})
export class MailButtonComponent implements OnInit, OnDestroy {

  waiting = false;
  loading = false;
  cssPrefix = 'mail-button';
  active: boolean;
  enterprise$: Observable<IEnterprise>;
  showOptions = false;
  @Input() options  = false;
  @Input() labels: boolean;
  @Input() publicationKey: string;
  @Output() requestPage = new EventEmitter();
  @HostBinding('class.invisible') invisible: boolean;

  private subscription: Subscription;


  @Input('class') set cssClass(v: string) {
    this.cssPrefix = v;
  }


  constructor(
    private api: ApiService,
    private data: DataService,
    private detector: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    if (this.publicationKey) {
      this.subscription = this.data.isMail(this.publicationKey).subscribe(f => {
        if (this.waiting || !this.loading) {
          this.active = f;
          this.showOptions = false;
          this.waiting = false;
          this.loading = false;

          this.detector.markForCheck();
        }
      });
    }

    this.enterprise$ = this.data.getEnterprise().pipe(tap(enterprise => {
      this.invisible = !hasEmailShare(enterprise);
    }));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  onClick(option: number = -1) {
    let execute = true;

    if (!this.active && this.options) {
      if (option === -1) {
        this.showOptions = !this.showOptions;
        execute = false;
      }
    }

    if (execute) {
      if (option === 1) {
        this.waiting = true;
        this.loading = true;

        this.detector.markForCheck();

        this.requestPage.emit();
      } else {
        this.updateMail();
      }
    }
  }


  private async updateMail() {
    this.loading = true;

    const list = await this.data.getMail().pipe(take(1)).toPromise();
    const publication = await this.data.getContentByKey(this.publicationKey).pipe(take(1)).toPromise();
    const id = publication.currentContentVersion.id;
    const index = list.findIndex(item => item.currentContentVersion.id === id);

    if (list.length > 0 && index !== -1) {
      list.splice(index, 1);
    }

    if (!this.active) {
      const link = await this.api.getPreviewUrl(publication).pipe(take(1)).toPromise();

      publication.currentContentVersion.temporaryLink = link;

      list.push(publication);
    }

    this.data.changeMail(list);

    this.loading = false;
  }

}
