export const orderNumerically = (property?: string) => {
  return (a: any, b: any) => {
    const c = parseFloat(property ? a[property] : a);
    const d = parseFloat(property ? b[property] : b);

    return (c === d) ? 0 : (c > d) ? 1 : -1;
  };
};

export const orderAlphabetically = (property?: string) => {
  return (a: any, b: any) => {
    const c = (property ? a[property] : a).trim().toUpperCase();
    const d = (property ? b[property] : b).trim().toUpperCase();

    return (c === d) ? 0 : (c > d) ? 1 : -1;
  };
};

export const orderAlphabeticallyDesc = (property?: string) => {
  return (a: any, b: any) => {
    const c = (property ? a[property] : a).trim().toUpperCase();
    const d = (property ? b[property] : b).trim().toUpperCase();

    return (c === d) ? 0 : (c < d) ? 1 : -1;
  };
};

export const orderChronologically = (property?: string) => {
  return (a: any, b: any) => {
    const c = (property ? a[property] : a).getTime();
    const d = (property ? b[property] : b).getTime();

    return (c === d) ? 0 : (c < d) ? 1 : -1;
  };
};
