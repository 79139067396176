import { Component, OnInit, Input, HostBinding } from '@angular/core';

import { IContentAccessibility } from '@dink/core/models/content.model';


@Component({
  selector: 'dwa-card-thumbs',
  templateUrl: './card-thumbs.component.html',
  styleUrls: ['./card-thumbs.component.sass']
})
export class CardThumbsComponent implements OnInit {

  shareable = true;
  download = true;
  @Input() publicationKey: string;
  @HostBinding('attr.class') className = 'dwa-card-thumb';


  @Input('class') set cssClass (v: string) {
    this.className = v;
  }

  @Input() set accessibility (v: string) {
    this.shareable = v === IContentAccessibility.PUBLIC;
  }

  @Input() set allowDownload (v: boolean) {
    this.download = v;
  }


  constructor() {
  }


  ngOnInit() {
  }

}
