<div class="dwa-topnav" *ngIf="enterprise$ | async as enterprise">
  <div class="dwa-topnav-menu" *ngIf="profile$ | async as profile">
    <div class="dwa-topnav-menu-item custom-button" *ngFor="let button of profile.customButtons"
      [ngSwitch]="button.type">
      <a class="dwa-topnav-menu-item-link" *ngSwitchCase="'CONTENT'" [title]="button.name"
        [dwaPublicationLink]="button.id">
        <img class="dwa-topnav-menu-item-image" [src]="button.iconDownloadUrl | fixImage" [alt]="button.name">
      </a>
      <a class="dwa-topnav-menu-item-link" *ngSwitchDefault [title]="button.name" [href]="button.destination | safeUrl"
        target="_blank">
        <img class="dwa-topnav-menu-item-image" [src]="button.iconDownloadUrl | fixImage" [alt]="button.name">
      </a>
    </div>

    <div class="dwa-topnav-menu-item">

      <mat-slide-toggle #tooltip="matTooltip"
        [matTooltip]="(confidential$ | async ) === 2? ('TOPNAV.BUTTONS.CONFIDENTIAL.TOOLTIP_PUBLIC' | translate) : ('TOPNAV.BUTTONS.CONFIDENTIAL.TOOLTIP_CONFIDENTIAL' | translate) "
        matTooltipPosition="below" matTooltipHideDelay="5000"
        aria-label="{{'TOPNAV.BUTTONS.CONFIDENTIAL.LABEL' | translate}}" class="dwa-topnav-menu-item-link"
        [checked]="(confidential$ | async ) === 2? false:true" (change)="onAccessibilityClick()" color="warn">
      </mat-slide-toggle>

    </div>

    <div class="dwa-topnav-menu-item" *ngIf="mail$ | async as mail">
      <a class="dwa-topnav-menu-item-link" title="{{'TOPNAV.BUTTONS.ACCOUNT_HUB' | translate}}"
        [href]="mailUrl | safeUrl" (click)="onMailClick()">
        <div class="dwa-topnav-menu-item-notification"
          [style.background-color]="enterprise.applicationSettings?.toolbarColor"
          [style.color]="enterprise.applicationSettings?.textColor">
          {{ mail }}
        </div>
        <span class="dwa-topnav-menu-item-icon icon-mail"></span>
      </a>
    </div>

    <div class="dwa-topnav-menu-item" *ngIf="shared$ | async as shared">
      <button class="dwa-topnav-menu-item-link" title="{{'TOPNAV.BUTTONS.ACCOUNT_HUB' | translate}}"
        (click)="onHubClick()">
        <div class="dwa-topnav-menu-item-notification"
          [style.background-color]="enterprise.applicationSettings?.toolbarColor"
          [style.color]="enterprise.applicationSettings?.textColor">
          {{ shared }}
        </div>
        <svg-icon-sprite class="dwa-topnav-menu-item-icon icon-shared" src="/assets/svg/sprite1.svg#account-hub">
        </svg-icon-sprite>
      </button>
    </div>

    <div class="dwa-topnav-menu-item" *ngIf="profile.lmsEnabled && profile.lmsConfiguration">
      <button class="dwa-topnav-menu-item-link" (click)="onLMSClick(profile.lmsConfiguration.id)"
        title="{{'TOPNAV.BUTTONS.LMS' | translate}}">
        <i class="dwa-topnav-menu-item-lms"></i>
      </button>
    </div>

    <div class="dwa-topnav-menu-item">
      <button class="dwa-topnav-menu-item-link" title="{{'TOPNAV.BUTTONS.SEARCH' | translate}}"
        [routerLink]="['/libraries/search']">
        <i class="dwa-topnav-menu-item-search"></i>
      </button>
    </div>

    <div class="dwa-topnav-menu-item">
      <button class="dwa-topnav-menu-item-link" title="{{'TOPNAV.BUTTONS.RELOAD' | translate}}"
        (click)="onReloadClick()">
        <i class="dwa-topnav-menu-item-reload rotate"></i>
        <div class="dwa-topnav-menu-link-content green" *ngIf="updated">
          <strong>New Update!</strong>
          <span>Click to reload!</span>
        </div>
      </button>
    </div>

    <div class="dwa-topnav-menu-item user" [class.opened]="user">
      <button class="dwa-topnav-menu-item-link" (click)="onUserClick()">
        <div class="dwa-topnav-menu-avatar"
          [style.background-image]="profile.profileImage?.url | downloadImage | async | background"
          [style.background-color]="enterprise.applicationSettings?.toolbarColor"></div>
        <div class="dwa-topnav-menu-link-content">
          <p class="dwa-topnav-menu-link-title">{{'TOPNAV.WELCOME' | translate}}</p>
          <div class="dwa-topnav-menu-link-subtitle">
            <p class="dwa-topnav-menu-link-email">{{ profile.name || profile.email }}</p>
            <div class="dwa-topnav-menu-link-icon"></div>
          </div>
        </div>
      </button>
      <div class="dwa-topnav-menu-submenu" *ngIf="user">
        <div class="dwa-topnav-menu-submenu-title" [style.color]="enterprise.applicationSettings?.titleTextColor">
          {{'TOPNAV.SUBMENU.USER.TITLE' | translate}}
          <div class="dwa-topnav-menu-submenu-title-icon"></div>

          <button class="dwa-topnav-menu-submenu-title-button" (click)="onUserEdit(profile)">
            {{'TOPNAV.SUBMENU.USER.EDIT' | translate}}
          </button>
        </div>
        <div class="dwa-topnav-menu-submenu-section">
          <strong class="section-item">{{'TOPNAV.SUBMENU.USER.NAME.TITLE' | translate}}</strong>
          <span class="section-item">{{'TOPNAV.SUBMENU.USER.NAME.FIRST_NAME' | translate}}:
            {{ profile.firstName }}</span>
          <span class="section-item">{{'TOPNAV.SUBMENU.USER.NAME.LAST_NAME' | translate}}: {{ profile.lastName }}</span>
        </div>
        <div class="dwa-topnav-menu-submenu-section">
          <strong class="section-item">{{'TOPNAV.SUBMENU.USER.CONTACT.TITLE' | translate}}</strong>
          <span class="section-item"
            *ngIf="isFieldShow('Phone number', profile)">{{'TOPNAV.SUBMENU.USER.CONTACT.PHONE_NUMBER' | translate}}:
            {{ profile.phoneNumber || '(n/a)' }}</span>
        </div>
        <div class="dwa-topnav-menu-submenu-title">
          {{'TOPNAV.SUBMENU.USER.BUSINESS_CARD' | translate}}
        </div>
        <div class="dwa-topnav-menu-submenu-section">
          <div class="business-card">
            <div class="bc-avatar" *ngIf="profile.isImagePublic">
              <div class="dwa-topnav-menu-avatar" [style.background-image]="image | downloadImage | async | background"
                [style.background-color]="enterprise.applicationSettings?.toolbarColor"></div>
              <span *ngIf="!image" class="initials">
                {{ userInitial }}
              </span>
            </div>
            <div class="bc-avatar" *ngIf="!profile.isImagePublic">
              <div class="dwa-topnav-menu-avatar" [style.background-image]="image | background"
                [style.background-color]="enterprise.applicationSettings?.toolbarColor"></div>
              <span *ngIf="!image" class="initials">
                {{ userInitial }}
              </span>
            </div>
            <div class="bc-data">
              <strong class="section-item">{{ profile.firstName }} {{ profile.lastName }}</strong>

              <ng-container *ngIf="profile.dataOrder && profile.dataOrder.length">
                <ng-container *ngFor="let item of profile.dataOrder">
                  <span *ngIf="profile.isPhoneNumberPublic && item.dataKeyName == 'Phone number'"
                    class="section-item">{{ profile.phoneNumber || '(n/a)' }}</span>
                  <span *ngIf="profile.isTitlePublic && item.dataKeyName == 'Title'"
                    class="section-item">{{ profile.title }}</span>
                  <span *ngIf="profile.isCompanyWebSitePublic && item.dataKeyName == 'Company website'"
                    class="section-item">{{ profile.companyWebSite }}</span>
                  <span *ngIf="profile.isCompanyAddressPublic && item.dataKeyName == 'Company address'"
                    class="section-item">{{ profile.companyAddress }}</span>
                  <span *ngIf="profile.isVatNumberPublic && item.dataKeyName == 'VAT number'"
                    class="section-item">{{ profile.vatNumber }}</span>
                  <span *ngIf="profile.isLinkedInPublic && item.dataKeyName == 'LinkedIn'"
                    class="section-item">{{ profile.linkedIn }}</span>
                </ng-container>
              </ng-container>
            </div>
          </div>

        </div>
        <div class="dwa-topnav-menu-submenu-section">
          <button (click)="onClearCachedDataClick()" [style.color]="enterprise.applicationSettings?.titleTextColor">
            {{'TOPNAV.SUBMENU.USER.CLEAR_CACHED_DATA' | translate}}
          </button>
        </div>
        <div class="dwa-topnav-menu-submenu-section">
          <a class="section-link" [routerLink]="['/logout']" (click)="onUserClick()"
            [style.color]="enterprise.applicationSettings?.titleTextColor">
            {{'TOPNAV.SUBMENU.USER.LOGOUT' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="dwa-topnav-start">
    <button (click)="onSidebarClick()" class="dwa-topnav-toggle"
      [style.color]="enterprise.applicationSettings?.titleTextColor">
      <span class="dwa-topnav-toggle-icon"></span>
    </button>

    <!-- <input class="dwa-topnav-search" type="text" placeholder="{{'TOPNAV.SEARCH.LABEL' | translate}}"> -->

    <ng-container *ngIf="device.size$ | async as size">
      <div class="dwa-topnav-sections" *ngIf="accountHub || crm">
        <a class="dwa-topnav-sections-link" [routerLink]="['/', 'dashboard']"
          [title]="'TOPNAV.MENU.DASHBOARD' | translate" routerLinkActive="active">
          {{ 'TOPNAV.MENU.DASHBOARD' | translate }}
        </a>
        <a class="dwa-topnav-sections-link last" *ngIf="crm" [routerLink]="['/', 'crm']"
          [title]="'TOPNAV.MENU.CRM' | translate" routerLinkActive="active">
          {{ 'TOPNAV.MENU.CRM' | translate }}
        </a>
        <a class="dwa-topnav-sections-link" *ngIf="accountHub" [href]="accountHub"
          [title]="'TOPNAV.MENU.ACCOUNT_HUB' | translate" target="_blank">
          {{ 'TOPNAV.MENU.ACCOUNT_HUB' | translate }}
        </a>
        <a class="dwa-topnav-sections-link" *ngIf="enterprise.userAccessToAnalyticsEnabled"
          [href]="'{portfolio}/dashboard/analytics/enterprises/{enterprise}/mystats/{user}/external' | buildUrl | async"
          [title]="'TOPNAV.MENU.STATS' | translate" target="_blank">
          {{ 'TOPNAV.MENU.STATS' | translate }}
        </a>
      </div>
    </ng-container>
  </div>

  <div class="dwa-topnav-backdrop" *ngIf="user" (click)="onUserClick()"></div>
</div>
