import { Injectable } from '@angular/core';

import { Observable, combineLatest, of } from 'rxjs';
import { map, filter, tap, switchMap } from 'rxjs/operators';


import { environment } from '@dink/env/environment';

import { CacheService } from '@dink/core/services/cache.service';
import { ApiService } from '@dink/core/services/api.service';
import { ILibrary } from '@dink/core/models/library.model';
import { IProfile, IProfileFile } from '@dink/core/models/profile.model';
import { IContent, IContentAccessibility, IContentSearchRequest } from '@dink/core/models/content.model';
import { IEnterprise } from '@dink/core/models/enterprise.model';
import { INewsArticle } from '@dink/core/models/news.model';
import { IMicrositeCacheItem } from '@dink/core/models/account-hub.model';
import { INotification } from '@dink/core/models/notification.model';
import { orderChronologically } from '@dink/core/helpers/sort.helper';
import { findQueryInString } from '@dink/core/helpers/string.helper';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private cache: CacheService,
    private api: ApiService
  ) {
  }


  getStatus(): Observable<boolean> {
    return this.cache.status$.pipe(filter(status => typeof status === 'boolean'));
  }

  getLibraries(): Observable<ILibrary[]> {
    return this.cache.libraries$.pipe(filter(libraries => !!libraries));
  }

  getEnterprise(): Observable<IEnterprise> {
    return this.cache.enterprise$.pipe(filter(enterprise => !!enterprise));
  }

  getNotifications(): Observable<INotification[]> {
    return this.cache.notifications$.pipe(filter(notifications => !!notifications));
  }

  readNotification(id: string): Promise<void> {
    return this.cache.readNotification(id);
  }

  getProfile(): Observable<IProfile> {
    return this.cache.profile$.pipe(
      filter(profile => !!profile),
      tap(profile => {
        if (!profile.profileImage) {
          profile.profileImage = <IProfileFile>{
            fileName: environment.layout.profile.defaultImage
          };
        }
      })
    );
  }

  getLibraryByKey(key: string): Observable<ILibrary> {
    return this.cache.indexes$.pipe(
      filter(cache => !!cache),
      map(cache => cache.keys.libraries[key])
    );
  }

  getContentByKey(key: string): Observable<IContent> {
    return this.cache.indexes$.pipe(
      filter(cache => !!cache),
      map(cache => cache.keys.contents[key])
    );
  }

  getLibraryByName(name: string): Observable<ILibrary> {
    return this.cache.indexes$.pipe(
      filter(cache => !!cache),
      map(cache => cache.names.libraries[name])
    );
  }

  getContentByName(name: string): Observable<IContent> {
    return this.cache.indexes$.pipe(
      filter(cache => !!cache),
      map(cache => cache.names.contents[name])
    );
  }

  searchContents(query: string): Observable<IContent[]> {
    if (query && query.length > 1) {
      let contents: { [index: string]: IContent };

      return this.cache.indexes$.pipe(
        filter(cache => !!cache),
        switchMap(cache => {
          const libraries = Object.keys(cache.keys.libraries);
          const param = <IContentSearchRequest>{ libraryIds: libraries, text: query };

          contents = cache.keys.contents;

          return this.api.searchContent(param);
        }),
        map(search => {
          const ids = search.map(i => i.content.id);
          const check = (key: string) => {
            if (ids.indexOf(key) !== -1) {
              return true;
            }

            const content = contents[key];

            if ('keywords' in content && content.keywords.length > 0) {
              const found = [ content.title, ...content.keywords ]
                .map(k => findQueryInString(query, k))
                .filter(k => k)
                .length;

              return found > 0;
            }

            return findQueryInString(query, content.title);
          };

          return Object.keys(contents)
            .filter(check)
            .map(key => contents[key])
            .sort(orderChronologically('updated'));
        })
      );
    }

    return of([]);
  }

  getAllContents(): Observable<IContent[]> {
    return this.cache.indexes$.pipe(
      filter(cache => !!cache),
      map(cache => {
        const contents = cache.keys.contents;
        return Object.keys(contents).map(k => contents[k]);
      })
    );
  }

  getKeyContents(): Observable<IContent[]> {
    return this.getAllContents().pipe(map(contents => {
      return contents
        .filter(p => !!p && p.keyPublication)
        .sort(orderChronologically('updated'));
    }));
  }

  getNewContents(max: number = 10): Observable<IContent[]> {
    return this.getAllContents().pipe(map(contents => {
      return contents
        .sort(orderChronologically('updated'))
        .slice(0, max);
    }));
  }

  getRecentContents(max: number = 10): Observable<IContent[]> {
    return this.cache.recent$.pipe(
      filter(recent => !!recent),
      map(contents => {
        return contents
          .filter(k => !!k)
          .reverse()
          .slice(0, max);
      })
    );
  }

  getFavorites(max: number = 10): Observable<IContent[]> {
    return this.cache.favorites$.pipe(
      filter(favorites => !!favorites),
      map(contents => {
        return contents
          .filter(k => !!k)
          .reverse()
          .slice(0, max);
      })
    );
  }

  isFavorite(contentKey: string): Observable<boolean> {
    return this.cache.favorites$.pipe(
      filter(favorites => !!favorites),
      map(contents => {
        return contents
          .filter(k => !!k)
          .some(p => p.id === contentKey);
      })
    );
  }

  getShared(): Observable<IMicrositeCacheItem[]> {
    return this.cache.shared$.pipe(
      filter(shared => !!shared),
      map(contents => contents.filter(k => !!k))
    );
  }

  isShared(contentKey: string): Observable<boolean> {
    return combineLatest([
        this.getContentByKey(contentKey),
        this.cache.shared$.pipe(filter(shared => !!shared))
      ]).pipe(map(data => {
        const [ content, shared ] = data;

        return (shared || [])
          .filter(p => !!p)
          .some(p => p.id === content.currentContentVersion.id);
      }));
  }

  changeShared(shared: IMicrositeCacheItem[]): Promise<void> {
    return this.cache.updateShared(shared);
  }

  getMail(): Observable<IContent[]> {
    return this.cache.mail$.pipe(
      filter(mail => !!mail),
      map(contents => contents.filter(k => !!k))
    );
  }

  isMail(contentKey: string): Observable<boolean> {
    return this.cache.mail$.pipe(
      filter(mail => !!mail),
      map(contents => {
        return contents
          .filter(k => !!k)
          .some(p => p.id === contentKey);
      })
    );
  }

  changeMail(mail: IContent[]): Promise<void> {
    return this.cache.updateMail(mail);
  }

  getAccessibility(): Observable<IContentAccessibility> {
    return this.cache.accessibility$.pipe(
      filter(accessibility => accessibility !== null)
    );
  }

  changeAccessibility(status: IContentAccessibility): Promise<void> {
    return this.cache.updateAccessibility(status);
  }

  getNews(max: number = Infinity): Observable<INewsArticle[]> {
    return this.cache.news$.pipe(
      filter(news => !!news),
      map(news => news.sort((a, b) => a.index - b.index).slice(0, max))
    );
  }

  getArticle(id: string): Observable<INewsArticle> {
    return this.cache.indexes$.pipe(
      filter(cache => !!cache),
      map(cache => cache.keys.news[id])
    );
  }

}
