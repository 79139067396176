import { ILibrary } from '@dink/core/models/library.model';
import { ContentDownloadEventType } from '@dink/core/enums/content-download-event-type.enum';


export enum IContentAccessibility {
  NULL = 'null',
  PUBLIC = 'public',
  CONFIDENTIAL = 'confidential',
}


export interface IContent {
  id: string;
  title: string;
  label: string;
  created: Date;
  updated: Date;
  metadata: string;
  showInAnyLangugage: boolean;
  allowContentVersionDownloadAndPrint: boolean;
  distributionIsAllowed: boolean;
  keyPublication: boolean;
  contentVersionPriority: string;
  accessibility: IContentAccessibility;
  currentContentVersion: IContentVersion;
  language: IContentLanguage;
  contentCollections: string[];
  keywords: string[];
  library?: ILibrary;
  isLMS?: boolean;
  allowAppDownload: boolean;
}

export interface IContentVersion {
  id: string;
  contentId: string;
  title: string;
  contentType: string;
  created: Date;
  updated: Date;
  publishDateTime?: Date;
  imageUrl: string;
  videoUrl: string;
  aspectRatio: string;
  downloadUrl: string;
  isEncrypted: boolean;
  startingStoryKey?: string;
  temporaryLink?: string;
}

export interface IContentLanguage {
  id: string;
  shortName: string;
  longName: string;
}


export interface IContentSearchRequest {
  libraryIds: string[];
  text: string;
}

export interface IContentSearchResponse {
  id: string;
  title: string;
  contentType: string;
  libraries: ILibrary[];
  alias: string;
  content: {
    id: string;
    title: string;
    label: string;
    imageUrl: string;
  };
}

export interface IContentDownloadEvent {
  eventType: ContentDownloadEventType,
  content: IContent;
}
