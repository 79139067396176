<div #content class="dwa-publication-dialog-content" *ngIf="!loading"
    [class.history-opened]="history" [class.fullscreen-activated]="fullscreen?.activated" [class.hide-controls]="!input.controls">
  <div class="dwa-publication-dialog-toolbar" [class.no-touch]="!device.touch">
    <button *ngIf="!fullscreen?.activated" (click)="onClose()" [disabled]="closing"
        class="dwa-publication-dialog-toolbar-item dwa-publication-dialog-toolbar-item-button close">
      <svg-icon-sprite class="dwa-publication-dialog-toolbar-item-icon" src="/assets/svg/sprite1.svg#multiply"></svg-icon-sprite>
    </button>

    <ng-container *ngIf="[1, 2].indexOf(error) === -1">
      <button *ngIf="toolbar && fullscreen.enabled" class="dwa-publication-dialog-toolbar-item-button fullscreen"
          (click)="onFullscreenClick()">
        <svg-icon-sprite *ngIf="!fullscreen.activated" class="dwa-publication-dialog-toolbar-item-icon"
            src="/assets/svg/sprite1.svg#expand"></svg-icon-sprite>
        <svg-icon-sprite *ngIf="fullscreen.activated" class="dwa-publication-dialog-toolbar-item-icon"
            src="/assets/svg/sprite1.svg#minimize"></svg-icon-sprite>
      </button>

      <ng-container *ngIf="recent$ | async as recent">
        <div *ngIf="recent.length > 0" class="dwa-publication-dialog-toolbar-item history" [class.active]="history">
          <button class="dwa-publication-dialog-toolbar-item-button history" (click)="onHistoryClick()">
            <svg-icon-sprite class="dwa-publication-dialog-toolbar-item-icon" src="/assets/svg/sprite1.svg#history"></svg-icon-sprite>
          </button>
          <div *ngIf="history" class="dwa-publication-dialog-toolbar-item-submenu">
            <button class="dwa-publication-dialog-toolbar-item-submenu-item" *ngFor="let publication of recent"
                (click)="onPublicationClick(publication)">
              <span><img class="dwa-publication-dialog-toolbar-item-submenu-image" [src]="publication.imageUrl | fixImage"></span>
              <span class="dwa-publication-dialog-toolbar-item-submenu-title">{{ publication.title }}</span>
            </button>
          </div>
        </div>
      </ng-container>

      <dwa-mail-button class="dwa-publication-dialog-toolbar-item" *ngIf="input.content.accessibility === 'public'"
          [publicationKey]="input.content.id" [options]="frontend || pdf" (requestPage)="onMailPageClick()"></dwa-mail-button>

      <dwa-share-button class="dwa-publication-dialog-toolbar-item" *ngIf="input.content.accessibility === 'public'"
          [publicationKey]="input.content.id"></dwa-share-button>

      <dwa-favorites-button class="dwa-publication-dialog-toolbar-item" [publicationKey]="input.content.id"></dwa-favorites-button>

      <dwa-download-button class="dwa-publication-dialog-toolbar-item" *ngIf="input.content.accessibility === 'public'" 
          [publicationKey]="input.content.id"></dwa-download-button>

      <button *ngIf="toolbar && thumbs" (click)="onThumbsClick()"
          class="dwa-publication-dialog-toolbar-item dwa-publication-dialog-toolbar-item-button toolbar">
        <svg-icon-sprite class="dwa-publication-dialog-toolbar-item-icon" src="/assets/svg/sprite1.svg#toolbar"></svg-icon-sprite>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="!error">
    <ng-container *ngIf="resize$ | async as resize">
      <iframe #iframe *ngIf="!video" class="dwa-publication-dialog-content-iframe" allowfullscreen [scrolling]="device.iOS ? 'no' : null"
          [src]="safeUrl" [style.height]="resize.height" [style.width]="resize.width" [style.transform]="resize.transform | safeStyle"></iframe>
    </ng-container>

    <video *ngIf="video" autoplay controls class="dwa-publication-dialog-content-video"
        [src]="safeUrl" (loadedmetadata)="onMetadata($event.target)"></video>
  </ng-container>

  <div *ngIf="error" class="dwa-publication-dialog-content-error">
    <ng-container *ngIf="error === 1">
      <p>{{'PUBLICATION.ERROR.DESCRIPTION_1' | translate}}</p>
      <!-- <p>{{'PUBLICATION.ERROR.DESCRIPTION_2' | translate}} <strong [innerHTML]="waiting$ | async | seconds"></strong>.</p> -->
    </ng-container>
    <ng-container *ngIf="error === 2">
      <p>{{'PUBLICATION.ERROR.DESCRIPTION_3' | translate}}</p>
    </ng-container>
    <ng-container *ngIf="error === 3">
      <p>{{'PUBLICATION.ERROR.DESCRIPTION_4.FIRST_LINE' | translate}}</p>
      <p>
        <a [href]="url" target="_blank">{{'PUBLICATION.ERROR.DESCRIPTION_4.CLICK_HERE' | translate}}</a>
        {{'PUBLICATION.ERROR.DESCRIPTION_4.LAST_LINE' | translate}}
      </p>
    </ng-container>
  </div>
</div>

<div *ngIf="loading" class="dwa-publication-dialog-loader">{{'PUBLICATION.LOADING' | translate}}</div>
