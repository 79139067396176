import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';


@Pipe({
  name: 'safeStyle'
})
export class SafeStylePipe implements PipeTransform {

  constructor(private sanitize: DomSanitizer) {
  }

  transform(origin: string): SafeStyle {
    return this.sanitize.bypassSecurityTrustStyle(origin);
  }

}
