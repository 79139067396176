import { Component, OnInit } from '@angular/core';

import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

import { CacheService } from '@dink/core/services/cache.service';
import { PublicationService } from '@dink/core/services/publication.service';


@Component({
  selector: 'dwa-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.sass']
})
export class FullscreenComponent implements OnInit {

  constructor(
    private cache: CacheService,
    private publication: PublicationService
  ) {
  }


  async ngOnInit() {
    this.cache.verify();
    this.publication.initialize();

    await this.cache.load(true, false, false);

    const status = await this.cache.status$.pipe(take(1)).toPromise();

    if (!status) {
      console.log('{fullscreen} will load');

      await interval(5000).pipe(take(1)).toPromise();
      await this.cache.load(false, true, true);
    }
  }

}
