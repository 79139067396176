import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'dwa-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.sass']
})
export class ImageComponent implements OnInit {

  @Input() path: string;
  @HostBinding('class.hide') hide = true;


  constructor() {
  }


  ngOnInit() {
  }

  onError() {
    this.hide = true;
  }

  onLoad() {
    this.hide = false;
  }

}
