import { Injectable } from '@angular/core';
import { OffineStorageType, OfflineFileStorageService } from '@dink/core/services/file-storage/offline-file-storage.service';

import * as JSZip from "jszip";

@Injectable({
  providedIn: 'root'
})
export class FileSystemAccessApiService extends OfflineFileStorageService {

  private pluginUrl = "https://s3.eu-west-1.amazonaws.com/cdn.dink.eu/dkplugin/2.0/dkplugin.bundle.js";

  //See more at https://web.dev/file-system-access/
  constructor() {
    super();
  }

  isSupported(): Boolean {
    // @ts-ignore
    if (navigator.storage && navigator.storage.getDirectory) {
      return true
    } else {
      return false;
    }
  }

  getType(): OffineStorageType {
    return OffineStorageType.FileSystemAccessAPI;
  }

  async saveZip(cacheKey: string, blob: Blob): Promise<any> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    const dirHandle = await directoryHandle.getDirectoryHandle(cacheKey, { create: true });
    const indexHandle = await this.unzipFile(blob, dirHandle);
    await this.overWriteDKPlugin(dirHandle);
    return indexHandle;
  }

  async saveBlob(cacheKey: string, blob: Blob): Promise<Boolean> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    const fileHandle = await directoryHandle.getFileHandle(cacheKey, { create: true });
    const writable = await fileHandle.createWritable({ keepExistingData: false });
    //await res.body.pipeTo(writable);
    await writable.write(blob);
    await writable.close();
    console.log("[file system] stored: " + cacheKey);
    return true;
  }

  private async createFullPath(dirName: string, dirHandle: any): Promise<any> {
    const parts = dirName.split("/");
    const depth = parts.length - 1;
    //console.log(`depth is ${depth}`);
    let parentDirHandle = dirHandle;
    try {
      let i = 0;
      while (i < depth) {
        parentDirHandle = await parentDirHandle.getDirectoryHandle(parts[i], { create: true });
        i += 1;
      }
      return parentDirHandle;
    } catch (error) {
      console.log(error.message);
      return null;
    }
  }

  private async unzipFile(zippedFile: Blob, dirHandle: any): Promise<any> {

    const zip = await JSZip.loadAsync(zippedFile);
    const handles = {};
    const dirNames = [];
    let filesCounter = 0;
    zip.forEach((relativePath, zipEntry) => {
      //console.log(`zip entry: ${zipEntry.name}, ${zipEntry.dir}`);
      if (zipEntry.dir) {
        const dirName = zipEntry.name;
        dirNames.push(dirName);
      } else {
        filesCounter += 1;
      }
    });

    for (let i = 0; i < dirNames.length; i++) {
      const newDirHandle = await this.createFullPath(dirNames[i], dirHandle);
      if (newDirHandle != null) {
        handles[dirNames[i]] = newDirHandle;
      }
    }


    let indexHandle: any;
    let handled = 0;
    zip.forEach(async (relativePath, zipEntry) => {
      if (!zipEntry.dir) {
        const entryName = zipEntry.name;
        const i = entryName.lastIndexOf("/");
        let dirName = entryName.substr(0, i);
        let fileName = entryName.substr(i + 1);
        let targetDirhandle: any;
        if (dirName.length < 1) {
          //this is the root dir
          targetDirhandle = dirHandle;
        } else {
          dirName += "/";
          targetDirhandle = handles[dirName];
        }

        //console.log(`file: ${fileName}, path: ${dirName}`);
        const fileHandle = await targetDirhandle.getFileHandle(fileName, { create: true });
        const writable = await fileHandle.createWritable();
        const blob = await zipEntry.async("blob");
        await writable.write(blob);
        await writable.close();
        if (fileName == "index.html" && (!indexHandle)) {
          indexHandle = fileHandle;
        }
        handled += 1;
      }
    });

    // A small hack to make sure the index handle is available
    let retries = 10;
    while ((handled < filesCounter) && (retries > 0)) {
      retries -= 1;
      await new Promise(r => setTimeout(r, 1000));
      console.log(`handled ${handled} of ${filesCounter}`);
    }

    return indexHandle;
  }


  private async overWriteDKPluginSource(dirHandle: any): Promise<boolean> {
    let result = false;
    try {
      const fileHandle = await dirHandle.getFileHandle("index.html");
      const blob = await fileHandle.getFile();
      let txt = await blob.text();

      var re = /("|')app_dink\.js("|')/gi;
      txt = txt.replace(re, `$1${this.pluginUrl}$1`);

      const writable = await fileHandle.createWritable({ keepExistingData: false });

      await writable.write(txt);
      await writable.close();
      result = true;
    } catch (error) {
      console.log(error.message);
    }
    return result
  }

  private async overWriteDKPlugin(dirHandle: any): Promise<any> {
    let fileHandle: any;
    try {
      const res = await fetch(this.pluginUrl);
      const txt = await res.text();
      if (!txt) {
        return null;
      }
      fileHandle = await dirHandle.getFileHandle("app_dink.js");
      const writable = await fileHandle.createWritable({ keepExistingData: false });
      await writable.write(txt);
      await writable.close();
    } catch (error) {
      console.log(error.message);
      fileHandle = null;
    }
    return fileHandle;
  }

  async getZipIndexHandle(cacheKey: string): Promise<any> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    let fileHandle: any;
    try {
      const dirHandle = await directoryHandle.getDirectoryHandle(cacheKey);
      fileHandle = await dirHandle.getFileHandle("index.html");
    } catch (error) {
      fileHandle = null;
    }
    return fileHandle;
  }

  async copyToken(token: string): Promise<any> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    const fileHandle = await directoryHandle.getFileHandle('offline_token.txt', { create: true });
    const writable = await fileHandle.createWritable({ keepExistingData: false });
    await writable.write(token);
    await writable.close();
    return fileHandle;
  }

  async getBlob(cacheKey: string): Promise<Blob> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    let blob = null;
    try {
      const fileHandle = await directoryHandle.getFileHandle(cacheKey);
      blob = await fileHandle.getFile()
      if (!(blob && blob.size > 0)) {
        blob = null;
      } else {
        console.log("[file system] found: " + cacheKey);
        //const text = await blob.text();
        //console.log(text);
      }
    } catch (error) {

    }

    return blob;
  }

  async blobExists(cacheKey: string): Promise<boolean> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    try {
      const fileHandle = await directoryHandle.getFileHandle(cacheKey, { create: false });
      return true;

    } catch (error) {
      return false;
    }
  }

  async zipExists(cacheKey: string): Promise<boolean> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    try {
      const folderHandle = await directoryHandle.getDirectoryHandle(cacheKey, { create: false });
      return true;
    } catch (error) {
      return false;
    }
  }

  async deleteOldestBlob(): Promise<Boolean> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    //TODO: this code needs to be improved
    let targetFileName: string = null;
    let targetModified = Date.now() + 60 * 60 * 1000 * 24 * 7;
    for await (const entry of directoryHandle.values()) {
      //console.log(entry.kind, entry.name);
      if (entry.kind == "file") {
        const fileHandle = await directoryHandle.getFileHandle(entry.name);
        const f = await fileHandle.getFile();
        if (f.lastModified < targetModified) {
          targetModified = f.lastModified;
          targetFileName = entry.name;
        }
      }
    }

    if (targetFileName) {
      await this.deleteBlob(targetFileName);
    }

    return true;
  }

  async deleteBlob(cacheKey: string): Promise<Boolean> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    try {
      await directoryHandle.removeEntry(cacheKey);
      console.log("deleting file " + cacheKey);
      return true;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }

  async deleteAllBlobs(): Promise<Boolean> {
    // @ts-ignore
    const directoryHandle = await navigator.storage.getDirectory();
    for await (const entry of directoryHandle.values()) {
      console.log(`[file system access api] removing ${entry.kind} ${entry.name}`);
      if (entry.kind == "file") {
        await directoryHandle.removeEntry(entry.name);
      } else {
        await directoryHandle.removeEntry(entry.name, { recursive: true });
      }
    }
    return true;
  }
}
