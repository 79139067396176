export function extractHTML(message: string): { style: string, content: string } {
  const html = message.replace(/[\n\r]/g, '');
  const regex = /<body([^>]*)>(.*?)<\/body>/i.exec(html);

  if (regex && regex[2]) {
    const style = /style="([^"]*)"/i.exec(regex[1] || '') && RegExp.$1;
    const body = regex[2];

    if (body) {
      const content = regex[2].replace(
        / src="http\:\/\/([^"]+)"/gi,
        (m, p1) => ` src="https://${p1}"`
      );

      return { style, content };
    }
  }

  return null;
}
