export enum DKPluginEvents {
  OpenPublicationRequested = 'openPublicationRequested',
  OpenPublicationHandled = 'openPublicationHandled',
  ClosePublicationRequested = 'closeCurrentPublicationRequested',
  ClosePublicationHandled = 'closeCurrentPublicationHandled',
  OpenKioskRequested = 'openKioskRequested',
  OpenKioskHandled = 'openKioskHandled',
  OpenBrowserRequested = 'openBrowserRequested',
  OpenBrowserHandled = 'openBrowserHandled',
  ShowPdfDocumentRequested = 'showPdfDocumentRequested',
  ShowPdfDocumentHandled = 'showPdfDocumentHandled',
  ShowRemotePdfDocumentRequested = 'showRemotePdfDocumentRequested',
  ShowRemotePdfDocumentHandled = 'showRemotePdfDocumentHandled',
  StatObjectRequested = 'statObjectRequested',
  StatObjectHandled = 'statObjectHandled',
  ScreenshotRequested = 'screenshotRequested',
  ScreenshotRequestHandled = 'screenshotRequestHandled',
  ChooseContentSharingOptionRequested = 'chooseContentSharingOptionRequested',
  ChooseContentSharingOptionHandled = 'chooseContentSharingOptionHandled',
  AddPdfToMicrositeRequested = 'addPdfToMicrositeRequested',
  AddPdfToMicrositeHandled = 'addPdfToMicrositeHandled',
  StartMeetingRequested = 'startMeetingRequested',
  StartMeetingHandled = 'startMeetingHandled',
  StopMeetingRequested = 'stopMeetingRequested',
  StopMeetingHandled = 'stopMeetingHandled',
  FrontendReady = 'frontendReady',
  ToggleThumbs = 'toggleThumbs',
  ChangeDocumentRequested = 'changeDocumentRequested'
}


export interface IPostMessage {
  eventName: DKPluginEvents;
  callbackIdentifier: string;
}

export interface IEventResponse {
  eventName: DKPluginEvents;
  result: boolean;
  callbackIdentifier: string;
  message: string;
}


export interface IClosePublicationRequest extends IPostMessage {
  eventName: DKPluginEvents.ClosePublicationRequested;
}

export interface IClosePublicationHandled extends IEventResponse {
  eventName: DKPluginEvents.ClosePublicationHandled;
}


export interface IOpenPublicationRequest extends IPostMessage {
  eventName: DKPluginEvents.OpenPublicationRequested;
  storyKey?: string;
  publicationKey?: string;
  publicationName?: string;
  returnToPreviousPublicationWhenClosed?: boolean;
  refreshWhenReturningToPreviousPublication?: boolean;
  referenceId?: string;
}

export interface IChangeDocumentRequest extends IPostMessage {
  eventName: DKPluginEvents.ChangeDocumentRequested;
  page: number;
  editionKey: string;
  pdf: string|Blob;
}

export interface IOpenPublicationHandled extends IEventResponse {
  eventName: DKPluginEvents.OpenPublicationHandled;
}


export interface IOpenKioskRequest extends IPostMessage {
  eventName: DKPluginEvents.OpenKioskRequested;
  kioskKey?: string;
  kioskName?: string;
}

export interface IOpenKioskHandled extends IEventResponse {
  eventName: DKPluginEvents.OpenKioskHandled;
}


export interface IOpenBrowserRequest extends IPostMessage {
  eventName: DKPluginEvents.OpenBrowserRequested;
  url: string;
  refreshWhenReturningToPublication?: boolean;
  referenceId?: string;
}

export interface IOpenBrowserHandled extends IEventResponse {
  eventName: DKPluginEvents.OpenBrowserHandled;
}


export interface IShowPdfDocumentRequest extends IPostMessage {
  eventName: DKPluginEvents.ShowPdfDocumentRequested;
  pdfName: string;
  pageNumber: number;
}

export interface IShowPdfDocumentHandled extends IEventResponse {
  eventName: DKPluginEvents.ShowPdfDocumentHandled;
}


export interface IShowRemotePdfDocumentRequest extends IPostMessage {
  eventName: DKPluginEvents.ShowRemotePdfDocumentRequested;
  url: string;
  pageNumber: number;
}

export interface IShowRemotePdfDocumentHandled extends IEventResponse {
  eventName: DKPluginEvents.ShowRemotePdfDocumentHandled;
}


export enum ChooseContentSharingOptionResultType {
  EMAIL = 'EMAIL',
  ACCOUNT_HUB = 'ACCOUNT_HUB'
}

export interface IChooseContentSharingOptionRequest extends IPostMessage {
  eventName: DKPluginEvents.ChooseContentSharingOptionRequested;
}

export interface IChooseContentSharingOptionHandled extends IEventResponse {
  eventName: DKPluginEvents.ChooseContentSharingOptionHandled;
  type: ChooseContentSharingOptionResultType;
}


export interface IAddPdfToMicrositeRequest extends IPostMessage {
  eventName: DKPluginEvents.AddPdfToMicrositeRequested;
  fileName: string;
  base64Data: string;
  mimeType: string;
}

export interface IAddPdfToMicrositeHandled extends IEventResponse {
  eventName: DKPluginEvents.AddPdfToMicrositeHandled;
}


export interface IScreenshotRequestHandled extends IPostMessage {
  eventName: DKPluginEvents.ScreenshotRequestHandled;
  imageData: string;
}
