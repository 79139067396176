import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';

import { DataService } from '@dink/core/services/data.service';
import { CacheService } from '@dink/core/services/cache.service';


@Component({
  selector: 'dwa-reload',
  template: ''
})
export class ReloadComponent implements OnInit, OnDestroy {

  private subscription: Subscription;


  constructor(
    private cache: CacheService,
    private data: DataService,
    private router: Router
  ) {
  }


  ngOnInit() {
    this.subscription = this.data.getStatus()
      .pipe(filter(status => !status), take(1))
      .subscribe(() => this.router.navigate([ '/dashboard' ]));

    this.cache.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
