import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CookieService } from 'ngx-cookie-service';

import { AuthModule, OidcSecurityService, OpenIDImplicitFlowConfiguration,
  AuthWellKnownEndpoints } from 'angular-auth-oidc-client';

import { SharedModule } from '@dink/shared/shared.module';
import { environment } from '@dink/env/environment';

import { throwIfAlreadyLoaded } from './module-import-guard';

import { LoginComponent } from './components/pages/login/login.component';
import { LogoutComponent } from './components/pages/logout/logout.component';
import { ReloadComponent } from './components/pages/reload/reload.component';
import { MasterComponent } from './components/pages/master/master.component';
import { CRMComponent } from './components/pages/crm/crm.component';
import { ViewComponent } from './components/pages/view/view.component';
import { FullscreenComponent } from './components/pages/fullscreen/fullscreen.component';
import { TopnavComponent } from './components/elements/topnav/topnav.component';
import { SidebarComponent } from './components/elements/sidebar/sidebar.component';
import { SidebarTreeComponent } from './components/elements/sidebar/tree/sidebar-tree.component';
import { UserGuard } from './guards/user.guard';
import { RetryBadGatewayInterceptor } from './interceptors/retry.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production && environment.serviceWorkers
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [ HttpClient ]
      }
    }),
    AuthModule.forRoot()
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    ReloadComponent,
    MasterComponent,
    CRMComponent,
    ViewComponent,
    FullscreenComponent,
    TopnavComponent,
    SidebarComponent,
    SidebarTreeComponent
  ],
  providers: [
    UserGuard,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: RetryBadGatewayInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class CoreModule {

  constructor(
    private oidc: OidcSecurityService,
    @Optional() @SkipSelf() parent: CoreModule
  ) {
    throwIfAlreadyLoaded(parent, 'CoreModule');
    this.configure();
  }

  private configure() {
    const config = environment.auth.sso;
    const flow = new OpenIDImplicitFlowConfiguration();
    const endpoints = new AuthWellKnownEndpoints();

    const replace = value => {
      if (typeof value === 'string') {
        return value.replace('{{URL}}', location.origin);
      }

      return value;
    };

    Object.keys(config.flow).forEach(key => {
      flow[key] = replace(config.flow[key]);
    });

    Object.keys(config.endpoints).forEach(key => {
      endpoints[key] = replace(config.endpoints[key]);
    });


    this.oidc.setupModule(flow, endpoints);
  }

}
