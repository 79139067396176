export function removeAccentuation(original: string, hasSpaces: boolean = false): string {
  if (original) {
    let modified = original.toLowerCase();

    modified = modified.replace(/[àáâãäå]/g, 'a');
    modified = modified.replace(/[èéêë]/g, 'e');
    modified = modified.replace(/[ìíîï]/g, 'i');
    modified = modified.replace(/[òóôõö]/g, 'o');
    modified = modified.replace(/[ùúûü]/g, 'u');
    modified = modified.replace(/ýÿ/g, 'y');
    modified = modified.replace(/ç/g, 'c');
    modified = modified.replace(/ñ/g, 'n');
    modified = modified.replace(/ß/g, 's');

    if (hasSpaces) {
      modified = modified.replace(/[\s\-]+/g, '-');
      modified = modified.replace(/[^0-9a-z\-]/g, '');
    } else {
      modified = modified.replace(/[^0-9a-z]/g, '');
    }

    return modified;
  }

  return '';
}

export function findQueryInString(query: string, search: string): boolean {
  const words = removeAccentuation((query + '').trim(), true).split('-');
  const compare = removeAccentuation(search + '');
  const found = words.map(each => compare.search(each) !== -1).filter(each => each).length;

  return found >= words.length;
}
