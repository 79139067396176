import { IContent } from '@dink/core/models/content.model';
import { ILibraryCollection } from '@dink/core/models/library.model';
import { orderChronologically } from '@dink/core/helpers/sort.helper';
import { checkDatesInJSON } from '@dink/core/helpers/date.helper';
import { PublicationType } from '@dink/core/enums/publication-type.enum';


export function parseContents(collections: ILibraryCollection[], contents: IContent[]): IContent[] {
  const map = collections.reduce((a, b) => {
    if(b){
      a[b.id] = b.name || 'Unknown';
    }
    return a;
  }, {});

  if (contents) {
    contents.map(cont => {
      checkDatesInJSON<IContent>(cont);

      if (cont.contentCollections) {
        cont.contentCollections = cont.contentCollections.map(col => map[col]);
      } else {
        console.log("Collection is null or empty");
        cont.contentCollections = [];
      }
    });
  }

  return contents;
}

export function organizeInCollections(contents: IContent[], max: number = 1000): { [index: string]: IContent[] } {
  const collections = {};
  const ordered = {};

  contents.forEach(p => {
    const has = p.contentCollections && p.contentCollections.length > 0;
    const list = has ? p.contentCollections.filter(c => !!c) : [ 'Default Collection' ];

    list.forEach(c => {
      if (!collections[c]) {
        collections[c] = [];
      }

      if (collections[c].length < max) {
        collections[c].push({ ...p });
      }
    });
  });

  for (const c of Object.keys(collections)) {
    ordered[c] = collections[c].sort(orderChronologically('updated'));
  }

  return ordered;
}

export function getAspect(content: IContent): number {
  const current = content.currentContentVersion;
  const aspect = current.contentType === PublicationType.DINK_ANGULAR.toString() ? '16:9' : (current.aspectRatio || '4:3');

  return aspect.split(':')
    .map(e => parseInt(e, 10))
    .reduce((a, b) => a / b);
}

export const docTypes = [
  PublicationType.PDF.toString(), 
  PublicationType.XLSX.toString(), 
  PublicationType.DOCX.toString(), 
  PublicationType.PPTX.toString(), 
  PublicationType.PPT.toString(), 
  PublicationType.XOD.toString()
];

export const downloadableTypes =  docTypes.concat([
  PublicationType.VIDEO.toString(), 
  PublicationType.DINK_ANGULAR.toString()
]);
