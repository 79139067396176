import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';

import { Subject, BehaviorSubject, Observable, Subscription, zip, interval } from 'rxjs';
import { tap, map, filter, take } from 'rxjs/operators';

import { environment } from '@dink/env/environment';

import { DataService } from '@dink/core/services/data.service';
import { CacheService } from '@dink/core/services/cache.service';
import { DeviceService } from '@dink/core/services/device.service';
import { hasAccountHub } from '@dink/core/helpers/enterprise.helper';
import { IProfile } from '@dink/core/models/profile.model';
import { IEnterprise } from '@dink/core/models/enterprise.model';
import { IContentAccessibility } from '@dink/core/models/content.model';

import { ShareDialogComponent } from '@dink/shared/components/dialogs/share-dialog/share-dialog.component';
import { UserProfileComponent } from '@dink/shared/components/dialogs/user-profile/user-profile.component';
import { StoredFileService } from '@dink/core/services/stored-file.service';
import { UserDataOrder } from '@dink/core/models/account-hub-user-data-order';
import { AccountHubUserService } from '@dink/core/services/account-hub-user.service';

const CONFIDENTIAL_MAP = {
  'public': 1,
  'confidential': 2
};

@Component({
  selector: 'dwa-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {

  updated = false;
  user = false;
  crm: boolean;
  accountHub: string;
  tooltip: boolean;
  mailUrl: string;
  confidential$: Observable<number>;
  enterprise$: Observable<IEnterprise>;
  profile$: Observable<IProfile>;
  shared$: Observable<number>;
  mail$: Observable<number>;
  @Output() change: Subject<boolean>;
  userDataOrder: UserDataOrder[];
  userInitial: string;
  image: any;
  BASE_URL = environment.accountHubUrl;

  private subscription = new Subscription();
  private opened: boolean;
  private saved: boolean;
  private hidden: boolean;
  private timeout: number;


  @Input() set hide(v: boolean) {
    this.hidden = v;

    if ((v && this.opened) || (!v && this.saved)) {
      this.update(!v, false, 500);
    }
  }

  constructor(
    private ahUserService: AccountHubUserService,
    public device: DeviceService,
    private data: DataService,
    private cache: CacheService,
    private router: Router,
    private worker: SwUpdate,
    private dialog: MatDialog,
    private storedFileService: StoredFileService) {
    this.initialize();
  }

  ngOnInit() {
    this.subscription.add(this.worker.available.subscribe(() => {
      console.log('{topnav} UPDATE AVAILABLE');

      this.worker.activateUpdate();
      this.updated = true;
    }));

    this.enterprise$ = this.data.getEnterprise().pipe(tap(enterprise => {
      if (hasAccountHub(enterprise)) {
        this.accountHub = environment.accountHub.iframe;
      }
    }));

    this.profile$ = this.data.getProfile().pipe(tap(profile => {
      this.crm = !!profile.crmInfo.crmEnabled;
      profile.profileImage.url = 'Me/ProfileImage/Download?downloadAsFile=false&ver=' + Date.now();
      this.userInitial = profile.firstName.charAt(0) + profile.lastName.charAt(0);

      if (!profile.isImagePublic) {
        if (profile.defaultImageUrl) {
          this.image = `${this.BASE_URL}${profile.defaultImageUrl}`;
        } else {
          this.image = null;
        }
      } else {
        if (profile && profile.profileImage.url) {
          this.image = 'Me/ProfileImage/Download?downloadAsFile=false&ver=' + Date.now();
        } else if (profile.defaultImageUrl) {
          this.image = `${this.BASE_URL}${profile.defaultImageUrl}`;
        } else {
          profile.profileImage.url = null;
        }
      }
    }));

    this.shared$ = this.data.getShared().pipe(map(publications => publications.length));
    this.mail$ = this.data.getMail().pipe(
      tap(publications => {
        const list = publications.map(p => `${p.title}\n${p.currentContentVersion.temporaryLink}`);
        const body = encodeURIComponent(list.join('\n\n'));

        this.mailUrl = 'mailto:?body=' + body;
      }),
      map(publications => publications.length)
    );

    this.confidential$ = this.data.getAccessibility().pipe(
      tap(value => value === IContentAccessibility.NULL && this.configureTooltip()),
      map(value => CONFIDENTIAL_MAP[value] || 1)
    );
  }

  ngOnDestroy() {
    window.clearTimeout(this.timeout);
    this.subscription.unsubscribe();
  }


  onSidebarClick() {
    this.update(!this.opened, !this.hidden);
  }

  onReloadClick() {
    if (this.updated) {
      this.router.navigate(['/reload']);
    } else {
      this.cache.reset(false);
    }
  }

  onLMSClick(id: string) {
    this.router.navigate([], { queryParams: { 'lms': id } });
  }

  onHubClick() {
    this.dialog.open<ShareDialogComponent>(ShareDialogComponent, {
      width: '1000px',
      panelClass: 'dwa-share-dialog',
      backdropClass: 'dwa-share-dialog-backdrop',
      disableClose: true
    });
  }

  onMailClick() {
    this.data.changeMail([]);
  }

  onUserClick() {
    this.user = !this.user;
  }

  onUserEdit(profile: IProfile) {
    this.user = false;

    this.dialog.open<UserProfileComponent>(UserProfileComponent, {
      width: '500px',
      panelClass: 'dwa-user-profile',
      backdropClass: 'dwa-user-profile-backdrop',
      disableClose: true,
      data: profile
    });
  }

  isFieldShow(fieldKey: string, profile: IProfile) {
    return this.ahUserService.isShowUserField(fieldKey, profile.dataOrder);
  }

  async onAccessibilityClick() {
    const confidential = await this.data.getAccessibility().pipe(take(1)).toPromise();
    const next = confidential !== IContentAccessibility.PUBLIC
      ? IContentAccessibility.PUBLIC
      : IContentAccessibility.CONFIDENTIAL;

    this.tooltip = false;

    this.data.changeAccessibility(next);
  }

  async onClearCachedDataClick() {
    console.log("clearing cached data");
    await this.storedFileService.removeAllStoredFiles();
    alert("All cached content has been removed!");
    await this.onReloadClick();
  }


  private initialize() {
    const status = localStorage.getItem('menu.opened') === 'true';

    this.opened = status;
    this.saved = status;
    this.change = new BehaviorSubject(status);
  }

  private update(value: boolean, save: boolean = false, delay: number = 0) {
    window.clearTimeout(this.timeout);

    const status = value;
    const change = () => this.change.next(status);

    this.opened = status;

    if (save) {
      localStorage.setItem('menu.opened', status.toString());
      this.saved = status;
    }

    if (delay > 0) {
      this.timeout = window.setTimeout(change, 650);
    } else {
      change();
    }
  }

  private configureTooltip() {
    const time = 7000;

    this.tooltip = true;

    zip(
      this.data.getStatus().pipe(filter(status => !!status), take(1)),
      interval(time).pipe(take(1))
    ).subscribe(() => {
      this.tooltip = false;
    });
  }

}
