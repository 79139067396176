import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'dwa-slide-thumbs',
  templateUrl: './slide-thumbs.component.html',
  styleUrls: ['./slide-thumbs.component.sass']
})
export class SlideThumbsComponent implements OnInit {

  prev = false;
  next = true;
  scroll: number;
  @Input() title: string;
  @Input() link: string;
  @Input() pagination: boolean;
  @ViewChild('list', { static: true }) list: ElementRef;


  constructor() {
  }


  ngOnInit() {
  }

  onScroll() {
    const element = this.list.nativeElement;

    this.prev = element.scrollLeft > 0;
    this.next = element.scrollLeft + element.clientWidth < element.scrollWidth - 1;
  }

  onClick(direction: number) {
    const element = this.list.nativeElement;
    const offset = ((element.clientWidth - 70) * direction) + (15 * direction);

    element.scrollBy({ left: offset, behavior: 'smooth' });
  }

}
