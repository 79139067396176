import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef, InjectionToken } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

import { environment } from '@dink/env/environment';

import { IContent } from '@dink/core/models/content.model';

import { DeviceService } from '@dink/core/services/device.service';
import { PublicationService } from '@dink/core/services/publication.service';
import { DKPluginEvents, IChangeDocumentRequest, IPostMessage } from '@dink/core/models/post-message.model';
import { ApiService } from '@dink/core/services/api.service';
import { DataService } from '@dink/core/services/data.service';
import { StatService } from '@dink/core/services/stat.service';
import { StoredFileService } from '@dink/core/services/stored-file.service';
import { PublicationDownloadService } from '@dink/core/services/publication-download.service';
import { PublicationType } from '@dink/core/enums/publication-type.enum';


interface IResizeInfo {
  width: string;
  height: string;
  transform: string;
}

@Component({
  selector: 'dwa-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.sass']
})
export class PdfViewerComponent implements OnInit, AfterViewInit {
  isDocument = false;
  isFirstLoad = true;
  loading = false;
  closing = false;
  isVisible = false;
  error = false;
  pdf = true;
  lms = false;
  history = false;
  contentId: string;
  start: number;
  contentSubject = new Subject<IContent>();
  content$ = this.contentSubject.asObservable();
  safeUrl: SafeResourceUrl;
  recent$: Observable<IContent[]>;
  resize$: Observable<IResizeInfo>;
  @ViewChild('pdfViewerIframe', { static: false }) pdfViewerIframe: ElementRef;

  private openedUrl: string;
  private millisecondsUntilWeAssumePdfViewerIsReady = 2000;

  get url(): string {
    return this.openedUrl;
  }

  set url(v: string) {
    this.openedUrl = v;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(v);
  }

  constructor(
    public device: DeviceService,
    private api: ApiService,
    private data: DataService,
    private stat: StatService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private publication: PublicationService,
    private fileService: StoredFileService,
    private downloadService: PublicationDownloadService
  ) {

  }

  ngOnInit() {
    
    window.setTimeout(() => {
      console.log('[pdf viewer] timeout passed for first load delay');
      this.isFirstLoad = false;
    }, this.millisecondsUntilWeAssumePdfViewerIsReady);

    this.recent$ = this.data.getRecentContents(6).pipe(tap(contents => {
      const current = contents.findIndex(p => this.contentId === p.id);
      const last = contents.length - 1;
      contents.splice(current !== -1 ? current : last, 1);
    }));

    this.resize$ = this.device.size$.pipe(map(size => {
      return <IResizeInfo>{
        width: '100vw',
        height: '90vh',
        transform: ''
      };
    }));

    this.publication.pdfViewerUrlUpdated$.subscribe(
      publication => this.refresh(publication)
    );

    this.url = environment.pdf.viewer;
  }

  ngAfterViewInit() {
    //happens on startup
    this.showViewer(false);
  }

  onClose(mustNavigate = true) {
    if (!this.closing) {
      this.closing = true;
      this.history = false;
      this.error = false;
      this.loading = false;

      this.showViewer(false);
      //sending url as null will close the doc
      this.sendDocChangeMessage(null, null, 0);
      //clear the content info
      this.contentId = null;
      this.contentSubject.next(null);

      if (this.lms) {
        const now = new Date().getTime();
        const duration = Math.round((now - this.start) / 1000);
        this.stat.registerLMSVisit(duration);
      }

      if (mustNavigate && this.publication.checkIfPdfViewerCanNavigate()) {
        this.router.navigate([], { queryParams: null });
      }

    }
  }

  onMailPageClick() {
    this.sendMessage(<IPostMessage>{
      eventName: DKPluginEvents.ScreenshotRequested,
      callbackIdentifier: this.contentId
    });
  }

  onFullscreenClick() {
    //TODO: check if the full screen button in the PDF viewer is good enough
    console.log("full screen requested");
  }

  onHistoryClick() {
    this.history = !this.history;
  }

  async onPublicationClick(publication: IContent) {
    console.log("Opening another publication...");
    this.onClose(false);
    this.publication.open(publication);
  }

  private async getXODUrlThrougShortCut(edition:string, isDoc:boolean): Promise<string> {
    let docUrl: string;
    if (isDoc) {
      docUrl = `https://s3.eu-west-1.amazonaws.com/documents.dink.eu/${edition}.pdf`
    } else {
      docUrl = `https://s3.eu-west-1.amazonaws.com/eu.dink.cdn.pdf/${edition}.pdf`
    }
    console.log("[pdf viewer ] docUrl is: " + docUrl);

    const xod = await this.api.getXodForPdf(docUrl).toPromise();
    return xod;
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async refresh(content: IContent) {

    if (this.contentId == content.id) {
      console.log("[pdf viewer ] not refreshing, would be work we already did");
      return;
    }

    this.lms = 'isLMS' in content && content.isLMS;
    this.start = new Date().getTime();
    this.contentId = content.id;
    this.contentSubject.next(content);

    this.loading = true;
    this.closing = false;
    this.error = false;

    const current = content.currentContentVersion;
    const edition = current.id;
    const page = current.startingStoryKey || 1;

    this.isDocument = false;
    if ([
      PublicationType.XLSX.toString(), 
      PublicationType.DOCX.toString(), 
      PublicationType.PPTX.toString(), 
      PublicationType.PPT.toString()
    ].indexOf(current.contentType) > -1) {
      this.isDocument = true;
    }

    //this.showViewer(true);

    if (this.isFirstLoad) {
      this.isFirstLoad = false;
      // This gives a little more time to build the PDF viewer interface,
      // to prevent errors when deep linking
      await this.delay(this.millisecondsUntilWeAssumePdfViewerIsReady);
    }

    if (current.contentType === PublicationType.XOD.toString()) {
      const xod = await this.api.getXodForPdf(edition).toPromise();
      const page = current.startingStoryKey || 1;
      if (xod) {
        this.sendDocChangeMessage(xod, edition, parseInt(`${page}`));
      }
    } else {
      const docLoaded = await this.loadDocument(edition, page);
      if(!docLoaded){
        console.log(`we have to show an error for xod file: ${edition}.xod`);
        this.showViewer(false);
        this.error = true;
        this.loading = false;
        return;
      }
    }

    this.loading = false;
    this.showViewer(true);
  }

  private async loadDocument(edition: string, page: string | number):Promise<boolean> {
    try {
      let xodData = await this.fileService.getFile(`${edition}.xod`);
      if (!xodData) {
        xodData = await this.downloadService.downloadAndStoreDocument(edition, this.isDocument);
      }
      if(!xodData){
        return false;
      }else{
        this.sendDocChangeMessage(xodData, edition, parseInt(`${page}`));
      }
      
    } catch (error) {
      console.log("[file system service] " + error.message);
      const docUrl = await this.downloadService.getXODUrl(edition, this.isDocument);
      this.sendDocChangeMessage(docUrl, edition, parseInt(`${page}`));
    }
    return true;
  }

  private sendDocChangeMessage(docUrl: string | Blob, editionKey: string, page: number) {
    const msg = <IChangeDocumentRequest>{
      callbackIdentifier: "cbId_doc_change",
      eventName: DKPluginEvents.ChangeDocumentRequested,
      pdf: docUrl,
      editionKey: editionKey,
      page: page
    }

    this.sendMessage(msg);
  }

  private showViewer(show: boolean) {
    this.isVisible = show;
    const iframe = <HTMLIFrameElement>this.pdfViewerIframe.nativeElement;
    iframe.style.display = show ? 'block' : 'none';
  }

  private sendMessage(data: IPostMessage) {
    const iframe = <HTMLIFrameElement>this.pdfViewerIframe.nativeElement;
    iframe.contentWindow.postMessage(data, '*');
  }

}
