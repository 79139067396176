<div class="dwa-confirm-dialog" *ngIf="enterprise$ | async as enterprise">
  <h1 class="dwa-confirm-dialog-title">{{ input.title }}</h1>
  <small class="dwa-confirm-dialog-subtitle" [innerHTML]="input.message | paragraph"></small>

  <div class="dwa-confirm-dialog-list">
    <div class="dwa-confirm-dialog-item" *ngFor="let button of input.options">
      <button class="dwa-confirm-dialog-button" (click)="onSelect(button.value)"
          [style.color]="enterprise.applicationSettings?.titleTextColor"
          [class.cancel]="button.value === null">
        {{ button.text }}
      </button>
    </div>
  </div>
</div>
