export enum IEnterpriseContentSharingMode {
  DISABLE_SHARING = 'DISABLE_SHARING',
  EMAIL_ONLY = 'EMAIL_ONLY',
  ACCOUNT_HUB_PROTECTED_ONLY = 'ACCOUNT_HUB_PROTECTED_ONLY',
  ACCOUNT_HUB_OPEN_ONLY = 'ACCOUNT_HUB_OPEN_ONLY',
  ACCOUNT_HUB_ONLY = 'ACCOUNT_HUB_ONLY',
  ACCOUNT_HUB_PROTECTED_ONLY_AND_EMAIL = 'ACCOUNT_HUB_PROTECTED_ONLY_AND_EMAIL',
  ACCOUNT_HUB_OPEN_ONLY_AND_EMAIL = 'ACCOUNT_HUB_OPEN_ONLY_AND_EMAIL',
  ACCOUNT_HUB_AND_EMAIL = 'ACCOUNT_HUB_AND_EMAIL'
}


export interface IEnterprise {
  id: string;
  name: string;
  imageUrl: string;
  userTermsOfUse: string;
  userTermsOfUseLastChange: Date;
  userConsentRequired: boolean;
  languageSelectorEnabled: boolean;
  cRMConnectorEnabled: boolean;
  lMSEnabled: boolean;
  userAccessToAnalyticsEnabled: boolean;
  userAccessToSurveywondersEnabled: boolean;
  updated: Date;
  enterpriseSharingSettings: IEnterpriseSharingSettings;
  defaultLanguage: IEnterpriseLanguage;
  enterpriseLanguages: IEnterpriseLanguage[];
  enterpriseRegions: IEnterpriseRegion[];
  applicationSettings: IEnterpriseAppSettings;
  adminLogo: string;
}

export interface IEnterpriseRegion {
  id: string;
  name: string;
  isDefault: boolean;
  isCountry: boolean;
}

export interface IEnterpriseAppSettings {
  displayLibraryNames: boolean;
  iOSAppUrl: string;
  showAvailableMailApps: boolean;
  toolbarColor: string;
  toolbarExpandedColor: string;
  titleTextColor: string;
  textColor: string;
  libraryNamesColor: string;
}

export interface IEnterpriseSharingSettings {
  accountHubConsentRequired: boolean;
  accountHubTermsOfUse: string;
  accountHubTermsOfUseLastChange: Date;
  accountHubCustomerPageMoreInformationUrl: string;
  contentSharingMode: IEnterpriseContentSharingMode;
}

export interface IEnterpriseLanguage {
  id: string;
  shortName: string;
  longName: string;
}
