import { Component, OnInit, AfterContentInit, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { take, filter } from 'rxjs/operators';

import { AuthService } from '@dink/core/services/auth.service';
import { CacheService } from '@dink/core/services/cache.service';


@Component({
  selector: 'dwa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit, AfterContentInit {

  model: string;

  @HostBinding('class') cssClass = 'dwa-login';
  @HostBinding('class.visible') show = false;
  @ViewChild('field', { static: true }) field: ElementRef;


  constructor(
    private cache: CacheService,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
  }


  ngOnInit() {
    this.configure();
  }

  ngAfterContentInit() {
    if (this.show) {
      this.field.nativeElement.focus();
    }
  }


  onChange() {
    this.auth.email = this.model;
  }

  async onClick() {
    await this.cache.updateUser(this.model);
    this.auth.login();
  }


  private async configure() {
    const setup = await this.auth.setup$.pipe(take(1)).toPromise();
    const current = await this.cache.user$.pipe(filter(u => u !== null), take(1)).toPromise();

    console.log('{login} setup:', setup);

    if (current !== 'NULL') {
      this.auth.email = current;
    }

    if (setup) {
      if (location.hash) {
        this.auth.authorize();
      } else {
        const auth = await this.auth.authorized$.pipe(take(1)).toPromise();
        const query = this.route.snapshot.queryParamMap.get('auth') === 'true';

        if (auth) {
          this.auth.login();
        } else {
          if (current !== 'NULL' || query) {
            this.auth.renew();
          } else {
            this.show = true;
          }
        }
      }
    } else {
      this.show = true;
    }
  }

}
