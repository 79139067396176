import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable, of, Subject } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';

import { environment } from '@dink/env/environment';

import { ApiService } from '@dink/core/services/api.service';
import { DataService } from '@dink/core/services/data.service';
import { DeviceService } from '@dink/core/services/device.service';
import { getAspect } from '@dink/core/helpers/content.helper';
import { IContent } from '@dink/core/models/content.model';
import { IPostMessage, DKPluginEvents } from '@dink/core/models/post-message.model';
import { StoredFileService } from '@dink/core/services/stored-file.service';
import { AuthService } from '@dink/core/services/auth.service';
import { PublicationDownloadService } from '@dink/core/services/publication-download.service';
import { PublicationType } from '@dink/core/enums/publication-type.enum';

interface IResizeInfo {
  width: string;
  height: string;
  transform: string;
}

interface IFullscreenInfo {
  enabled: boolean;
  activated: boolean;
}


const screenful = <Screenfull>screenfull;


@Component({
  selector: 'dwa-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.sass']
})
export class PublicationComponent implements OnInit, OnDestroy {

  toolbar = false;
  closing = false;
  loading = true;
  video = false;
  thumbs = false;
  history = false;
  error = 0;
  another$ = new Subject<IContent>();
  fullscreen: IFullscreenInfo;
  safeUrl: SafeResourceUrl;
  recent$: Observable<IContent[]>;
  resize$: Observable<IResizeInfo>;
  pdf: boolean;
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  @ViewChild('content', { static: false }) content: ElementRef;

  private openedUrl: string;
  private timeout: number;


  get url(): string {
    return this.openedUrl;
  }

  set url(v: string) {
    this.openedUrl = v;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(v);
  }


  get frontend(): boolean {
    return this.thumbs;
  }

  set frontend(v: boolean) {
    this.thumbs = v;
  }


  constructor(
    public device: DeviceService,
    private auth: AuthService,
    private api: ApiService,
    private data: DataService,
    private cookie: CookieService,
    private fileService: StoredFileService,
    private downloadService: PublicationDownloadService,
    private sanitizer: DomSanitizer,
    private detector: ChangeDetectorRef,
    private dialog: MatDialogRef<PublicationComponent>,
    @Inject(MAT_DIALOG_DATA) private input: { content: IContent, controls: boolean }
  ) {
  }


  ngOnInit() {
    const publicationKey = this.input.content.id;

    this.recent$ = this.data.getRecentContents(6).pipe(tap(contents => {
      const current = contents.findIndex(p => publicationKey === p.id);
      const last = contents.length - 1;

      contents.splice(current !== -1 ? current : last, 1);
    }));

    this.resize$ = this.device.size$.pipe(map(size => {
      const content = this.input.content;
      const aspect = getAspect(content);
      const current = content.currentContentVersion;
      const isLMS = 'isLMS' in content && content.isLMS;
      const fullscreen = this.fullscreen && this.fullscreen.activated;
      const maxWidth = fullscreen ? size.width - 70 : size.width - 10;
      const maxHeight = fullscreen ? 1 : environment.publication.maxHeight;
      const height = size.height * maxHeight;
      const width = Math.min(maxWidth, aspect * height);

      if (!isLMS) {
        if (current.contentType === PublicationType.DINK.toString()) {
          const dimensions = {
            '4:3': [1024, 768],
            '3:2': [2160, 1440],
            '16:10': [2560, 1600],
            '16:9': [1366, 768]
          };

          const dimension = dimensions[current.aspectRatio || '4:3'];

          if (dimension) {
            const scale = width / dimension[0];
            const x = (size.width - width) / 2;
            const transform = [`scale(${scale})`];

            if (fullscreen) {
              transform.push(`translateX(${x}px)`);
              transform.reverse();
            }

            return <IResizeInfo>{
              width: dimension[0] + 'px',
              height: dimension[1] + 'px',
              transform: transform.join(' ')
            };
          }
        }
      } else {
        return <IResizeInfo>{
          width: '100vw',
          height: '100vh',
          transform: ''
        };
      }

      return <IResizeInfo>{
        width: Math.ceil(width) + 'px',
        height: Math.ceil(height) + 'px',
        transform: ''
      };
    }));

    this.initialize();
  }

  ngOnDestroy() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  async onMetadata(video: HTMLVideoElement) {
    const size = await this.device.size$.pipe(take(1)).toPromise();
    const maxWidth = size.width - 10;
    const height = size.height * environment.publication.maxHeight;
    const aspect = video.videoWidth / video.videoHeight;
    const width = Math.min(maxWidth, aspect * height);

    this.dialog.updateSize(`${width}px`, `${height}px`);
  }

  onFullscreenClick() {
    this.history = false;

    if (!this.fullscreen.activated) {
      const element = this.content.nativeElement;

      this.fullscreen.activated = true;
      screenful.request(element);
    } else {
      this.fullscreen.activated = false;
      screenful.exit();
    }
  }

  onFullscreenChange() {
    if (!screenful.isFullscreen && this.fullscreen.activated) {
      this.onFullscreenClick();
    }
  }

  onThumbsClick() {
    this.history = false;

    this.sendMessage(<IPostMessage>{
      eventName: DKPluginEvents.ToggleThumbs,
      callbackIdentifier: 'IGNORE'
    });
  }

  onHistoryClick() {
    this.history = !this.history;
  }

  onPublicationClick(publication: IContent) {
    this.another$.next(publication);
    this.another$.complete();
  }

  onMailPageClick() {
    this.sendMessage(<IPostMessage>{
      eventName: DKPluginEvents.ScreenshotRequested,
      callbackIdentifier: this.input.content.id
    });
  }

  onClose() {
    if (!this.closing) {
      if (this.video) {
        console.log("[publication] revoking video object url");
        URL.revokeObjectURL(this.openedUrl);
      }

      this.closing = true;

      if (!this.frontend && !this.pdf) {
        this.dialog.close();
      } else {
        //Only still applies to .dink files, 
        //but we have to be sure that exit page stats are captured.
        //Otherwise publications with just 1 page view will not appear in the stats.

        console.log('[publication] will not close. waiting for stats', { identifier: this.input.content.id });

        //Hiding the dialog to provide instant visual feedback to the user
        this.dialog.updateSize('0', '0');
        const time = environment.publication.statsTimeout * 1000;

        this.sendMessage(<IPostMessage>{
          eventName: DKPluginEvents.StatObjectRequested,
          callbackIdentifier: this.input.content.id
        });

        this.timeout = window.setTimeout(() => {
          console.log('[publication] timeout for stats');
          this.dialog.close();
        }, time);
      }
    }
  }

  close() {
    this.onClose();
  }

  reload() {
    this.loading = true;
    this.error = 0;
    this.url = 'about:blank';

    this.detector.detectChanges();

    this.initialize();
  }


  private initialize() {
    this.checkToolbar();

    const current = this.input.content.currentContentVersion;
    switch (current.contentType) {
      case PublicationType.VIDEO.toString():
        return this.openS3ForVideo();

      case PublicationType.REGULAR.toString():
      case PublicationType.WEBLINK.toString():
      case PublicationType.XOD.toString():
        return this.openRegular();

      case PublicationType.DINK_ANGULAR.toString():
      case PublicationType.ZIP.toString():
      case PublicationType.DINK.toString():
      case PublicationType.BUTTON.toString():
        return this.openCloudfront();

      default:
        console.log(`Publication componnent invalid content type: ${current.contentType}`)
    }
  }

  private async openRegular() {
    let url: string;
    const current = this.input.content.currentContentVersion;
    const edition = current.id;

    if (current.contentType === PublicationType.XOD.toString()) {
      const xod = await this.api.getXodForPdf(edition).toPromise();
      const page = current.startingStoryKey || 1;

      if (xod) {
        url = `${environment.pdf.viewer}?xod=${encodeURIComponent(xod)}&page=${page}`;
      }
    } else {
      if (/^https?\:\/\//.test(current.downloadUrl)) {
        this.error = 3;
        this.fullscreen.enabled = false;
        window.open(current.downloadUrl);
      }

      url = current.downloadUrl;
    }

    this.loading = false;
    this.url = url;
  }

  private async openS3ForVideo() {
    const current = this.input.content.currentContentVersion;
    const edition = current.id;

    let url: string;
    let mustDownloadVideo = false;

    let videoData = await this.fileService.getFile(`${edition}.mp4`);
    if (!videoData) {
      url = await this.api.getSignedS3UrlForEdition(edition, false).toPromise();
      //Don't await, the video player will do a better job then just waiting for the download to end
      mustDownloadVideo = true;
    } else {
      url = URL.createObjectURL(videoData);
    }

    this.video = true;
    this.loading = false;

    if (url) {
      this.url = url;
    } else {
      this.renderError();
    }

    if (mustDownloadVideo) {
      try {
        await this.downloadService.downloadAndStoreVideoFile(edition);
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  private async openCloudfront() {
    const current = this.input.content.currentContentVersion;
    const button = current.contentType === PublicationType.BUTTON.toString();

    const request = await this.api.getSignedCloudfrontCookies(current.id, button).toPromise();
    if (request) {
      Object.keys(request.cookies).forEach(c => {
        this.cookie.set(c, request.cookies[c], null, '/', 'dink.eu');
      });

      this.url = request.url + (current.startingStoryKey ? `#S${current.startingStoryKey}` : '');
    } else {
      this.renderError();
    }

    this.loading = false;
  }

  private checkToolbar() {
    const current = this.input.content.currentContentVersion;

    this.pdf = current.contentType === PublicationType.PDF.toString();

    if ([
      PublicationType.ZIP.toString(), 
      PublicationType.WEBLINK.toString(), 
      PublicationType.DINK_ANGULAR.toString(), 
      PublicationType.BUTTON.toString()
    ].indexOf(current.contentType) !== -1) {
      this.toolbar = true;

      this.fullscreen = {
        enabled: screenful.enabled,
        activated: false
      };
    }
  }

  private sendMessage(data: IPostMessage) {
    const iframe = <HTMLIFrameElement>this.iframe.nativeElement;
    iframe.contentWindow.postMessage(data, '*');
  }

  private renderError() {
    const current = this.input.content.currentContentVersion;

    if ([
      PublicationType.ZIP.toString(), 
      PublicationType.DINK_ANGULAR.toString()
    ].indexOf(current.contentType) !== -1) {
      this.error = 1;
    } else {
      this.error = 2;
    }
  }

}
