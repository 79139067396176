export enum PublicationType {
  DINK_ANGULAR = 'dink_angular',
  PDF = 'pdf', 
  XLSX = 'xlsx', 
  DOCX = 'docx', 
  DINK = 'dink',
  PPTX = 'pptx', 
  PPT = 'ppt', 
  XOD = 'xod',
  VIDEO = 'video',
  ZIP = 'zip',
  WEBLINK = 'weblink',
  BUTTON = 'button',
  REGULAR = 'regular'
}
