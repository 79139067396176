<div class="dwa-login-container">
  <h1 class="dwa-title">{{'LOGIN.TITLE' | translate}}</h1>
  <small class="dwa-subtitle dwa-login-subtitle">{{'LOGIN.DESCRIPTION' | translate}}</small>

  <form class="dwa-login-form">
    <label class="dwa-field dwa-login-field">
      <span class="dwa-field-label"><i class="dwa-icon-font dwa-login-icon-username"></i></span>
      <input #field class="dwa-field-input" autocomplete="on" type="email" name="username"
          placeholder="{{'LOGIN.USERNAME' | translate}}" [(ngModel)]="model" (ngModelChange)="onChange()">
    </label>

    <div class="dwa-login-bottom">
      <button class="dwa-button dwa-login-bottom-submit" (click)="onClick()">
        {{'LOGIN.BUTTON' | translate}}
      </button>
    </div>
  </form>
</div>
