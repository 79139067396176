import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import { ApiService } from '@dink/core/services/api.service';
import { DataService } from '@dink/core/services/data.service';
import { AuthService } from '@dink/core/services/auth.service';
import { StatService } from '@dink/core/services/stat.service';
import { IEnterprise } from '@dink/core/models/enterprise.model';
import { DKPluginEvents, IPostMessage } from '@dink/core/models/post-message.model';
import { IProfileCrmCredential, IProfile } from '@dink/core/models/profile.model';


@Component({
  selector: 'dwa-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.sass']
})
export class CRMComponent implements OnInit, OnDestroy {

  login = false;
  error = false;
  loading = false;
  credentials = <IProfileCrmCredential>{};
  url: SafeUrl;
  enterprise: IEnterprise;
  profile: IProfile;
  @ViewChild('iframe', { static: true }) iframe: ElementRef;

  private start: Date;
  private meetingStarted: Date;
  private subscription: Subscription;


  constructor(
    private api: ApiService,
    private data: DataService,
    private auth: AuthService,
    private stat: StatService,
    private cookie: CookieService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private detector: ChangeDetectorRef
  ) {
  }


  async ngOnInit() {
    this.enterprise = await this.data.getEnterprise().pipe(take(1)).toPromise();
    this.profile = await this.data.getProfile().pipe(take(1)).toPromise();

    if ('crmInfo' in this.profile && this.profile.crmInfo.crmEnabled) {
      const credentials = this.auth.CRMCredentials;

      if (credentials) {
        this.openCloudfront();
      } else {
        this.login = true;
      }
    } else {
      this.router.navigate([ '/dashboard' ]);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.registerStat();
  }


  onLoginClick() {
    this.registerCookie();
  }

  @HostListener('window:beforeunload')
  onUnload() {
    this.registerStat();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    const iframe = this.iframe.nativeElement;

    if (event.source === iframe && 'eventName' in event.data && this.profile.crmInfo.crmEnabled) {
      const data = <IPostMessage>event.data;

      console.log('{crm} event received:', data.eventName);

      switch (data.eventName) {
        case DKPluginEvents.StartMeetingRequested: return this.onMeetingStarted();
        case DKPluginEvents.StopMeetingRequested: return this.onMeetingStopped();
      }
    }
  }


  private async registerCookie() {
    this.loading = true;

    const profile = this.profile;
    const ok = await this.api.loginCrm(profile.crmInfo.crmConfiguration, this.credentials).toPromise();

    this.loading = false;

    if (ok) {
      this.login = false;
      this.auth.CRMCredentials = this.credentials;

      this.openCloudfront();
    } else {
      this.error = true;
    }

    this.detector.markForCheck();
  }

  private async openCloudfront() {
    const profile = this.profile;
    const crm = profile.crmInfo.crmConfiguration.id;
    const request = await this.api.getSignedCloudfrontCookies(crm).toPromise();

    if (request) {
      Object.keys(request.cookies).forEach(c => {
        this.cookie.set(c, request.cookies[c], null, '/', 'dink.eu');
      });

      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(request.url);
      this.start = new Date();

      if (this.stat.interval > 0) {
        this.subscription = timer(0, this.stat.interval).subscribe(() => {
          this.registerStat();
        });
      } else {
        this.registerStat();
      }
    } else {
      this.login = true;
      this.error = false;
    }
  }

  private registerStat() {
    if (this.start) {
      const current = new Date().getTime();
      const duration = Math.round((current - this.start.getTime()) / 1000);

      this.stat.registerCRMVisit(duration);
    }
  }

  private onMeetingStarted() {
    this.meetingStarted = new Date();
  }

  private onMeetingStopped() {
    if (this.meetingStarted) {
      const current = new Date();
      const id = this.profile.crmInfo.crmConfiguration.id;

      this.stat.registerCRMMeeting(id, this.meetingStarted, current);

      this.meetingStarted = null;
    }
  }

}
