import { Directive, Input, HostListener } from '@angular/core';

import { PublicationService } from '@dink/core/services/publication.service';
import { IContent, IContentVersion } from '@dink/core/models/content.model';
import { PublicationType } from '@dink/core/enums/publication-type.enum';
import { PublicationDownloadService } from '@dink/core/services/publication-download.service';


@Directive({
  selector: '[dwaPublicationLink]'
})
export class PublicationLinkDirective {

  private publication: IContent;


  @Input('dwaPublicationLink') set input(v: IContent | string) {
    if (typeof v === 'string') {
      this.publication = <IContent>{
        currentContentVersion: <IContentVersion>{ id: v, contentType: PublicationType.BUTTON.toString() }
      };
    } else {
      this.publication = v;
    }
  }


  constructor(
    private service: PublicationService,
    private downloadService: PublicationDownloadService
  ) {
  }

  @HostListener('click') async onClick() {
    const isAvailable = await this.downloadService.contentIsAvailable(this.publication);
    if (isAvailable) {
      this.service.open(this.publication);
    } else {
      this.downloadService.addToQueue(this.publication);
    }
  }

}
