import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { throwError, Observable, timer, iif } from 'rxjs';
import { catchError, retryWhen, concatMap } from 'rxjs/operators';

import { environment } from '@dink/env/environment';


@Injectable()
export class RetryBadGatewayInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const config = environment.api.retry;

    const wait = error => {
      return [ 0, 502, 503, 504 ].indexOf(error.status) !== -1
        ? timer(config.time)
        : throwError(error);
    };

    return next.handle(request).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((error, i) => iif(
          () => i + 1 > config.max,
          throwError(error),
          wait(error)
        ))
      )),
      catchError(errors => throwError(errors))
    );
  }

}
