import { IEnterprise, IEnterpriseContentSharingMode } from '@dink/core/models/enterprise.model';


export function hasAccountHub(enterprise: IEnterprise): boolean {
  const setting = enterprise.enterpriseSharingSettings;
  const open = [
    IEnterpriseContentSharingMode.ACCOUNT_HUB_OPEN_ONLY,
    IEnterpriseContentSharingMode.ACCOUNT_HUB_OPEN_ONLY_AND_EMAIL,
    IEnterpriseContentSharingMode.ACCOUNT_HUB_ONLY,
    IEnterpriseContentSharingMode.ACCOUNT_HUB_AND_EMAIL
  ];

  return setting ? open.indexOf(setting.contentSharingMode) !== -1 : false;
}

export function hasEmailShare(enterprise: IEnterprise): boolean {
  const setting = enterprise.enterpriseSharingSettings;
  const open = [
    IEnterpriseContentSharingMode.EMAIL_ONLY,
    IEnterpriseContentSharingMode.ACCOUNT_HUB_PROTECTED_ONLY_AND_EMAIL,
    IEnterpriseContentSharingMode.ACCOUNT_HUB_OPEN_ONLY_AND_EMAIL,
    IEnterpriseContentSharingMode.ACCOUNT_HUB_AND_EMAIL
  ];

  return setting ? open.indexOf(setting.contentSharingMode) !== -1 : false;
}
