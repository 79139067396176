<form class="dwa-share-dialog-wrapper" *ngIf="enterprise$ | async as enterprise">
  <div class="dwa-share-dialog-header">
    <p class="dwa-share-dialog-title">{{'ACCOUNT_HUB.TITLE' | translate}}</p>
  </div>
  <div class="dwa-share-dialog-form" *ngIf="model">
    <div class="dwa-field dwa-share-dialog-form-group"
      *ngFor="let customer of model.customers; let i = index; trackBy: trackBy">
      <label class="dwa-field dwa-share-dialog-form-field firstname-field">
        <span class="dwa-field-label">{{'ACCOUNT_HUB.CUSTOMER.FIRST_NAME' | translate}}:</span>
        <input class="dwa-field-input" type="text" name="firstname_{{i}}" [disabled]="loading"
          [(ngModel)]="model.customers[i].firstName" (ngModelChange)="onChangeCustomer()">
      </label>

      <label class="dwa-field dwa-share-dialog-form-field lastname-field">
        <span class="dwa-field-label">{{'ACCOUNT_HUB.CUSTOMER.LAST_NAME' | translate}}:</span>
        <input class="dwa-field-input" type="text" name="lastname_{{i}}" [disabled]="loading"
          [(ngModel)]="model.customers[i].lastName" (ngModelChange)="onChangeCustomer()">
      </label>

      <label class="dwa-field dwa-share-dialog-form-field email-field">
        <span class="dwa-field-label">{{'ACCOUNT_HUB.CUSTOMER.EMAIL' | translate}}:</span>
        <input class="dwa-field-input" type="email" name="email_{{i}}" [disabled]="loading"
          [(ngModel)]="model.customers[i].email" (ngModelChange)="onChangeCustomer()">
      </label>

      <button class="dwa-share-dialog-form-action remove-button" tabindex="-1" [disabled]="model.customers.length === 1"
        (click)="onRemoveCustomer(i)" [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"
        [style.color]="enterprise.applicationSettings?.libraryNamesColor"></button>
    </div>

    <div class="dwa-field dwa-share-dialog-form-group language-group">
      <label class="dwa-field dwa-share-dialog-form-field language-field">
        <span class="dwa-field-label">{{'ACCOUNT_HUB.CUSTOMER.LANGUAGE' | translate}}:</span>
        <select class="dwa-field-input dropdown" type="text" name="language" [disabled]="loading"
          [(ngModel)]="model.language" (ngModelChange)="onChangeLanguage()">
          <option value=""></option>
          <option *ngFor="let language of enterprise.enterpriseLanguages" [value]="language.id">{{ language.longName }}
          </option>
        </select>
      </label>
    </div>

    <button class="dwa-share-dialog-form-action add-button inline-buttom" (click)="onAddCustomer()"
      [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"
      [style.color]="enterprise.applicationSettings?.libraryNamesColor">
      <span class="dwa-button-text">{{'ACCOUNT_HUB.ADD' | translate}}</span>
    </button>

    <angular-editor [config]="config" [(ngModel)]="model.message" [ngModelOptions]="{standalone: true}">
    </angular-editor>
  </div>

  <div class="dwa-share-dialog-list" *ngIf="items$ | async as items">
    <div class="dwa-share-dialog-list-item" *ngFor="let item of items; let i = index">
      <span class="dwa-share-dialog-list-title">{{ item.title }}</span>

      <span *ngIf="!item.id" class="dwa-share-dialog-list-percentage">{{ uploading[i]?.progress }}%</span>

      <button class="dwa-share-dialog-list-button" *ngIf="item.id" (click)="toggleLock(i)"
        [ngClass]="{ 'lock-button': item.isSecured, 'unlock-button': !item.isSecured }"></button>
      <button class="dwa-share-dialog-list-button delete-button" (click)="onDelete(i)"></button>
    </div>
  </div>

  <div class="dwa-share-dialog-footer">
    <input #file type="file" name="file" (change)="onUpload($event)" style="display: none">
    <button class="dwa-share-dialog-footer-button upload-button" (click)="file.click()"
      [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"
      [style.color]="enterprise.applicationSettings?.libraryNamesColor">
      <span class="dwa-button-text">{{'ACCOUNT_HUB.CONTENTS.UPLOAD' | translate}}</span>
    </button>

    <!-- <button class="dwa-share-dialog-footer-button preview-button" [disabled]="loading || !valid" (click)="onPreview()"
      [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"
      [style.color]="enterprise.applicationSettings?.libraryNamesColor">
      {{'ACCOUNT_HUB.PREVIEW' | translate}}
    </button> -->

    <button class="dwa-share-dialog-footer-button send-button" [disabled]="loading || !valid" (click)="onSubmit()"
      [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"
      [style.color]="enterprise.applicationSettings?.libraryNamesColor">
      {{'ACCOUNT_HUB.SEND' | translate}}
    </button>
  </div>

  <div class="preview" *ngIf="preview">
    <iframe #iframe class="preview-message"></iframe>

    <button class="dwa-share-dialog-footer-button preview-back" (click)="preview = false"
      [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"
      [style.color]="enterprise.applicationSettings?.libraryNamesColor">
      {{'ACCOUNT_HUB.BACK' | translate}}
    </button>
  </div>
</form>
