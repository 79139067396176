import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatFormFieldControl, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'dwa-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.sass'],
  providers: [
    { provide: MatFormFieldControl, useExisting: CropImageComponent }
  ]
})
export class CropImageComponent implements OnInit {

  public imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperReady: boolean = false;
  aspectRatio: number = 1;
  resizeToWidth: number = 72;
  format: string = 'jpeg';
  @Output() dialogData = new EventEmitter<any>(true);
  isClickedOnce: boolean = false;
  imageName: string = '';
  croppedFile: Blob;

  constructor(
    private dialog: MatDialogRef<CropImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.imageChangedEvent = { target: { files: [this.data.file] } };
    this.imageName = this.data.file.name;
    switch (this.data.file.type) {
      case 'image/png': this.format = 'png'; break;
      case 'image/jpeg': this.format = 'jpeg'; break;
      default: this.format = 'jpeg'; break;
    }
    if (this.data.width === '') {
      this.data.width = 1024;
    }

    if (this.data.height === '') {
      this.data.height = 728;
    }
    this.aspectRatio = (this.data.width / this.data.height);
    this.resizeToWidth = this.data.width;
  }

  imageCroppedBase64(image: string) {
    this.croppedImage = image;
  }

  imageCroppedFile(image: any) {
    this.croppedImage = image;
    this.croppedFile = base64ToFile(image.base64);
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  onCancel() {
    this.close(false);
  }

  onSave() {
    if (this.isClickedOnce === false) {
      const fileModel = {
        image: this.croppedImage,
        blob: this.croppedFile
      };

      this.close(fileModel);
    }
  }

  private close(data: any) {
    this.cropperReady = false;
    this.imageChangedEvent = null;
    this.isClickedOnce = false;
    this.dialog.close(data);
  }
}
