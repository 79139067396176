import { Component, OnInit, OnDestroy, Input, HostBinding, ChangeDetectorRef } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { IMicrositeCacheItem } from '@dink/core/models/account-hub.model';
import { DataService } from '@dink/core/services/data.service';
import { hasAccountHub } from '@dink/core/helpers/enterprise.helper';
import { IEnterprise } from '@dink/core/models/enterprise.model';


@Component({
  selector: 'dwa-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.sass']
})
export class ShareButtonComponent implements OnInit, OnDestroy {

  loading = false;
  cssPrefix = 'share-button';
  active: boolean;
  enterprise$: Observable<IEnterprise>;
  @Input() publicationKey: string;
  @Input() labels: boolean;
  @HostBinding('class.invisible') invisible: boolean;

  private subscription: Subscription;


  @Input('class') set cssClass(v: string) {
    this.cssPrefix = v;
  }


  constructor(
    private data: DataService,
    private detector: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    if (this.publicationKey) {
      this.subscription = this.data.isShared(this.publicationKey).subscribe(f => {
        if (!this.loading) {
          this.active = f;
          this.detector.markForCheck();
        }
      });
    }

    this.enterprise$ = this.data.getEnterprise().pipe(tap(enterprise => {
      this.invisible = !hasAccountHub(enterprise);
    }));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  async onClick() {
    this.loading = true;

    const shared = await this.data.getShared().pipe(take(1)).toPromise();
    const publication = await this.data.getContentByKey(this.publicationKey).pipe(take(1)).toPromise();
    const id = publication.currentContentVersion.id;
    const index = shared.findIndex(item => item.id === id);

    if (shared.length > 0 && index !== -1) {
      shared.splice(index, 1);
    }

    if (!this.active) {
      shared.push(<IMicrositeCacheItem>{
        id: id,
        title: publication.title,
        isSecured: false,
        comment: null,
        type: 'content'
      });
    }

    this.data.changeShared(shared);

    this.loading = false;
  }

}
