<div class="dash-dialog">
  <div class="dash-dialog-header">
    <div class="dash-dialog-title">{{imageName}} ({{data.width}}px - {{data.height}}px)</div>
  </div>
  <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]=aspectRatio
    [resizeToWidth]=resizeToWidth [roundCropper]="false" [format]=format (imageCropped)="imageCroppedFile($event)"
    (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()" style="max-height: 33vh"
    [style.display]="cropperReady ? null : 'none'"></image-cropper>

  <div class="dash-dialog-footer">
    <button class="dwa-button gray" mat-button (click)="onCancel()">
      {{'BUTTONS.CANCEL' | translate}}
    </button>
    <button class="dwa-button" mat-button (click)="onSave(); isClickedOnce = true">
      {{'BUTTONS.SAVE' | translate}}
    </button>
  </div>
</div>
