export const environment = {
  production: true,
  serviceWorkers: false,
  accountHubUrl: 'https://dev-api3.dink.eu/AccountHubApi/v1',
  auth: {
    crm: {
      token: 'CrmCookieIDStats',
      domain: 'dink.eu'
    },
    basic: {
      token: 'CookieIDStats',
      redirect: 'LoginReturnURL',
      domain: 'dink.eu'
    },
    sso: {
      flow: {
        stsServer: 'https://dev-identity.dink.eu',
        redirect_url: '{{URL}}/login',
        client_id: 'dev_angular_webapp',
        response_type: 'id_token token',
        scope: 'openid profile email api:admin',
        post_logout_redirect_uri: '{{URL}}',
        start_checksession: false,
        silent_renew: false,
        silent_renew_url: '{{URL}}/silent-renew.html',
        post_login_route: '/dashboard',
        forbidden_route: '/forbidden',
        unauthorized_route: '/unauthorized',
        log_console_warning_active: true,
        log_console_debug_active: true,
        trigger_authorization_result_event: true,
        max_id_token_iat_offset_allowed_in_seconds: 10
      },
      endpoints: {
        issuer: 'https://dev-identity.dink.eu',
        jwks_uri: 'https://dev-identity.dink.eu/.well-known/openid-configuration/jwks',
        authorization_endpoint: 'https://dev-identity.dink.eu/connect/authorize',
        token_endpoint: 'https://dev-identity.dink.eu/connect/token',
        userinfo_endpoint: 'https://dev-identity.dink.eu/connect/userinfo',
        end_session_endpoint: 'https://dev-identity.dink.eu/connect/endsession',
        check_session_iframe: 'https://dev-identity.dink.eu/connect/checksession',
        revocation_endpoint: 'https://dev-identity.dink.eu/connect/revocation',
        introspection_endpoint: 'https://dev-identity.dink.eu/connect/introspect'
      }
    }
  },
  layout: {
    profile: {
      defaultImage: '/assets/images/structure/header/generic-user.png'
    },
    dashboard: {
      maxRecent: 20,
      maxNew: 20,
      maxArticles: 9,
      maxFavorites: 20
    }
  },
  pdf: {
    viewer: 'https://cdn.dink.eu/dkwebviewer/2.0/index.html'
  },
  publication: {
    origin: ['https://sites.dink.eu', 'https://cdn.dink.eu'],
    maxHeight: 0.8,
    statsTimeout: 2.5
  },
  accountHub: {
    iframe: 'https://dev-grow2.dink.eu/admin/dashboard?auth=true'
  },
  portfolio: {
    manager: 'https://dev-admin3.dink.eu'
  },
  api: {
    cache: 60 * 24 * 7,
    retry: {
      max: 2,
      time: 100
    },
    domain: 'https://dev-api3.dink.eu/ClientApi/v1',
    accountHubUrl: 'https://dev-api3.dink.eu/AccountHubApi/v1',
    methods: {
      profile: {
        get: 'Me',
        favorites: 'Me/Favorites',
        image: 'Me/ProfileImage/Download?downloadAsFile=false'
      },
      enterprise: {
        get: 'Enterprise',
        assets: 'Application/Assets',
        logo: 'iOSGraphics',
        terms: 'Enterprise/TermsOfUse/Accept'
      },
      news: {
        list: 'NewsfeedMessages'
      },
      libraries: {
        get: 'Libraries'
      },
      publications: {
        get: 'ContentVersions',
        search: 'Search',
        angular: 'ContentVersions/{{id}}/SignedCloudFrontCookies',
        button: 'CustomButton/{{id}}/SignedCloudFrontCookies',
        pdf: 'ContentVersions/{{id}}/SignedUrl',
        xod: 'https://xod.dink.eu/get_url.php?requestedUrl='
      },
      accountHub: {
        create: 'AccountHub',
        upload: 'AccountHub/Assets/Upload',
        template: 'AccountHub/Language/{{language}}/GetMessageTemplate',
        limit: 25 * 1024 * 1024
      },
      stats: {
        repeat: 1800,
        visit: 'AnalyticVisit/Visits',
        article: 'NewsfeedMessages/Visits',
        meeting: 'AnalyticMeeting/Meetings'
      },
      viewer: {
        expires: 0,
        preview: 'ContentViewer/ContentVersion/{{id}}/GetPreviewUrl',
        page: 'ContentViewer/Asset/CreateAndGetPreviewUrl',
        create: 'https://56n12xyk7h.execute-api.eu-west-1.amazonaws.com/production/pdf'
      },
      notifications: {
        list: 'UserNotifications',
        new: 'UserNotifications/New',
        viewed: 'UserNotifications/{{id}}/Viewed'
      }
    }
  }
};
