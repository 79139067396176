import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paragraph'
})
export class ParagraphPipe implements PipeTransform {

  transform(origin: string): string {
    const result = origin
      .split('\n')
      .map(line => !line ? '&nbsp' : line)
      .join('</p><p>');

    return `<p>${result}</p>`;
  }

}
