import { Injectable } from '@angular/core';
import { exception } from 'console';

import Dexie from 'dexie';
import { OffineStorageType, OfflineFileStorageService } from '@dink/core/services/file-storage/offline-file-storage.service';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbFileStorageService extends OfflineFileStorageService {

  private db;

  constructor() {
    super();
    //TODO: also check https://dexie.org/docs/StorageManager
    try {
      this.db = new Dexie('WebAppDB');
      this.db.version(1).stores({
        documents: "name, timestamp"
      });
    } catch (error) {
      console.log('[storage] message', { error });
    }
  }

  isSupported(): Boolean {
    // @ts-ignore
    if (this.db != null) {
      return true
    } else {
      return false;
    }
  }

  getType(): OffineStorageType {
    return OffineStorageType.IndexedDB;
  }

  async getBlob(cacheKey: string): Promise<Blob> {
    const entry = await this.db.documents
      .where('name')
      .equals(cacheKey)
      .first();
    if (entry) {
      return entry['document'];
    } else {
      return null;
    }
  }

  async blobExists(cacheKey: string): Promise<boolean> {
    const entry = await this.db.documents
    .where('name')
    .equals(cacheKey)
    .first();

    return entry? true: false;
  }

  async zipExists(cacheKey: string): Promise<boolean> {
    //IndexedDB has no support for zip, so always false;
    return false;
  }

  async copyToken(token:string): Promise<any>{
    throw "Copy token is not supported";
  }

  async getZipIndexHandle(cacheKey: string): Promise<any> {
    throw "Getting zip files is not supported";
  }

  async saveZip(cacheKey: string, blob: Blob): Promise<any>{
    throw "Saving zip files is not supported";
  }

  async saveBlob(cacheKey: string, blob: Blob): Promise<Boolean> {
    const timeStamp = new Date().getTime();

    await this.db.documents.put({
      name: cacheKey,
      document: blob,
      size: blob.size,
      timestamp: timeStamp
    });
    return true;
  }

  async deleteBlob(cacheKey: string): Promise<Boolean> {
    await this.db.documents
      .where('name')
      .equals(cacheKey)
      .delete()
    return true;
  }

  async deleteOldestBlob(): Promise<Boolean> {
    const deleteCount = await this.db.documents
      .orderBy('timestamp')
      .limit(1)
      .delete()

    if (deleteCount > 0) {
      return true;
    } else {
      return false;
    }
  }

  async deleteAllBlobs(): Promise<Boolean> {
    //remove all cached documents
    const futureDate = new Date(Date.now() + 60 * 60 * 1000 * 24 * 7);
    await this.db.documents
      .where('timestamp')
      .below(futureDate)
      .delete();
    return true;
  }

}
