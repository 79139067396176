 <!-- PDF Viewer should be a singleton, to avoid reloading all the pdf viewer code -->
 <div #content class="dwa-publication-dialog-content">
  
  <div *ngIf="content$ | async as content" class="dwa-publication-dialog-toolbar" [class.no-touch]="!device.touch">
    <button (click)="onClose()" [disabled]="closing"
        class="dwa-publication-dialog-toolbar-item dwa-publication-dialog-toolbar-item-button close">
      <svg-icon-sprite class="dwa-publication-dialog-toolbar-item-icon" src="/assets/svg/sprite1.svg#multiply"></svg-icon-sprite>
    </button>
    <!--
    <button (click)="onFullscreenClick()" class="dwa-publication-dialog-toolbar-item dwa-publication-dialog-toolbar-item-button fullscreen">
        <svg-icon-sprite class="dwa-publication-dialog-toolbar-item-icon" src="/assets/svg/sprite1.svg#expand"></svg-icon-sprite>
    </button>
    -->

    <ng-container *ngIf="recent$ | async as recent">
      <div *ngIf="recent.length > 0" class="dwa-publication-dialog-toolbar-item history" [class.active]="history">
        <button class="dwa-publication-dialog-toolbar-item-button history" (click)="onHistoryClick()">
          <svg-icon-sprite class="dwa-publication-dialog-toolbar-item-icon" src="/assets/svg/sprite1.svg#history"></svg-icon-sprite>
        </button>
        <div *ngIf="history" class="dwa-publication-dialog-toolbar-item-submenu">
          <button class="dwa-publication-dialog-toolbar-item-submenu-item" *ngFor="let publication of recent"
              (click)="onPublicationClick(publication)">
            <span><img class="dwa-publication-dialog-toolbar-item-submenu-image" [src]="publication.imageUrl | fixImage"></span>
            <span class="dwa-publication-dialog-toolbar-item-submenu-title">{{ publication.title }}</span>
          </button>
        </div>
      </div>
    </ng-container>

    <dwa-mail-button class="dwa-publication-dialog-toolbar-item" *ngIf="content.accessibility === 'public' && content.distributionIsAllowed"
          [publicationKey]="content.id" [options]="pdf" (requestPage)="onMailPageClick()"></dwa-mail-button>
    
    <dwa-share-button class="dwa-publication-dialog-toolbar-item" *ngIf="content.accessibility === 'public' && content.distributionIsAllowed"
          [publicationKey]="content.id"></dwa-share-button>

    <dwa-favorites-button class="dwa-publication-dialog-toolbar-item" [publicationKey]="content.id"></dwa-favorites-button>

    <dwa-download-button class="dwa-publication-dialog-toolbar-item" *ngIf="content.accessibility === 'public' && content.allowAppDownload" 
          [publicationKey]="content.id"></dwa-download-button>

  </div>

  <ng-container>
      <ng-container *ngIf="resize$ | async as resize">
        <iframe #pdfViewerIframe *ngIf="safeUrl" class="dwa-publication-dialog-content-iframe" allowfullscreen [scrolling]="device.iOS ? 'no' : null"
      [src]="safeUrl" [style.height]="resize.height" [style.width]="resize.width" [style.transform]="resize.transform | safeStyle"></iframe>
      </ng-container>
  </ng-container>

  <div *ngIf="error" class="dwa-publication-dialog-content-error" >
    <p>{{'PUBLICATION.ERROR.DESCRIPTION_5' | translate}}</p>
  </div>

  <div *ngIf="loading" class="dwa-publication-dialog-loader">{{'PUBLICATION.LOADING' | translate}}</div>

</div>

