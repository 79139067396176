import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule, MatFormFieldModule, MatInputModule } from '@angular/material';

import { FixImagePipe } from './pipes/fix-image.pipe';
import { DownloadImagePipe } from './pipes/download-image.pipe';
import { TitlePipe } from './pipes/title.pipe';
import { BackgroundPipe } from './pipes/background.pipe';
import { SecondsPipe } from './pipes/seconds.pipe';
import { FilterContentsPipe } from './pipes/filter-contents.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { BuildUrlPipe } from './pipes/build-url.pipe';
import { ParagraphPipe } from './pipes/paragraph.pipe';
import { PublicationLinkDirective } from '@dink/shared/directives/publication-link.directive';
import { ArticleLinkDirective } from '@dink/shared/directives/article-link.directive';
import { SlideThumbsComponent } from './components/lists/slide-thumbs/slide-thumbs.component';
import { CardThumbsComponent } from './components/lists/card-thumbs/card-thumbs.component';
import { CardThumbsImageComponent } from './components/lists/card-thumbs-image/card-thumbs-image.component';
import { ContentProgressBarComponent } from './components/lists/content-progress-bar/content-progress-bar.component';
import { FavoritesButtonComponent } from './components/buttons/favorites-button/favorites-button.component';
import { DownloadButtonComponent } from './components/buttons/download-button/download-button.component';
import { ShareButtonComponent } from './components/buttons/share-button/share-button.component';
import { MailButtonComponent } from './components/buttons/mail-button/mail-button.component';
import { ImageComponent } from './components/plugins/image/image.component';
import { PublicationComponent } from './components/dialogs/publication/publication.component';
import { LoaderComponent } from './components/dialogs/loader/loader.component';
import { TermsOfUseComponent } from './components/dialogs/terms-of-use/terms-of-use.component';
import { ShareDialogComponent } from './components/dialogs/share-dialog/share-dialog.component';
import { UserProfileComponent } from './components/dialogs/user-profile/user-profile.component';
import { AlertComponent } from './components/dialogs/alert/alert.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PdfViewerComponent } from './components/dialogs/pdf-viewer/pdf-viewer.component';
import { CropImageComponent } from './components/plugins/upload-image/crop-image/crop-image.component';
import { UploadImageComponent } from './components/plugins/upload-image/upload-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { DateFormatPipe } from './pipes/date-format';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const material = [
  MatDialogModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatInputModule,
  MatFormFieldModule,
  MatCheckboxModule,
  DragDropModule,
  MatDividerModule,
  MatTableModule,
  MatSelectModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatSortModule,
  MatProgressBarModule
];

const declarations = [
  FixImagePipe,
  DownloadImagePipe,
  TitlePipe,
  BackgroundPipe,
  SecondsPipe,
  FilterContentsPipe,
  SafeUrlPipe,
  SafeStylePipe,
  BuildUrlPipe,
  ParagraphPipe,
  PublicationLinkDirective,
  ArticleLinkDirective,
  SlideThumbsComponent,
  CardThumbsComponent,
  CardThumbsImageComponent,
  ContentProgressBarComponent,
  FavoritesButtonComponent,
  DownloadButtonComponent,
  ShareButtonComponent,
  MailButtonComponent,
  ImageComponent,
  CropImageComponent,
  DateFormatPipe,
  UploadImageComponent
];

const dialog = [
  PublicationComponent,
  LoaderComponent,
  TermsOfUseComponent,
  ShareDialogComponent,
  UserProfileComponent,
  AlertComponent,
  PdfViewerComponent,
  CropImageComponent
];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    IconSpriteModule,
    AngularEditorModule,
    ImageCropperModule,
    ...material
  ],
  declarations: [
    ...declarations,
    ...dialog
  ],
  exports: [
    FormsModule,
    RouterModule,
    HttpClientModule,
    TranslateModule,
    IconSpriteModule,
    AngularEditorModule,
    ...material,
    ...declarations,
    ...dialog
  ],
  entryComponents: [
    ...dialog
  ],
  providers: [
    FixImagePipe
  ]
})
export class SharedModule {
}
