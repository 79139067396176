import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { IEnterprise } from '@dink/core/models/enterprise.model';
import { IAlertOption } from '@dink/core/models/alert.model';
import { DataService } from '@dink/core/services/data.service';


@Component({
  selector: 'dwa-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.sass']
})
export class AlertComponent implements OnInit {

  enterprise$: Observable<IEnterprise>;


  constructor(
    private data: DataService,
    private dialog: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public input: { title: string, message: string, options: IAlertOption[] }
  ) {
  }


  async ngOnInit() {
    this.enterprise$ = this.data.getEnterprise();
  }


  onSelect(option: string) {
    console.log('{confirm} selected:', option);
    this.dialog.close(option);
  }

  onClose() {
    console.log('{confirm} close');
    this.dialog.close(null);
  }

}
