import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { StoredFileService } from '@dink/core/services/stored-file.service';
import { FixImagePipe } from '@dink/shared/pipes/fix-image.pipe';

@Component({
  selector: 'dwa-card-thumbs-image',
  templateUrl: './card-thumbs-image.component.html',
  styleUrls: ['./card-thumbs-image.component.sass']
})
export class CardThumbsImageComponent implements OnInit, OnDestroy {

  @Input() downloadUrl: string;
  @Input() cacheKey: string;
  public imageUrl: SafeResourceUrl;
  private blobUrl: string;

  constructor(
    private storedFileService: StoredFileService,
    private fixImagePipe: FixImagePipe,
    private sanitizer: DomSanitizer) {
  }


  ngOnInit() {
    console.log(`download url is ${this.downloadUrl}`);
    this.cacheKey = `${this.cacheKey}.png`;
    const downloadUrl = this.fixImagePipe.transform(this.downloadUrl);
    
    this.storedFileService.getFile(this.cacheKey).then(imageData => {
      if (imageData != null) {
        this.createSafeUrl(imageData);
      } else {
        this.storedFileService.downloadAndStoreFile(this.cacheKey, downloadUrl).then(imageData => {
          if (imageData != null) {
            this.createSafeUrl(imageData);
          } else {
            this.imageUrl = downloadUrl;
          }
        })
      }
    });
  }

  private createSafeUrl(imageData: Blob){
    this.blobUrl = URL.createObjectURL(imageData);
    this.imageUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(this.blobUrl);
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.blobUrl);
  }

}
