import { Pipe, PipeTransform } from '@angular/core';

import { CacheService } from '@dink/core/services/cache.service';
import { IContent } from '@dink/core/models/content.model';


@Pipe({
  name: 'filterContents',
  pure: false
})
export class FilterContentsPipe implements PipeTransform {

  constructor(private cache: CacheService) {
  }


  transform(list: IContent[]): IContent[] {
    if (list && list.length > 0) {
      const confidential = this.cache.accessibility$.getValue();
      const compare = 'confidential';

      if (confidential === compare) {
        return list.filter(c => c.accessibility !== compare);
      }
    }

    return list;
  }

}
