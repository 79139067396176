import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { IndexedDbFileStorageService } from '@dink/core/services/file-storage/indexeddb-file-storage.service';
import { FileSystemAccessApiService } from '@dink/core/services/file-storage/file-system-access-api.service';
import { OfflineFileStorageService } from '@dink/core/services/file-storage/offline-file-storage.service';


export const FILE_STORAGE = new InjectionToken<OfflineFileStorageService>('File Storage', {
  providedIn: 'root',
  factory: () => {
    const f = new FileSystemAccessApiService();
    if (f.isSupported()) {
      return f;
    } else {
      const i = new IndexedDbFileStorageService();
      if (i.isSupported()) {
        return i;
      } else {
        return null;
      }
    }
  }
});


@Injectable({
  providedIn: 'root'
})
export class StoredFileService {

  private checkedPersistence = false;
  private askedForQuotaIncrease = false;

  constructor(
    @Optional()
    @Inject(FILE_STORAGE) public fileStorage: OfflineFileStorageService
  ) { }

  async downloadAndStoreFile(cacheKey: string, docUrl: string, isZip:boolean = false): Promise<Blob|any> {

    if(!docUrl){
      return null;
    }
    
    const res = await fetch(docUrl);
    console.log(`[stored-file.service] ${docUrl}`);
    const blob = await res.blob();
    if((!blob) || (blob.size === 0)){
      return null;
    }

    if (this.fileStorage) {
      if(!this.checkedPersistence){
        this.checkedPersistence = true;
        const persisted = await this.fileStorage.isStoragePersisted();
        if((!persisted) && (typeof persisted!== 'undefined')){
          // We must try to make sure files are persisted, otherwise the storage is just "best effort",
          // which means the data could be deleted whenever the browser wants to free up some space.
          await this.fileStorage.persist()
        }
      }

      console.log(`[stored file service] Trying to write ${blob.size} bytes.`);

      let zipIndexHandle:any;
      const remainingStorage = await this.fileStorage.getRemainingAvailableStorage();
      if ((remainingStorage > -1) && (remainingStorage <= blob.size)) {
        if(!this.askedForQuotaIncrease){
          this.askedForQuotaIncrease = true;
          this.fileStorage.requestQuotaIncrease();
        }

        //Free up some space
        //TODO: make this a lot more intelligent
        await this.fileStorage.deleteOldestBlob();
        
        console.log("[stored file service] Storing would exceed storage quota");
      } else {
        if(isZip){
          zipIndexHandle = await this.fileStorage.saveZip(cacheKey, blob);
          return zipIndexHandle;
        }else{
          await this.fileStorage.saveBlob(cacheKey, blob);
        }
        
      }
    }
    return blob;
  }

  async getFile(cacheKey: string, isZip: Boolean = false): Promise<Blob|any> {
    if (this.fileStorage) {
      if(!isZip){
        const blob = await this.fileStorage.getBlob(cacheKey);
        if ((!blob) || (blob.size === 0)) {
          return null;
        } else {
          return blob;
        }
      }else{
        const indexHandle = await this.fileStorage.getZipIndexHandle(cacheKey);
        return indexHandle;
      }
    } else {
      return null;
    }
  }

  async removeAllStoredFiles(){
    await this.fileStorage.deleteAllBlobs();
  }

}