import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { LoginComponent } from '@dink/core/components/pages/login/login.component';
import { LogoutComponent } from '@dink/core/components/pages/logout/logout.component';
import { ReloadComponent } from '@dink/core/components/pages/reload/reload.component';
import { MasterComponent } from '@dink/core/components/pages/master/master.component';
import { UserGuard } from '@dink/core/guards/user.guard';
import { CRMComponent } from '@dink/core/components/pages/crm/crm.component';
import { ViewComponent } from '@dink/core/components/pages/view/view.component';
import { FullscreenComponent } from '@dink/core/components/pages/fullscreen/fullscreen.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { state: 1 }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: { state: 2 }
  },
  {
    path: 'reload',
    component: ReloadComponent,
    data: { state: 3 }
  },
  {
    path: 'fullscreen',
    component: FullscreenComponent,
    data: { state: 4 },
    canActivate: [UserGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: '',
    component: MasterComponent,
    data: { state: 5 },
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { state: 6 },
        canActivate: [UserGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'libraries',
        loadChildren: () => import('./modules/kiosks/kiosks.module').then(m => m.KiosksModule),
        data: { state: 7 },
        canActivate: [UserGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'news',
        loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule),
        data: { state: 8 },
        canActivate: [UserGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'crm',
        component: CRMComponent,
        data: { state: 9 },
        canActivate: [UserGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'view',
        component: ViewComponent,
        data: { state: 10 },
        canActivate: [UserGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'fullscreen',
        component: FullscreenComponent,
        data: { state: 11 },
        canActivate: [UserGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'account-hub',
        loadChildren: () => import('./modules/account-hub/account-hub.module').then(m => m.AccountHubModule),
        data: { state: 12 },
        canActivate: [UserGuard],
        runGuardsAndResolvers: 'always'
      },
      // {
      //   path: 'messages',
      //   loadChildren: () => import('./modules/messages/messages.module').then(m => m.MessagesModule),
      //   data: { state: 13 },
      //   canActivate: [ UserGuard ],
      //   runGuardsAndResolvers: 'always'
      // },
      // {
      //   path: 'contacts',
      //   loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule),
      //   data: { state: 14 },
      //   canActivate: [ UserGuard ],
      //   runGuardsAndResolvers: 'always'
      // },
      // {
      //   path: 'help',
      //   loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule),
      //   data: { state: 15 },
      //   canActivate: [ UserGuard ],
      //   runGuardsAndResolvers: 'always'
      // }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'disabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
