import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { configuration } from '../../../configurations/configuration';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return 'N/A';
    } else {
      return super.transform(value, configuration.DATE_FMT);
    }
  }
}
