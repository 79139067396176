import { Component, OnInit, HostBinding } from '@angular/core';

import { CacheService } from '@dink/core/services/cache.service';


@Component({
  selector: 'dwa-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass']
})
export class LoaderComponent implements OnInit {

  @HostBinding('class.dwa-loader-component') show = false;


  constructor(public cache: CacheService) {
  }


  ngOnInit() {
    this.show = true;
  }

}
