import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { RouterOutlet, Router, NavigationStart } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Subscription, Observable, timer } from 'rxjs';
import { take, filter, startWith, map } from 'rxjs/operators';

import { LoaderComponent } from '@dink/shared/components/dialogs/loader/loader.component';
import { TermsOfUseComponent } from '@dink/shared/components/dialogs/terms-of-use/terms-of-use.component';

import { AuthService } from '@dink/core/services/auth.service';
import { DataService } from '@dink/core/services/data.service';
import { CacheService } from '@dink/core/services/cache.service';
import { StatService } from '@dink/core/services/stat.service';
import { AlertService } from '@dink/core/services/alert.service';
import { PublicationService } from '@dink/core/services/publication.service';

import { masterAnimations } from './master.animations';


@Component({
  selector: 'dwa-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.sass'],
  animations: [ masterAnimations ]
})
export class MasterComponent implements OnInit, OnDestroy {

  opened: boolean;
  version: boolean;
  menu$: Observable<boolean>;
  fullscreen$: Observable<boolean>;
  loading = false;

  private link$: Observable<string>;
  private loader: MatDialogRef<LoaderComponent>;
  private timeouts = { loader: -1, terms: -1 };
  private subscription: Subscription;


  constructor(
    private auth: AuthService,
    private data: DataService,
    private cache: CacheService,
    private stat: StatService,
    private publication: PublicationService,
    private alert: AlertService,
    private router: Router,
    private dialog: MatDialog
  ) {
  }


  ngOnInit() {
    this.cache.verify();
    this.stat.initialize();
    this.publication.initialize();

    if (this.stat.interval) {
      timer(0, this.stat.interval).subscribe(() => {
        this.stat.registerVisit();
      });
    } else {
      this.stat.registerVisit();
    }


    this.link$ = this.router.events.pipe(
      filter(e => e instanceof NavigationStart),
      map(e => (<NavigationStart>e).url),
      startWith(this.router.url),
      map(url => /^\/([^/?]+)([/?].+)?$/g.exec(url) && RegExp.$1)
    );

    this.menu$ = this.link$.pipe(map(url => [ 'crm', 'view' ].includes(url)));
    this.fullscreen$ = this.link$.pipe(map(url => url === 'fullscreen'));


    this.subscription = this.data.getStatus().subscribe(async status => {
      if (status) {
        console.log('{master} LOADED');

        this.hideLoader();

        this.checkUser();
        this.checkNotifications();
      } else {
        console.log('{master} will load');

        this.showLoader();

        try {
          await this.cache.load();
        } catch (error) {
          console.log('{master} CACHE ERROR', error);
          this.auth.renew();
        }
      }
    });
  }

  ngOnDestroy() {
    this.onUnload();

    window.clearTimeout(this.timeouts.loader);
    window.clearTimeout(this.timeouts.terms);

    this.subscription.unsubscribe();

    if (this.loader) {
      this.hideLoader();
    }
  }


  getState(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.state || 0;
  }

  onMenuChanged(status: boolean) {
    this.opened = status;
  }

  onDone() {
    window.scrollTo(0, 0);
  }

  @HostListener('window:beforeunload')
  onUnload() {
    this.stat.registerVisit();
  }


  private showLoader() {
    window.clearTimeout(this.timeouts.loader);

    if (this.loader) {
      this.closeLoader();
    }

    this.loading = true;

    this.loader = this.dialog.open<LoaderComponent>(LoaderComponent, {
      width: `100%`,
      height: `100%`,
      backdropClass: 'dwa-loader-backdrop',
      panelClass: 'dwa-loader'
    });
  }

  private hideLoader() {
    window.clearTimeout(this.timeouts.loader);

    this.loading = false;

    if (this.loader) {
      this.timeouts.loader = window.setTimeout(() => {
        this.closeLoader();
      }, 500);
    }
  }

  private closeLoader() {
    this.loader.close();
    this.loader = null;
  }

  private async checkUser() {
    const [ profile, enterprise ] = await Promise.all([
      this.data.getProfile().pipe(take(1)).toPromise(),
      this.data.getEnterprise().pipe(take(1)).toPromise()
    ]);

    if (/\@dink\.eu$/g.test(profile.email)) {
      this.version = true;
    }

    if (enterprise.userConsentRequired) {
      let show = false;

      if (!profile.userTermsOfUseAccepted) {
        show = true;
      } else {
        const date1 = enterprise.userTermsOfUseLastChange;
        const date2 = profile.lastUserTermsOfUseAcceptance;

        if (date1.getTime() > date2.getTime()) {
          show = true;
        }
      }

      if (show) {
        window.clearTimeout(this.timeouts.terms);

        this.timeouts.terms = window.setTimeout(() => {
          this.dialog.open<TermsOfUseComponent>(TermsOfUseComponent, {
            disableClose: true,
            closeOnNavigation: false,
            panelClass: 'dwa-terms',
            backdropClass: 'dwa-terms-backdrop'
          });
        }, 750);
      }
    }
  }

  private async checkNotifications() {
    const notifications = await this.data.getNotifications().pipe(take(1)).toPromise();
    const recent = notifications.filter(n => !n.viewed);

    for (const item of recent) {
      const result = await this.alert.open<string>('Notification', item.message, [
        { text: 'Delete', value: 'true' },
        { text: 'Remind me later', value: null, bold: true }
      ]);

      if (result) {
        this.data.readNotification(item.id);
      }
    }
  }

}
