import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@dink/env/environment';

import { ILibrary, ILibraryCollection } from '@dink/core/models/library.model';
import { IContent, IContentSearchRequest, IContentSearchResponse, IContentVersion } from '@dink/core/models/content.model';
import { INewsArticle } from '@dink/core/models/news.model';
import {
  IProfile, IProfileCrmCredential, IProfileCrmConfiguration,
  IProfileEditRequest
} from '@dink/core/models/profile.model';
import { IEnterprise } from '@dink/core/models/enterprise.model';
import { IEditionCookies } from '@dink/core/models/edition.model';
import {
  IMicrositeCreationRequest, IMicrositeCreationAnswer,
  IMicrositeAssetResponse, IMicrositeAssetResponseData, IMicrositeFile
} from '@dink/core/models/account-hub.model';
import { IStatVisit, IStatArticle, IStatCRMMeeting } from '@dink/core/models/stat.model';
import { INotification } from '@dink/core/models/notification.model';
import { checkDatesInJSON } from '@dink/core/helpers/date.helper';
import { parseContents } from '@dink/core/helpers/content.helper';
import { orderNumerically } from '@dink/core/helpers/sort.helper';
import { base64toBlob } from '../helpers/upload.helper';
import { ISignedUrl } from '@dink/core/models/signed-url.model';

const config = environment.api;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  server = config.domain;
  accountHub = config.accountHubUrl;

  constructor(
    private http: HttpClient) {
  }


  getProfile(): Observable<IProfile> {
    const url = `${this.server}/${config.methods.profile.get}`;

    return this.http.get<IEnterprise>(url).pipe(
      map(v => checkDatesInJSON<IProfile>(v)),
    );
  }

  saveProfile(data: IProfileEditRequest): Observable<IProfile> {
    const url = `${this.server}/${config.methods.profile.get}`;

    return this.http.put<IEnterprise>(url, data).pipe(
      map(v => checkDatesInJSON<IProfile>(v)),
    );
  }

  uploadProfileImage(file): any {
    const url = `${this.server}/Me/ProfileImage/Upload`;

    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(url, formData);
  }

  getEnterprise(): Observable<IEnterprise> {
    const url = `${this.server}/${config.methods.enterprise.get}`;

    return this.http.get<IEnterprise>(url).pipe(
      map(v => checkDatesInJSON<IEnterprise>(v)),
    );
  }

  acceptUserTerms(): Observable<boolean> {
    const url = `${this.server}/${config.methods.enterprise.terms}`;

    return this.http.post<any>(url, null).pipe(
      map(() => true)
    );
  }

  getAdminLogo(): Observable<string> {
    const url = `${this.server}/${config.methods.enterprise.assets}`;
    const field = config.methods.enterprise.logo;

    return this.http.get<any>(url).pipe(
      map(v => {
        const logo = v.data.find(e => e.assetType === field);
        return logo ? `${this.server}/${logo.url}?downloadAsFile=false` : null;
      }),
    );
  }

  getLibraries(): Observable<ILibrary[]> {
    const url = `${this.server}/${config.methods.libraries.get}`;

    return this.http.get<any>(url).pipe(
      map(v => v.data.map(i => checkDatesInJSON<ILibrary>(i))),
    );
  }

  getContents(library: string): Observable<{ contents: IContent[], collections: ILibraryCollection[] }> {
    const url = `${this.server}/${config.methods.libraries.get}/${library}`;

    return this.http.get<ILibrary>(url).pipe(
      map(v => ({
        contents: parseContents(v.collections, v.contents),
        collections: v.collections.sort(orderNumerically('position'))
      })),
    );
  }

  getContentVersion(id: string): Observable<IContentVersion> {
    const url = `${this.server}/${config.methods.publications.get}/${id}`;

    return this.http.get<IContentVersion>(url).pipe(
      map(v => checkDatesInJSON<IContentVersion>(v))
    );
  }

  searchContent(data: IContentSearchRequest): Observable<IContentSearchResponse[]> {
    const url = `${this.server}/${config.methods.publications.search}`;

    return this.http.post<any>(url, data).pipe(
      map(v => <IContentSearchResponse[]>v.results)
    );
  }

  getSignedCloudfrontCookies(contentId: string, button: boolean = false): Observable<IEditionCookies> {
    const type = button ? 'button' : 'angular';
    const method = config.methods.publications[type].replace('{{id}}', contentId);
    const url = `${this.server}/${method}`;

    return this.http.get<IEditionCookies>(url);
  }

  getSignedS3UrlForEdition(contentId: string, isDocument: Boolean = false): Observable<string> {
    const method = config.methods.publications.pdf.replace('{{id}}', contentId);
    const url = `${this.server}/${method}`;

    return this.http.get<ISignedUrl>(url).pipe(
      map(v => {
        let url = v.url
        if (isDocument && (v.viewerUrl != null) && (v.viewerUrl.length > 0)) {
          url = v.viewerUrl;
        }
        return url;
      })
    );
  }

  getXodForPdf(pdfUrl: string): Observable<string> {
    const url = config.methods.publications.xod + encodeURIComponent(pdfUrl);

    return this.http.get<any>(url).pipe(
      map(v => v.result ? v.url : null),
    );
  }

  getNews(): Observable<INewsArticle[]> {
    const url = `${this.server}/${config.methods.news.list}`;

    return this.http.get<any>(url).pipe(
      map(v => v.data.map(i => checkDatesInJSON<INewsArticle>(i))),
    );
  }

  getArticleText(id: string): Observable<string> {
    const url = `${this.server}/${config.methods.news.list}/${id}`;

    return this.http.get<INewsArticle>(url).pipe(
      map(v => v.message),
    );
  }

  getFavorites(): Observable<string[]> {
    const url = `${this.server}/${config.methods.profile.favorites}`;

    return this.http.get<any>(url).pipe(
      map(obj => obj.data.map(content => content.id)),
    );
  }

  addFavorite(content: string): Observable<boolean> {
    const url = `${this.server}/${config.methods.profile.favorites}/${content}`;

    return this.http.post<any>(url, null).pipe(
      map(() => true),
    );
  }

  removeFavorite(content: string): Observable<boolean> {
    const url = `${this.server}/${config.methods.profile.favorites}/${content}`;

    return this.http.delete<any>(url).pipe(
      map(() => true),
    );
  }

  createMicrosite(data: IMicrositeCreationRequest): Observable<boolean> {
    const url = `${this.server}/${config.methods.accountHub.create}`;

    return this.http.post<IMicrositeCreationAnswer>(url, data).pipe(
      map(() => true),
    );
  }

  getAccountHubTemplate(language: string): Observable<string> {
    const method = config.methods.accountHub.template.replace('{{language}}', language);
    const url = `${this.server}/${method}`;

    return this.http.get(url, { responseType: 'text' });
  }

  uploadAsset(file: File | IMicrositeFile, base64: boolean = false): Observable<IMicrositeAssetResponse> {
    const url = `${this.server}/${config.methods.accountHub.upload}`;

    const data: FormData = new FormData();
    const response = new BehaviorSubject<IMicrositeAssetResponse>({ progress: 0, data: null });

    if (!base64) {
      const f = <File>file;
      data.append('file', f, f.name);
    } else {
      const f = (<IMicrositeFile>file);
      data.append('file', base64toBlob(f.base64, f.type), f.name);
    }

    const request = new HttpRequest('POST', url, data, { reportProgress: true });

    this.http.request(request)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const done = Math.round(100 * event.loaded / event.total);
          response.next({ progress: done, data: null });
        } else if (event instanceof HttpResponse) {
          response.next({ progress: 100, data: <IMicrositeAssetResponseData>event.body });
          response.complete();
        }
      });

    return response.asObservable();
  }

  getImage(method: string): Observable<string> {
    const parsed = method.startsWith('/') ? method.substr(1) : method;
    const url = `${this.server}/${parsed}`;

    return new Observable<string>(observer => {
      this.http.get(url, { responseType: 'blob' }).subscribe(image => {
        const reader = new FileReader();

        reader.readAsDataURL(image);
        reader.onloadend = () => {
          observer.next(<string>reader.result);
          observer.complete();
        };
      });
    });
  }

  registerVisit(data: IStatVisit): Observable<boolean> {
    const url = `${this.server}/${config.methods.stats.visit}`;
    const headers = new HttpHeaders({ 'App-Platform': 'WEB' });

    return this.http.post(url, [data], { headers }).pipe(
      map(() => true)
    );
  }

  registerArticle(data: IStatArticle): Observable<boolean> {
    const url = `${this.server}/${config.methods.stats.article}`;
    const headers = new HttpHeaders({ 'App-Platform': 'WEB' });

    return this.http.post(url, [data], { headers }).pipe(
      map(() => true)
    );
  }

  getPreviewUrl(content: IContent): Observable<string> {
    const method = config.methods.viewer.preview.replace('{{id}}', content.currentContentVersion.id);
    const expiration = config.methods.viewer.expires;
    const url = `${this.server}/${method}`;
    let param = '';

    if (expiration > 0) {
      const expires = new Date(Date.now() + (1000 * expiration));
      param = '?expires=' + encodeURIComponent(expires.toISOString());
    }

    return this.http.get<any>(url + param).pipe(
      map(obj => obj.url),
    );
  }

  generatePDFFromImage(file: string): Observable<string> {
    const url = config.methods.viewer.create;
    const data = {
      imageData: file,
      mimeType: 'image/png'
    };

    return this.http.post<any>(url, data).pipe(
      map(obj => obj.data),
    );
  }

  createAndGetPreviewUrl(file: string, name: string): Observable<string> {
    const expiration = config.methods.viewer.expires;
    const url = `${this.server}/${config.methods.viewer.page}`;
    const data: FormData = new FormData();

    data.append('file', base64toBlob(file, 'application/pdf'), name);
    data.append('expires', new Date(Date.now() + (1000 * expiration)).toISOString());

    return this.http.post<any>(url, data).pipe(
      map(obj => obj.url),
    );
  }

  loginCrm(configuration: IProfileCrmConfiguration, credentials: IProfileCrmCredential): Observable<boolean> {
    const url = `${configuration.connectorServiceUrl}/users/validateUser`;
    const user = btoa(`${credentials.username}:${credentials.password}`);

    let headers = new HttpHeaders();

    headers = headers.set('Authorization', `Basic ${user}`);
    headers = headers.set('OrganizationSvc', configuration.organizationServiceUrl);

    if (configuration.crmType === 'SALESFORCE') {
      headers = headers.set('ConsumerKey', configuration.clientKey);
      headers = headers.set('ConsumerSecret', configuration.secretKey);
    }

    return this.http.get(url, { headers }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  registerMeeting(data: IStatCRMMeeting): Observable<boolean> {
    const url = `${this.server}/${config.methods.stats.visit}`;
    const headers = new HttpHeaders({ 'App-Platform': 'WEB' });

    return this.http.post(url, [data], { headers }).pipe(
      map(() => true)
    );
  }

  getNotifications(): Observable<INotification[]> {
    const url = `${this.server}/${config.methods.notifications.list}`;

    return this.http.get<any>(url).pipe(
      map(v => v.data.map(i => checkDatesInJSON<INotification>(i)))
    );
  }

  getNewNotifications(): Observable<string[]> {
    const url = `${this.server}/${config.methods.notifications.new}`;

    return this.http.get<any>(url).pipe(
      map(v => v.data.map(i => (<INotification>i).id))
    );
  }

  readNotification(id: string): Observable<boolean> {
    const method = config.methods.notifications.viewed.replace('{{id}}', id);
    const url = `${this.server}/${method}`;

    return this.http.put(url, null).pipe(
      map(() => true),
    );
  }

}
