import { Injectable } from '@angular/core';

import { from, fromEvent } from 'rxjs';
import { startWith, map, mergeMap, filter } from 'rxjs/operators';

import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';

import { IScreenSize } from '@dink/core/models/screen-size.model';


const screenful = <Screenfull>screenfull;


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  readonly iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  readonly touch = ('ontouchstart' in document.documentElement);
  readonly fullscreen = screenful.enabled;

  readonly size$ = from([
    'resize',
    'orientationchange',
    this.fullscreen ? screenful.raw.fullscreenchange : null
  ]).pipe(
    filter(event => !!event),
    mergeMap(event => fromEvent(window, event)),
    startWith(this.getSize()),
    map(() => this.getSize())
  );


  constructor() {
  }


  private getSize(): IScreenSize {
    return <IScreenSize>{
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

}
