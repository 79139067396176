<form class="dwa-user-profile-wrapper" *ngIf="enterprise$ | async as enterprise">
  <div class="dwa-user-profile-header">
    <p class="dwa-user-profile-title">{{'USER_PROFILE.TITLE' | translate}}</p>
  </div>
  <div class="dwa-user-profile-form">
    <div class="dwa-field dwa-user-profile-form-group">
      <label class="dwa-field dwa-user-profile-form-field firstname-field">
        <span class="dwa-field-label">{{'USER_PROFILE.FIELDS.FIRST_NAME' | translate}}:</span>
        <input class="dwa-field-input" type="text" name="firstname" [disabled]="loading" [(ngModel)]="model.firstName"
          (ngModelChange)="onChange()">
      </label>
    </div>
    <div class="dwa-field dwa-user-profile-form-group">
      <label class="dwa-field dwa-user-profile-form-field lastname-field">
        <span class="dwa-field-label">{{'USER_PROFILE.FIELDS.LAST_NAME' | translate}}:</span>
        <input class="dwa-field-input" type="text" name="lastname" [disabled]="loading" [(ngModel)]="model.lastName"
          (ngModelChange)="onChange()">
      </label>
    </div>
    <div class="dwa-field dwa-user-profile-form-group">
      <div class="input-container">
        <div class="input-public">
          <mat-checkbox [(ngModel)]="model.isImagePublic" [checked]="model.isImagePublic" name="isImagePublic">
          </mat-checkbox>
        </div>
        <div class="input-value">
          <span class="dwa-field-label">{{'USER_PROFILE.FIELDS.PROFILE_PICTURE' | translate}}:</span>
        </div>
      </div>
      <label class="dwa-field dwa-user-profile-form-field lastname-field">
        <img class="image-preview dwa-topnav-menu-avatar" *ngIf="profileImage" [src]="profileImage.base64" />
        <div *ngIf="!profileImage" class="dwa-topnav-menu-avatar"
          [style.background-image]="input?.profileImage?.url | downloadImage | async | background"></div>
        <dwa-upload-image #iconUpload placeholder="{{'DIALOG.EDIT_USER.AVATAR' | translate}}" name="iconFilename"
          accept=".png, .jpg, .jpeg" maxSize="150KB" [progress]="iconProgress" (change)="onImageSelected($event)"
          [width]="72" [height]="72"></dwa-upload-image>
        <mat-hint align="start">
          <b>{{'USER_PROFILE.IMAGE_MAXIMUM_SIZE' | translate}}: </b>{{'USER_PROFILE.IMAGE_FILE_SIZE' | translate}}
        </mat-hint>
      </label>
    </div>

    <div class="input-container" *ngIf="isFieldShow('Phone number')">
      <div class="input-public">
        <mat-checkbox [(ngModel)]="model.isPhoneNumberPublic" [checked]="model.isPhoneNumberPublic"
          name="isPhoneNumberPublic"></mat-checkbox>
      </div>
      <div class="input-value">
        <label class="dwa-field dwa-user-profile-form-field phone-field">
          <span class="dwa-field-label">{{'USER_PROFILE.FIELDS.PHONE_NUMBER' | translate}}:</span>
          <input class="dwa-field-input" type="text" name="phonenumber" [disabled]="loading"
            [(ngModel)]="model.phoneNumber" (ngModelChange)="onChange()">
        </label>
      </div>
    </div>

    <div class="input-container" *ngIf="isFieldShow('Title')">
      <div class="input-public">
        <mat-checkbox [(ngModel)]="model.isTitlePublic" [checked]="model.isTitlePublic" name="isTitlePublic">
        </mat-checkbox>
      </div>
      <div class="input-value">
        <label class="dwa-field dwa-user-profile-form-field phone-field">
          <span class="dwa-field-label">{{'USER_PROFILE.FIELDS.TITLE' | translate}}:</span>
          <input class="dwa-field-input" type="text" name="title" [disabled]="loading" [(ngModel)]="model.title"
            (ngModelChange)="onChange()">
        </label>
      </div>
    </div>

    <div class="input-container" *ngIf="isFieldShow('Company website')">
      <div class="input-public">
        <mat-checkbox [(ngModel)]="model.isCompanyWebSitePublic" [checked]="model.isCompanyWebSitePublic"
          name="isCompanyWebSitePublic"></mat-checkbox>
      </div>
      <div class="input-value">
        <label class="dwa-field dwa-user-profile-form-field phone-field">
          <span class="dwa-field-label">{{'USER_PROFILE.FIELDS.COMPANY_WEB_SITE' | translate}}:</span>
          <input class="dwa-field-input" type="text" name="companyWebSite" [disabled]="loading"
            [(ngModel)]="model.companyWebSite" (ngModelChange)="onChange()">
        </label>
      </div>
    </div>

    <div class="input-container" *ngIf="isFieldShow('Company address')">
      <div class="input-public">
        <mat-checkbox [(ngModel)]="model.isCompanyAddressPublic" [checked]="model.isCompanyAddressPublic"
          name="isCompanyAddressPublic"></mat-checkbox>
      </div>
      <div class="input-value">
        <label class="dwa-field dwa-user-profile-form-field phone-field">
          <span class="dwa-field-label">{{'USER_PROFILE.FIELDS.COMPANY_ADDRESS' | translate}}:</span>
          <input class="dwa-field-input" type="text" name="companyAddress" [disabled]="loading"
            [(ngModel)]="model.companyAddress" (ngModelChange)="onChange()">
        </label>
      </div>
    </div>

    <div class="input-container" *ngIf="isFieldShow('VAT number')">
      <div class="input-public">
        <mat-checkbox [(ngModel)]="model.isVatNumberPublic" [checked]="model.isVatNumberPublic"
          name="isVatNumberPublic"></mat-checkbox>
      </div>
      <div class="input-value">
        <label class="dwa-field dwa-user-profile-form-field phone-field">
          <span class="dwa-field-label">{{'USER_PROFILE.FIELDS.VAT_NUMBER' | translate}}:</span>
          <input class="dwa-field-input" type="text" name="vatNumber" [disabled]="loading" [(ngModel)]="model.vatNumber"
            (ngModelChange)="onChange()">
        </label>
      </div>
    </div>

    <div class="input-container" *ngIf="isFieldShow('LinkedIn')">
      <div class="input-public">
        <mat-checkbox [(ngModel)]="model.isLinkedInPublic" [checked]="model.isLinkedInPublic" name="isLinkedInPublic">
        </mat-checkbox>
      </div>
      <div class="input-value">
        <label class="dwa-field dwa-user-profile-form-field phone-field">
          <span class="dwa-field-label">{{'USER_PROFILE.FIELDS.LINKEDIN' | translate}}:</span>
          <input class="dwa-field-input" type="text" name="linkedin" [disabled]="loading" [(ngModel)]="model.linkedIn"
            (ngModelChange)="onChange()">
        </label>
      </div>
    </div>
  </div>

  <div class="dwa-user-profile-footer">
    <button class="dwa-user-profile-footer-button send-button" [disabled]="loading || !valid" (click)="onSubmit()"
      [style.background-color]="enterprise.applicationSettings?.toolbarExpandedColor"
      [style.color]="enterprise.applicationSettings?.libraryNamesColor">
      {{'USER_PROFILE.SEND' | translate}}
    </button>
  </div>
</form>
