<div [class.sidebar-opened]="opened" [class.disable]="loading" [class.fullscreen]="fullscreen$ | async">
  <dwa-topnav class="menu" (change)="onMenuChanged($event)" [hide]="menu$ | async"></dwa-topnav>

  <dwa-sidebar class="sidebar" [open]="opened"></dwa-sidebar>

  <div class="dwa-content content" [@masterTransition]="getState(o)" (@masterTransition.done)="onDone()">
    <router-outlet #o="outlet"></router-outlet>
  </div>
</div>

<div class="dwa-version" *ngIf="version">{{'VERSION' | translate}}</div>
