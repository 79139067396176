import { Component, OnInit, HostBinding } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';

import { take } from 'rxjs/operators';

import { ApiService } from '@dink/core/services/api.service';
import { CacheService } from '@dink/core/services/cache.service';
import { AuthService } from '@dink/core/services/auth.service';
import { DataService } from '@dink/core/services/data.service';
import { extractHTML } from '@dink/core/helpers/html.helper';


@Component({
  selector: 'dwa-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.sass']
})
export class TermsOfUseComponent implements OnInit {

  styles: SafeStyle;
  content: SafeHtml;
  loading = true;
  @HostBinding('class.dwa-terms-component') show = true;


  constructor(
    private api: ApiService,
    private cache: CacheService,
    private auth: AuthService,
    private data: DataService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialogRef<TermsOfUseComponent>
  ) {
  }


  async ngOnInit() {
    const enterprise = await this.data.getEnterprise().pipe(take(1)).toPromise();
    const terms = enterprise.userTermsOfUse;
    const parsed = extractHTML(terms);

    if (parsed) {
      this.styles = this.sanitizer.bypassSecurityTrustStyle(parsed.style);
      this.content = this.sanitizer.bypassSecurityTrustHtml(parsed.content);
      this.loading = false;
    }
  }

  async onClose() {
    this.loading = true;

    try {
      const diff = 1000 * 60 * 10;
      const now = Date.now() - diff;

      await this.api.acceptUserTerms().toPromise();
      await this.cache.requestInfo();

      const profile = await this.data.getProfile().pipe(take(2)).toPromise();
      const date = profile.lastUserTermsOfUseAcceptance;

      if (profile.userTermsOfUseAccepted && date.getTime() >= now) {
        console.log('{terms-of-use} profile.userTermsOfUseAccepted', profile.userTermsOfUseAccepted);
        return this.dialog.close();
      } else {
        console.log('{terms-of-use} date inconsistency', profile.userTermsOfUseAccepted, date, now);
      }
    } catch (err) {
      console.log('{terms-of-use} error while accepting');
      return this.auth.renew();
    }

    this.loading = false;

    console.log('{terms-of-use} should be accepted. retry');
  }

}
