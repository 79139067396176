import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { take, filter } from 'rxjs/operators';

import { AuthService } from '@dink/core/services/auth.service';
import { CacheService } from '@dink/core/services/cache.service';


@Injectable()
export class UserGuard implements CanActivate {

  constructor(
    private cache: CacheService,
    private auth: AuthService,
    private router: Router
  ) {
  }


  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    console.log('(user) canActivate', next, state);

    const setup = await this.auth.setup$.pipe(take(1)).toPromise();
    const current = await this.cache.user$.pipe(filter(u => u !== null), take(1)).toPromise();

    if (setup) {
      const query = next.queryParams['auth'] === 'true';
      const auth = await this.auth.authorized$.pipe(take(1)).toPromise();

      if (auth) {
        return true;
      } else {
        console.log('(user) current user:', current);

        if (current === 'NULL') {
          this.auth.saveURL();
          return this.router.parseUrl('login' + (query ? '?auth=true' : ''));
        } else {
          this.auth.renew(state.url);
        }
      }
    }

    return false;
  }

}
