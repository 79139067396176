import { Component, OnInit, Input } from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { DataService } from '@dink/core/services/data.service';
import { ILibrary } from '@dink/core/models/library.model';
import { IEnterprise } from '@dink/core/models/enterprise.model';


const TOPNAV_HEIGHT = 54;
const ITEM_HEIGHT = 114;


@Component({
  selector: 'dwa-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent implements OnInit {

  height: SafeStyle;
  enterprise$: Observable<IEnterprise>;
  libraries$: Observable<ILibrary[]>;

  private opened: boolean;


  @Input() set open(value: boolean) {
    this.opened = value;
    console.log('{topnav} opened', this.opened);
  }


  constructor(
    private sanitizer: DomSanitizer,
    private data: DataService
  ) {
  }


  ngOnInit() {
    const buttons = TOPNAV_HEIGHT + ITEM_HEIGHT;

    this.enterprise$ = this.data.getEnterprise();
    this.libraries$ = this.data.getLibraries();
    this.height = this.sanitizer.bypassSecurityTrustStyle(`calc(100vh - ${buttons}px)`);
  }

}
