import { Injectable } from '@angular/core';
import { environment } from '@dink/env/environment';
import { HttpClient } from '@angular/common/http';
import { UserDataOrder } from '../models/account-hub-user-data-order';

@Injectable({
  providedIn: 'root'
})
export class AccountHubUserService {

  BASE_PATH = environment.api.accountHubUrl;
  ROUTE_PATH = 'AccountHubUser';

  constructor(private http: HttpClient) { }

  isShowUserField(fieldKey: string, userDataOrder: UserDataOrder[]) {
    let show = false;

    if (userDataOrder && userDataOrder.length) {
      show = userDataOrder.findIndex(_ => _.dataKeyName === fieldKey) > -1;
    }

    return show;
  }
}
