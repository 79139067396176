import { Injectable } from '@angular/core';

import { fromEvent, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@dink/env/environment';

import { ApiService } from '@dink/core/services/api.service';
import { IStatVisit, IStatArticle, IStatCRMMeeting } from '@dink/core/models/stat.model';


@Injectable({
  providedIn: 'root'
})
export class StatService {

  enabled = true;
  interval = environment.api.methods.stats.repeat * 1000;

  private start: Date;
  private sessionID: string;


  constructor(private api: ApiService) {
  }


  initialize() {
    this.start = new Date();
    this.sessionID = btoa((Math.random() * 1e7).toString(16));

    fromEvent(document, 'visibilitychange').subscribe(() => {
      this.enabled = document.visibilityState === 'visible';
    });
  }

  async registerVisit(): Promise<boolean> {
    if (this.enabled) {
      const current = new Date().getTime();
      const duration = Math.round((current - this.start.getTime()) / 1000);

      const data = <IStatVisit>{
        duration,
        entityId: null,
        reference: this.sessionID,
        creationDateLocal: new Date().toISOString(),
        type: 'WebApp'
      };

      console.log('[stat] Regular visit', { duration });

      return await this.api.registerVisit(data)
        .pipe(catchError(() => of(false)))
        .toPromise();
    }

    return false;
  }

  async registerCRMVisit(duration: number): Promise<boolean> {
    if (this.enabled) {
      const data = <IStatVisit>{
        duration,
        entityId: null,
        reference: this.sessionID,
        creationDateLocal: new Date().toISOString(),
        type: 'CRM'
      };

      console.log('[stat] CRM visit', { duration });

      return await this.api.registerVisit(data)
        .pipe(catchError(() => of(false)))
        .toPromise();
    }

    return false;
  }

  async registerCRMMeeting(id: string, start: Date, end: Date): Promise<boolean> {
    if (this.enabled) {
      const data = <IStatCRMMeeting>{
        contentId: id,
        startDateLocal: start,
        endDateLocal: end,
        duration: Math.round((start.getTime() - end.getTime()) / 1000),
        type: 'CRM'
      };

      console.log('[stat] CRM visit', { data });

      return await this.api.registerMeeting(data)
        .pipe(catchError(() => of(false)))
        .toPromise();
    }

    return false;
  }

  async registerLMSVisit(duration: number): Promise<boolean> {
    if (this.enabled) {
      const data = <IStatVisit>{
        duration,
        entityId: null,
        reference: this.sessionID,
        creationDateLocal: new Date().toISOString(),
        type: 'LMS'
      };

      console.log('[stat] LMS visit', { duration });

      return await this.api.registerVisit(data)
        .pipe(catchError(() => of(false)))
        .toPromise();
    }

    return false;
  }

  async registerArticleVisit(id: string, duration: number): Promise<boolean> {
    if (this.enabled) {
      const data = <IStatArticle>{
        duration,
        newsfeedMessageId: id,
        reference: this.sessionID,
        creationDateLocal: new Date().toISOString()
      };

      console.log('[stat] Article visit', { duration });

      return await this.api.registerArticle(data)
        .pipe(catchError(() => of(false)))
        .toPromise();
    }

    return false;
  }

}
