import { Component, OnInit, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, Subscription } from 'rxjs';

import { DataService } from '@dink/core/services/data.service';
import { ApiService } from '@dink/core/services/api.service';
import { IEnterprise } from '@dink/core/models/enterprise.model';
import { CacheService } from '@dink/core/services/cache.service';
import { IProfileEditRequest, IProfile } from '@dink/core/models/profile.model';
import { AccountHubUserService } from '@dink/core/services/account-hub-user.service';

const SNACK_OPTIONS = {
  announcementMessage: 'test',
  panelClass: 'dwa-login-error',
  duration: 5000
};

export class AssetFile {
  public file: File;
  public url: string;
}

@Component({
  selector: 'dwa-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  loading = false;
  valid = false;
  model: IProfileEditRequest;
  enterprise$: Observable<IEnterprise>;
  icon = new AssetFile();
  profileImage: any;

  private subscription: Subscription;

  constructor(
    private ahUserService: AccountHubUserService,
    private data: DataService,
    private cache: CacheService,
    private api: ApiService,
    private detector: ChangeDetectorRef,
    private dialog: MatDialogRef<UserProfileComponent>,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private input: IProfile
  ) {
    this.model = {
      firstName: this.input.firstName,
      lastName: this.input.lastName,
      phoneNumber: this.input.phoneNumber,
      userAppLanguages: this.input.userAppLanguages,
      title: this.input.title,
      companyAddress: this.input.companyAddress,
      companyWebSite: this.input.companyWebSite,
      linkedIn: this.input.linkedIn,
      vatNumber: this.input.vatNumber,
      isPhoneNumberPublic: this.input.isPhoneNumberPublic,
      isCompanyAddressPublic: this.input.isCompanyAddressPublic,
      isCompanyWebSitePublic: this.input.isCompanyWebSitePublic,
      isLinkedInPublic: this.input.isLinkedInPublic,
      isTitlePublic: this.input.isTitlePublic,
      isVatNumberPublic: this.input.isVatNumberPublic,
      isImagePublic: this.input.isImagePublic,
      dataOrder: this.input.dataOrder
    };
  }

  async ngOnInit() {
    this.profileImage = null;
    this.enterprise$ = this.data.getEnterprise();

    this.subscription = this.dialog.backdropClick().subscribe(click => {
      if (this.loading) {
        this.snack.open('Saving profile...', 'CLOSE', SNACK_OPTIONS);
      } else {
        this.close();
      }
    });

    this.onChange();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onImageSelected(f: any) {
    if (f) {
      this.icon = Object.assign(new AssetFile(), { file: f.blob });
      this.profileImage = f.image;
    }
  }

  onChange() {
    const model = this.model;
    const errors = [model.firstName, model.lastName].filter(i => !i || i.length === 0).length;

    this.valid = errors === 0;
    this.detector.markForCheck();
  }

  async onSubmit() {
    this.loading = true;

    try {
      await this.api.saveProfile(this.model).toPromise();

      if (this.icon && this.icon.file) {
        this.api.uploadProfileImage(this.icon.file).subscribe(response => {
          this.cache.requestInfo();
        });
      } else {
        this.cache.requestInfo();
      }

      this.snack.open('Profile saved!', 'CLOSE', SNACK_OPTIONS);

      this.close();
    } catch (err) {
      this.snack.open('Error saving the user\'s profile!', 'CLOSE', SNACK_OPTIONS);
    }

    this.loading = false;
  }

  isFieldShow(fieldKey: string) {
    return this.ahUserService.isShowUserField(fieldKey, this.model.dataOrder);
  }

  private close() {
    this.dialog.close();
  }

}
