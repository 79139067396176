import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';

import { take, tap } from 'rxjs/operators';

import { DataService } from '@dink/core/services/data.service';
import { CacheService } from '@dink/core/services/cache.service';
import { AuthService } from '@dink/core/services/auth.service';
import { ApiService } from '@dink/core/services/api.service';
import { PublicationType } from '@dink/core/enums/publication-type.enum';


@Component({
  selector: 'dwa-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.sass']
})
export class DownloadButtonComponent implements OnInit, OnDestroy {

  loading = false;
  favorite: boolean;
  cssPrefix = 'download-button';
  @Input() publicationKey: string;
  @Input() labels: boolean;


  @Input('class') set cssClass(v: string) {
    this.cssPrefix = v;
  }


  constructor(
    private data: DataService,
    private cache: CacheService,
    private auth: AuthService,
    private api: ApiService,
    private detector: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    if (this.publicationKey) {

    }
  }

  ngOnDestroy() {
  }


  downloadFile = (fileUrl: string) => {
    // Create a link and set the URL using `createObjectURL`
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = fileUrl;
    link.download = '';

    // It needs to be added to the DOM so it can be clicked
    document.body.appendChild(link);
    link.click();

    // To make this work on Firefox we need to wait
    // a little while before removing it.
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);
    }, 0);
  }


  async onClick() {
    let updated: boolean;

    this.loading = true;

    try {
      const publication = await this.data.getContentByKey(this.publicationKey).pipe(take(1)).toPromise();
      if (publication.currentContentVersion.id) {
        const url = await this.api.getSignedS3UrlForEdition(publication.currentContentVersion.id, false).toPromise();
        console.log("url is: " + url);
        if (publication.currentContentVersion.contentType === PublicationType.VIDEO.toString()) {
          window.open(url, '_blank');
        } else {
          this.downloadFile(url);
        }
      }
    } catch (err) {
      console.log('{download-button} ERROR', err);
      this.auth.renew();
    }

    this.loading = false;
  }

}
