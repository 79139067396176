import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import { IProfileCrmCredential } from '@dink/core/models/profile.model';

import { environment } from '@dink/env/environment';
import { CacheService } from './cache.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  setup$: Observable<boolean>;
  authorized$: Observable<boolean>;
  email: string;


  get token(): string {
    return this.oidc.getToken();
  }


  get CRMCredentials(): IProfileCrmCredential {
    const cookie = this.cookie.get(environment.auth.crm.token);

    if (cookie) {
      return <IProfileCrmCredential>JSON.parse(atob(cookie));
    }

    return null;
  }

  set CRMCredentials(value: IProfileCrmCredential) {
    const conf = environment.auth.crm;
    this.cookie.set(conf.token, btoa(JSON.stringify(value)), null, '/', conf.domain, false);
  }


  constructor(
    private cache: CacheService,
    private oidc: OidcSecurityService,
    private cookie: CookieService,
    private router: Router
  ) {
    this.setup$ = this.oidc.getIsModuleSetup();
    this.authorized$ = this.oidc.getIsAuthorized();
  }


  login() {
    if (this.email) {
      this.oidc.setCustomRequestParameters({ login_hint: this.email });
    }

    console.log('[auth] login()');
    this.oidc.authorize();
  }

  logout() {
    console.log('[auth] logout()');
    this.CRMCredentials = null;
    this.oidc.logoff();
  }

  authorize() {
    const env = environment.auth;
    let url = sessionStorage.getItem(env.basic.redirect);

    if (url) {
      sessionStorage.removeItem(env.basic.redirect);
    }

    if (!url || ['/login', '/'].indexOf(url) !== -1) {
      url = env.sso.flow.post_login_route;
    }

    this.oidc.onAuthorizationResult.pipe(take(1)).subscribe(async e => {
      const status = e.authorizationState === 'authorized';

      console.log('[auth] autorized()', status);

      if (status) {
        const token = this.token;
        const data = await this.oidc.getUserData().pipe(take(1)).toPromise();

        console.log('[auth] data.email', data.email);
        console.log('[auth] url', url);

        this.cache.updateUser(data.email);
        this.cookie.set(env.basic.token, token, null, '/', env.basic.domain, false);
        this.router.navigateByUrl(url);
      } else {
        this.renew();
      }
    });

    this.oidc.authorizedImplicitFlowCallback();
  }

  renew(url?: string) {
    console.log('[auth] renew()', url);

    this.saveURL(url);
    this.login();
  }

  saveURL(url?: string) {
    const env = environment.auth.basic;

    if (location.pathname !== '/login') {
      if (!url) {
        url = location.pathname + location.search;
        url = url.replace(/auth=true&?/g, '').replace(/[?&]$/g, '');
      }

      console.log('[auth] saveURL()', url);

      sessionStorage.setItem(env.redirect, url);
    }
  }

  getUserData(): Observable<{ user: string, enterprise: string }> {
    return this.oidc.getUserData().pipe(map(data => ({
      user: data['http://www.dink.eu/claims/userid'],
      enterprise: data['http://www.dink.eu/claims/enterpriseid']
    })));
  }

}
