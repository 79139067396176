import { Pipe, PipeTransform } from '@angular/core';

import { Observable, of } from 'rxjs';

import { ApiService } from '@dink/core/services/api.service';


@Pipe({
  name: 'downloadImage'
})
export class DownloadImagePipe implements PipeTransform {

  constructor(private api: ApiService) {
  }


  transform(method: string): Observable<string> {
    if (method) {
      return this.api.getImage(method);
    }

    return of(null);
  }

}
