<ul *ngIf="data?.length > 0" class="dwa-sidebar-tree-list">
  <li *ngFor="let item of data; let i = index" class="dwa-sidebar-tree-item">
    <a class="dwa-sidebar-tree-link" [style.color]="color" [routerLink]="['/', 'libraries', item.id]"
        routerLinkActive="active">
      {{ item.displayName }}
    </a>

    <dwa-sidebar-tree *ngIf="item.sublibraries.length > 0" [data]="item.sublibraries" [color]="color"></dwa-sidebar-tree>
  </li>
</ul>
