import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'title'
})
export class TitlePipe implements PipeTransform {

  transform(origin: string, max: number = 200): string {
    if (origin && origin.length > max) {
      const last = origin.lastIndexOf(' ', max);
      return origin.substring(0, last) + '...';
    }

    return origin;
  }

}
