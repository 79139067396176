import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { fromEvent, Subscription, Subject } from 'rxjs';
import { take, filter } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';

import { PublicationComponent } from '@dink/shared/components/dialogs/publication/publication.component';
import { ShareDialogComponent } from '@dink/shared/components/dialogs/share-dialog/share-dialog.component';

import { environment } from '@dink/env/environment';

import { ApiService } from '@dink/core/services/api.service';
import { DataService } from '@dink/core/services/data.service';
import { CacheService } from '@dink/core/services/cache.service';
import { DeviceService } from '@dink/core/services/device.service';
import { AlertService } from '@dink/core/services/alert.service';
import { StatService } from '@dink/core/services/stat.service';
import { docTypes, getAspect } from '@dink/core/helpers/content.helper';
import { IContent, IContentVersion } from '@dink/core/models/content.model';
import { ILibrary } from '@dink/core/models/library.model';
import { PublicationType } from '@dink/core/enums/publication-type.enum';

import {
  IPostMessage, DKPluginEvents, IClosePublicationRequest, IClosePublicationHandled, IEventResponse,
  IOpenPublicationRequest, IOpenPublicationHandled, IOpenKioskRequest, IOpenKioskHandled, IOpenBrowserRequest,
  IOpenBrowserHandled, IShowPdfDocumentRequest, IShowPdfDocumentHandled, IShowRemotePdfDocumentRequest,
  IShowRemotePdfDocumentHandled, IChooseContentSharingOptionRequest, IChooseContentSharingOptionHandled,
  ChooseContentSharingOptionResultType, IAddPdfToMicrositeRequest, IAddPdfToMicrositeHandled,
  IScreenshotRequestHandled
} from '@dink/core/models/post-message.model';


interface IContentDialog {
  reference: MatDialogRef<PublicationComponent, IContent>;
  publication: IContent;
  subscription: Subscription;
  afterClose?: ((old: IContentDialog) => void)[];
}


const screenful = <Screenfull>screenfull;


@Injectable({
  providedIn: 'root'
})
export class PublicationService {

  private all = false;
  private ready = false;
  private counter = 0;
  private closed = new Subject<number>();
  private opened: IContentDialog[] = [];
  private current: IContentDialog;
  private controls: boolean;
  private pdfViewerUrlSource = new Subject<IContent>();

  pdfViewerUrlUpdated$ = this.pdfViewerUrlSource.asObservable();


  constructor(
    private api: ApiService,
    private data: DataService,
    private stat: StatService,
    private cache: CacheService,
    private device: DeviceService,
    private cookie: CookieService,
    private alert: AlertService,
    private router: Router,
    private activated: ActivatedRoute,
    private dialog: MatDialog
  ) {
  }


  initialize() {
    this.data.getStatus().subscribe(status => this.ready = status);

    this.activated.queryParams.subscribe(async event => {
      if (!this.current) {
        const lms = 'lms' in event;
        const version = 'version' in event ? event.version : null;

        this.controls = !('controls' in event && event.controls === 'false');

        if ('content' in event || version || lms) {
          if (!version) {
            const key = lms ? 'lms' : 'content';
            const id = event[key];

            if (lms) {
              this.render(<IContent>{
                isLMS: true,
                currentContentVersion: <IContentVersion>{ id, contentType: PublicationType.DINK.toString() }
              });
            } else {
              const publication = await this.data.getContentByKey(id).pipe(take(1)).toPromise();
              this.render(publication);
            }
          } else {
            const publication = await this.api.getContentVersion(version).pipe(take(1)).toPromise();
            this.render(<IContent>{ currentContentVersion: publication });
          }
        }
      }
    });

    fromEvent(window, 'message')
      .pipe(filter(event => this.validate(<MessageEvent>event)))
      .subscribe(event => this.onEvent(<MessageEvent>event));

    if (screenful.enabled) {
      screenful.onchange(() => this.onFullscreen());
    }
  }

  open(publication: IContent) {
    const param = !!publication.isLMS ? 'lms' : 'content';
    this.router.navigate([], { queryParams: { [param]: publication.id } });
    this.render(publication);
  }

  close() {
    if (this.current) {
      this.current.subscription.unsubscribe();
      this.current.reference.componentInstance.close();
    }
  }

  closeAll() {
    this.all = true;
  }

  public checkIfPdfViewerCanNavigate(): Boolean {
    return this.opened.length < 1;
  }

  private renderPdf(publication: IContent, callbacks?: ((old: IContentDialog) => void)[]) {
    const lms = 'isLMS' in publication && publication.isLMS;
    this.pdfViewerUrlSource.next(publication);
    if (lms) {
      this.stat.registerLMSVisit(0);
    }
    this.cache.registerContentOpening(publication.id);
  }

  private async render(publication: IContent, callbacks?: ((old: IContentDialog) => void)[]) {

    if (docTypes.indexOf(publication.currentContentVersion.contentType) > -1) {
      this.renderPdf(publication, callbacks);
      return;
    }

    const lms = 'isLMS' in publication && publication.isLMS;
    const size = await this.device.size$.pipe(take(1)).toPromise();
    const maxWidth = size.width - 10;
    const height = size.height * environment.publication.maxHeight;
    const aspect = getAspect(publication);
    const width = Math.min(maxWidth, aspect * height);
    const start = new Date().getTime();

    const data = <MatDialogConfig<any>>{
      width: `${width}px`,
      height: `${height}px`,
      maxWidth: 'none',
      disableClose: true,
      backdropClass: 'dwa-publication-dialog-backdrop',
      panelClass: this.controls ? 'dwa-publication-dialog-wrapper' : 'dwa-publication-dialog-fullscreen',
      closeOnNavigation: false,
      data: { content: publication, controls: this.controls }
    };

    if (lms) {
      data.width = '100vw';
      data.height = 'calc(100vh - 54px)';

      this.stat.registerLMSVisit(0);
    }

    const reference = this.dialog.open<PublicationComponent, any, IContent>(PublicationComponent, data);

    const subscription = reference.componentInstance.another$.subscribe(async p => {
      this.closeAll();
      this.onClose();

      await this.closed.pipe(take(1)).toPromise();

      this.open(p);
    });


    reference.afterClosed().subscribe(() => {
      const closed = this.opened.pop();
      const current = (this.opened.length > 0) ? this.opened[this.opened.length - 1] : null;

      if (current && closed.afterClose) {
        closed.afterClose.forEach(f => f(current));
      }

      this.current = current;

      this.onClose();

      if (lms) {
        const now = new Date().getTime();
        const duration = Math.round((now - start) / 1000);

        this.stat.registerLMSVisit(duration);
      }
    });


    const dialog = <IContentDialog>{ reference, publication, subscription };

    if (callbacks && callbacks.length > 0) {
      dialog.afterClose = callbacks;
    }

    this.opened.push(dialog);
    this.current = dialog;

    this.cache.registerContentOpening(publication.id);
  }

  private validate(event: MessageEvent): boolean {
    const origins = environment.publication.origin;

    if (this.ready && event && origins.indexOf(event.origin) !== -1) {
      const data = <IPostMessage>event.data;

      if (data.eventName && data.callbackIdentifier) {
        return true;
      }
    }

    return false;
  }

  private onClose() {
    if (this.all) {
      if (this.current) {
        this.close();
      } else {
        this.all = false;
        this.closed.next(++this.counter);
      }
    }

    if (!this.current) {
      this.router.navigate([], { queryParams: null });
    }
  }

  private onFullscreen() {
    this.opened.forEach(item => {
      item.reference.componentInstance.onFullscreenChange();
    });
  }

  private async onEvent(event: MessageEvent = null) {
    const data = <IPostMessage>event.data;
    const message = await this.handleEvents(data);

    if (message) {
      console.log('[publication] message', { event, message });
      (<Window>event.source).postMessage(message, '*');
    }
  }

  private async handleEvents(data: IPostMessage): Promise<IEventResponse> {
    switch (data.eventName) {
      case DKPluginEvents.OpenBrowserRequested:
        return await this.onOpenBrowser(<IOpenBrowserRequest>data);

      case DKPluginEvents.ClosePublicationRequested:
        return await this.onClosePublication(<IClosePublicationRequest>data);

      case DKPluginEvents.OpenPublicationRequested:
        return await this.onOpenPublication(<IOpenPublicationRequest>data);

      case DKPluginEvents.OpenKioskRequested:
        return await this.onOpenKiosk(<IOpenKioskRequest>data);

      case DKPluginEvents.ShowPdfDocumentRequested:
        return await this.onShowPdf(<IShowPdfDocumentRequest>data);

      case DKPluginEvents.ShowRemotePdfDocumentRequested:
        return await this.onShowRemotePdf(<IShowRemotePdfDocumentRequest>data);

      case DKPluginEvents.ChooseContentSharingOptionRequested:
        return await this.onChooseContentSharingOption(<IChooseContentSharingOptionRequest>data);

      case DKPluginEvents.AddPdfToMicrositeRequested:
        return await this.onAddPdfToMicrosite(<IAddPdfToMicrositeRequest>data);

      case DKPluginEvents.FrontendReady:
        return await this.onFrontendReady();

      case DKPluginEvents.StatObjectHandled:
        return await this.onStatObjectHandled(data);

      case DKPluginEvents.ScreenshotRequestHandled:
        return await this.onScreenshotRequestHandled(<IScreenshotRequestHandled>data);
    }

    return null;
  }

  private async onOpenBrowser(data: IOpenBrowserRequest): Promise<IOpenBrowserHandled> {
    this.exitFullscreen();

    if (data.refreshWhenReturningToPublication || data.referenceId) {
      const callbacks: ((old: IContentDialog) => void)[] = [];

      if (data.referenceId) {
        this.cookie.set('LMSReferenceId', data.referenceId, null, '/', 'dink.eu', false);

        callbacks.push(() => {
          this.cookie.delete('LMSReferenceId', '/', 'dink.eu');
        });
      }

      if (data.refreshWhenReturningToPublication) {
        callbacks.push(old => old.reference.componentInstance.reload());
      }

      this.render(<IContent>{
        currentContentVersion: <IContentVersion>{
          contentId: data.url,
          contentType: PublicationType.REGULAR.toString()
        }
      }, callbacks);
    } else {
      window.open(data.url, '_blank');
    }

    return <IOpenBrowserHandled>{
      eventName: DKPluginEvents.OpenBrowserHandled,
      callbackIdentifier: data.callbackIdentifier,
      result: true,
      message: ''
    };
  }

  private async onClosePublication(data: IClosePublicationRequest): Promise<IClosePublicationHandled> {
    this.close();
    this.exitFullscreen();

    return <IClosePublicationHandled>{
      eventName: DKPluginEvents.ClosePublicationHandled,
      callbackIdentifier: data.callbackIdentifier,
      result: true,
      message: ''
    };
  }

  private async onOpenPublication(data: IOpenPublicationRequest): Promise<IOpenPublicationHandled> {
    let content: IContent;

    if (data.publicationKey) {
      content = await this.data.getContentByKey(data.publicationKey).pipe(take(1)).toPromise();
    } else if (data.publicationName) {
      content = await this.data.getContentByName(data.publicationName).pipe(take(1)).toPromise();
    }

    if (content) {
      const callbacks: ((old: IContentDialog) => void)[] = [];

      content.currentContentVersion.startingStoryKey = data.storyKey || null;

      if (data.referenceId) {
        this.cookie.set('LMSReferenceId', data.referenceId, null, '/', 'dink.eu', false);

        callbacks.push(() => {
          this.cookie.delete('LMSReferenceId', '/', 'dink.eu');
        });
      }

      if (!data.returnToPreviousPublicationWhenClosed) {
        callbacks.push(() => this.closeAll());
      } else if (data.refreshWhenReturningToPreviousPublication) {
        callbacks.push(old => old.reference.componentInstance.reload());
      }

      this.exitFullscreen();

      this.render(content, callbacks);
    }

    return <IOpenPublicationHandled>{
      eventName: DKPluginEvents.OpenPublicationHandled,
      callbackIdentifier: data.callbackIdentifier,
      result: !!content,
      message: !content ? 'Publication not found' : ''
    };
  }

  private async onOpenKiosk(data: IOpenKioskRequest): Promise<IOpenKioskHandled> {
    let library: ILibrary;

    if (data.kioskKey) {
      library = await this.data.getLibraryByKey(data.kioskKey).pipe(take(1)).toPromise();
    } else if (data.kioskName) {
      library = await this.data.getLibraryByName(data.kioskName).pipe(take(1)).toPromise();
    }

    if (library) {
      this.closeAll();
      this.exitFullscreen();

      this.router.navigate(['libraries', library.id]);
    }

    return <IOpenKioskHandled>{
      eventName: DKPluginEvents.OpenKioskHandled,
      callbackIdentifier: data.callbackIdentifier,
      result: !!library,
      message: !library ? 'Kiosk not found' : ''
    };
  }

  private async onShowPdf(data: IShowPdfDocumentRequest): Promise<IShowPdfDocumentHandled> {
    let result = false;

    if (this.opened.length > 0) {
      const item = this.opened[this.opened.length - 1].reference;
      const url = item.componentInstance.url;
      const regex = /^(https\:\/\/sites\.dink\.eu\/([a-z0-9\-]+))(\/(index\.html)?)?([#?].+)?$/i;
      const root = regex.exec(url) && RegExp.$1;
      const pdf = `${root}/${data.pdfName}`;

      this.exitFullscreen();

      this.render(<IContent>{
        currentContentVersion: <IContentVersion>{
          contentId: pdf,
          contentType: PublicationType.XOD.toString(),
          startingStoryKey: data.pageNumber.toString(10)
        }
      });

      result = true;
    }

    return <IShowPdfDocumentHandled>{
      eventName: DKPluginEvents.ShowPdfDocumentHandled,
      callbackIdentifier: data.callbackIdentifier,
      result: result,
      message: ''
    };
  }

  private async onShowRemotePdf(data: IShowRemotePdfDocumentRequest): Promise<IShowRemotePdfDocumentHandled> {
    let result = false;

    if (this.opened.length > 0) {
      this.exitFullscreen();

      this.render(<IContent>{
        currentContentVersion: <IContentVersion>{
          contentId: data.url,
          contentType: PublicationType.XOD.toString(),
          startingStoryKey: data.pageNumber.toString(10)
        }
      });

      result = true;
    }

    return <IShowRemotePdfDocumentHandled>{
      eventName: DKPluginEvents.ShowRemotePdfDocumentHandled,
      callbackIdentifier: data.callbackIdentifier,
      result: result,
      message: ''
    };
  }

  private async onFrontendReady(): Promise<null> {
    if (this.current) {
      const instance = this.current.reference.componentInstance;
      instance.frontend = true;
    }

    return null;
  }

  private async onChooseContentSharingOption(data: IChooseContentSharingOptionRequest): Promise<IChooseContentSharingOptionHandled> {
    const type = await this.alert.open<ChooseContentSharingOptionResultType>(
      'Share option',
      'Do you want to share the result to Account Hub or by E-mail?',
      [
        { text: 'E-mail', value: ChooseContentSharingOptionResultType.EMAIL },
        { text: 'Account Hub', value: ChooseContentSharingOptionResultType.ACCOUNT_HUB },
        { text: 'Cancel', value: null, bold: true }
      ]
    );

    console.log('{publication} selected sharing option:', type);

    return <IChooseContentSharingOptionHandled>{
      type,
      eventName: DKPluginEvents.ChooseContentSharingOptionHandled,
      callbackIdentifier: data.callbackIdentifier,
      result: !!type,
      message: !type ? 'User clicked to cancel' : ''
    };
  }

  private async onAddPdfToMicrosite(data: IAddPdfToMicrositeRequest): Promise<IAddPdfToMicrositeHandled> {
    this.dialog.open<ShareDialogComponent>(ShareDialogComponent, {
      width: '1000px',
      panelClass: 'dwa-share-dialog',
      backdropClass: 'dwa-share-dialog-backdrop',
      disableClose: true,
      data
    });

    return <IAddPdfToMicrositeHandled>{
      eventName: DKPluginEvents.AddPdfToMicrositeHandled,
      callbackIdentifier: data.callbackIdentifier,
      result: true,
      message: ''
    };
  }

  private async onStatObjectHandled(data: IPostMessage): Promise<null> {
    if (data.callbackIdentifier === this.current.publication.id) {
      console.log('{publication} save stats', data);
      this.current.reference.close();
    }

    return null;
  }

  private async onScreenshotRequestHandled(data: IScreenshotRequestHandled): Promise<null> {
    if (data.callbackIdentifier === this.current.publication.id) {
      const publication = this.current.publication;
      const list = await this.data.getMail().pipe(take(1)).toPromise();
      const pdf = await this.api.generatePDFFromImage(data.imageData).pipe(take(1)).toPromise();
      const link = await this.api.createAndGetPreviewUrl(pdf, publication.title).pipe(take(1)).toPromise();
      const id = publication.currentContentVersion.id;
      const index = list.findIndex(item => item.currentContentVersion.id === id);

      if (list.length > 0 && index !== -1) {
        list.splice(index, 1);
      }

      publication.currentContentVersion.temporaryLink = link;

      list.push(publication);
      this.data.changeMail(list);

      console.log('{publication} screen shot handled', data);
    }

    return null;
  }

  private exitFullscreen() {
    if (screenful.enabled && screenful.isFullscreen) {
      screenful.exit();
    }
  }

}
