import { Injectable } from '@angular/core';

import { queueScheduler, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { StoredFileService } from '@dink/core/services/stored-file.service';
import { ApiService } from '@dink/core/services/api.service';
import { IContent, IContentDownloadEvent } from '@dink/core/models/content.model';
import { PublicationType } from '@dink/core/enums/publication-type.enum';
import { ContentDownloadEventType } from '@dink/core/enums/content-download-event-type.enum';
import { downloadableTypes } from '@dink/core/helpers/content.helper';
import { OffineStorageType } from '@dink/core/services/file-storage/offline-file-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PublicationDownloadService {

  private MAX_DOC_SIZE_FOR_PREVIEW = 1024 * 1024 * 20; //20MB
  private LARGE_VIDEO_FILE_SIZE = 1024 * 1024 * 50; //50MB
  private NOTIFICATION_DURATION = 10000; //10 secs
  private SIZE_INFO_FILE_NAME = 'size_info.json';
  private downloadQueue: IContent[] = [];
  private downloadEventsSource = new Subject<IContentDownloadEvent>();

  downloadEvents$ = this.downloadEventsSource.asObservable();

  constructor(
    private api: ApiService,
    private fileService: StoredFileService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }


  private markAsDone(contentVersionId: string, isSuccess: boolean) {
    const i = this.downloadQueue.findIndex(x => x.currentContentVersion.id === contentVersionId);
    const content = this.downloadQueue[i];
    console.log(`[publication download service] Download done: ${content.title}, success: ${isSuccess}`);

    this.downloadEventsSource.next({
      eventType: isSuccess ? ContentDownloadEventType.FINISHED : ContentDownloadEventType.FAILED,
      content: content
    });

    this.downloadQueue.splice(i, 1);
    console.log(`[publication download service] Download queue length: ${this.downloadQueue.length}`);

    if (!isSuccess) {
      this.snackBar.open(this.translate.instant('PUBLICATION.ERROR.DESCRIPTION_DOWNLOAD_FAILED', { title: content.title }), this.translate.instant('PUBLICATION.ERROR.ACTION_DISMISS'), {
        duration: this.NOTIFICATION_DURATION,
        panelClass: ['dwa-snackbar-error']
      });
    }
  }

  isCurrentlyDownloading(contentVersionId: string) {
    const i = this.downloadQueue.findIndex(x => x.currentContentVersion.id === contentVersionId);
    return i > -1 ? true : false;
  }

  async addToQueue(content: IContent) {

    if (!navigator.onLine) {
      this.snackBar.open(this.translate.instant('PUBLICATION.ERROR.DESCRIPTION_OFFLINE'), this.translate.instant('PUBLICATION.ERROR.ACTION_DISMISS'), {
        duration: this.NOTIFICATION_DURATION,
        panelClass: ['dwa-snackbar-error']
      });
      return;
    }

    const id = content.currentContentVersion.id;
    if (this.downloadQueue.find(x => x.currentContentVersion.id === id)) {
      //Already queued
      return;
    }

    this.downloadQueue.push(content);

    this.downloadEventsSource.next({
      eventType: ContentDownloadEventType.STARTED,
      content: content
    });

    switch (content.currentContentVersion.contentType) {
      case PublicationType.VIDEO.toString():
        queueScheduler.schedule(async () => {
          const result = await this.downloadAndStoreVideoFile(id);
          this.markAsDone(id, result ? true : false);
        });
        break;
      case PublicationType.DINK_ANGULAR.toString():
        queueScheduler.schedule(async () => {
          const result = await this.downloadAndStoreDinkFile(id);
          this.markAsDone(id, result ? true : false);
        });
        break;
      case PublicationType.PDF.toString():
      case PublicationType.XOD.toString():
        queueScheduler.schedule(async () => {
          const result = await this.downloadAndStoreDocument(id, false);
          this.markAsDone(id, result ? true : false);
        });
        break;
      default:
        queueScheduler.schedule(async () => {
          let result = await this.downloadAndStoreDocument(id, true);
          if (!result) {
            const size = await this.getContentFileSize(id, true, true);
            console.log(`size of document is: ${size}, max is ${this.MAX_DOC_SIZE_FOR_PREVIEW}`);
            if (size > 0) {
              await this.updateContentFileSizeInfo(id, size);
            }
            // Document larger than 20MB do not have a PDF version, so we can't show those.
            // But these docs can be downloaded via the toolbar, so we mark this as a success.
            if (size > this.MAX_DOC_SIZE_FOR_PREVIEW) {
              result = true;
            }
          }
          this.markAsDone(id, result ? true : false);
        });
    }
  }

  async downloadAndStoreVideoFile(contentVersionKey: string): Promise<Blob> {
    const requestUrl = await this.api.getSignedS3UrlForEdition(contentVersionKey).toPromise();
    const blob = await this.fileService.downloadAndStoreFile(`${contentVersionKey}.mp4`, requestUrl, false);
    return blob;
  }

  async downloadAndStoreDinkFile(contentVersionKey: string): Promise<string> {
    const zipRequestUrl = await this.api.getSignedS3UrlForEdition(contentVersionKey).toPromise();
    const zipIndexHandle = await this.fileService.downloadAndStoreFile(contentVersionKey, zipRequestUrl, true);
    return zipIndexHandle;
  }

  async downloadAndStoreDocument(contentVersionKey: string, needsConversion: boolean): Promise<any> {
    const docUrl = await this.getXODUrl(contentVersionKey, needsConversion);
    const xodData = await this.fileService.downloadAndStoreFile(`${contentVersionKey}.xod`, docUrl);
    return xodData;
  }

  async getXODUrl(contentVersionKey: string, isDocument: boolean): Promise<string> {
    const url = await this.api.getSignedS3UrlForEdition(contentVersionKey, isDocument).toPromise();
    const docUrl = await this.api.getXodForPdf(url).toPromise();
    return docUrl;
  }

  async contentIsAvailable(content: IContent): Promise<boolean> {
    let answer = true;
    const contentType = content.currentContentVersion.contentType;
    if (this.fileService.fileStorage
      && this.fileService.fileStorage.isSupported()
      && (downloadableTypes.indexOf(contentType) > -1)
    ) {
      let downloadExists = false;
      let cacheKey = content.currentContentVersion.id;
      if (contentType !== PublicationType.DINK_ANGULAR.toString()) {
        if (contentType === PublicationType.VIDEO.toString()) {
          cacheKey = `${cacheKey}.mp4`;
        } else {
          //Must be an office file or pdf
          cacheKey = `${cacheKey}.xod`;
        }
        downloadExists = await this.fileService.fileStorage.blobExists(cacheKey);
        if (!downloadExists) {
          if (contentType === PublicationType.VIDEO.toString()) {
            if (navigator.onLine) {
              if (this.fileService.fileStorage.getType() !== OffineStorageType.FileSystemAccessAPI) {
                // Video files can be quite large, so quickly checking their size online should be ok
                const size = await this.getContentFileSize(content.currentContentVersion.id, false, true);
                if (size > this.LARGE_VIDEO_FILE_SIZE) {
                  //Video will be accessible quicker if we use streaming, so we consider this as accessible content.
                  //Downloading will happen in parallel.
                  downloadExists = true;
                }
              }
            }
          }
          else if (contentType !== PublicationType.PDF.toString()) {
            const size = await this.getContentFileSize(content.currentContentVersion.id, true, false);
            if (size > this.MAX_DOC_SIZE_FOR_PREVIEW) {
              //See explanation about 20MB restriction earlier in the code
              downloadExists = true;
            }
          }
        }
      } else {
        //DINK_ANGULAR is only supported with file system access api
        if (this.fileService.fileStorage.getType() === OffineStorageType.FileSystemAccessAPI) {
          downloadExists = await this.fileService.fileStorage.zipExists(cacheKey);
        } else {
          //report it as available to allow user to access the content
          downloadExists = true;
        }
      }
      if (downloadExists) {
        answer = true;
      } else {
        answer = false;
      }

    } else {
      answer = true;
    }
    return answer;
  }

  private async getAllContentFileSizeInfo(): Promise<any> {
    const sizeInfoBlob = await this.fileService.fileStorage.getBlob(this.SIZE_INFO_FILE_NAME);
    let sizeInfo = {};
    if (sizeInfoBlob) {
      // @ts-ignore
      const sizeInfoText = await sizeInfoBlob.text();
      sizeInfo = JSON.parse(sizeInfoText);
    }
    return sizeInfo;
  }

  private async updateContentFileSizeInfo(contentVersionKey: string, size: number) {
    const sizeInfo = await this.getAllContentFileSizeInfo();
    sizeInfo[contentVersionKey] = size;
    const updatedBlob = new Blob([JSON.stringify(sizeInfo)]);
    await this.fileService.fileStorage.saveBlob(this.SIZE_INFO_FILE_NAME, updatedBlob);
  }

  private async getContentFileSize(contentVersionKey: string, isDocument: boolean, checkOnline: boolean): Promise<number> {
    const sizeInfo = await this.getAllContentFileSizeInfo();
    if (sizeInfo.hasOwnProperty(contentVersionKey)) {
      return sizeInfo[contentVersionKey];
    } else {
      if (checkOnline) {
        const size = await this.getRemoteContentFileSize(contentVersionKey, isDocument);
        if (size > 0) {
          await this.updateContentFileSizeInfo(contentVersionKey, size);
        }
        return size;
      } else {
        return -1;
      }
    }
  }

  private async getRemoteContentFileSize(contentVersionKey: string, isDocument: boolean): Promise<number> {
    let size = -1;
    try {
      const url = await this.api.getSignedS3UrlForEdition(contentVersionKey, isDocument).toPromise();

      const rangeHeader = new Headers({
        'Content-Range': 'bytes 0-1/*'
      });

      const response = await fetch(url, {
        headers: rangeHeader
      });

      size = parseInt(response.headers.get('content-length'), 10);
    } catch (error) {
      console.log(error.message);
    }
    return size;
  }


}
