import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seconds'
})
export class SecondsPipe implements PipeTransform {

  transform(origin: number): string {
    const minutes = Math.floor(origin / 60);
    const seconds = origin - minutes * 60;
    const padding = ('0' + seconds).substr(-2);

    return `${minutes}:${padding}`;
  }

}
