import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { environment } from '@dink/env/environment';

import { AuthService } from '@dink/core/services/auth.service';


@Pipe({
  name: 'buildUrl'
})
export class BuildUrlPipe implements PipeTransform {

  constructor(private auth: AuthService) {
  }


  transform(original: string): Observable<string> {
    const porfolio = environment.portfolio.manager;

    return this.auth.getUserData().pipe(
      take(1),
      map(ids => {
        return original
          .replace(/\{portfolio\}/g, porfolio)
          .replace(/\{enterprise\}/g, ids.enterprise)
          .replace(/\{user\}/g, ids.user);
      })
    );
  }

}
