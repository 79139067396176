import { Injectable } from '@angular/core';

enum PersistenceResult {
  Never = "never",
  Persisted = "persisted",
  Prompt = "prompt"
}

export enum OffineStorageType {
  IndexedDB,
  FileSystemAccessAPI
}

@Injectable({
  providedIn: 'root'
})
export abstract class OfflineFileStorageService {
  abstract saveBlob(cacheKey: string, blob: Blob): Promise<Boolean>;
  abstract saveZip(cacheKey: string, blob: Blob): Promise<any>
  abstract blobExists(cacheKey: string): Promise<boolean>;
  abstract zipExists(cacheKey: string): Promise<boolean>;
  abstract getBlob(cacheKey: string): Promise<Blob>;
  abstract getZipIndexHandle(cacheKey: string): Promise<any>;
  abstract copyToken(token:string): Promise<any>;
  abstract deleteBlob(cacheKey: string): Promise<Boolean>;
  abstract deleteAllBlobs(): Promise<Boolean>;
  abstract isSupported(): Boolean;
  abstract getType(): OffineStorageType;
  abstract deleteOldestBlob(): Promise<Boolean>;

  async getRemainingAvailableStorage(): Promise<Number> {
    // Attention: this usually only works over https,
    // See https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/estimate
    let remaining = -1;
    if (navigator.storage && navigator.storage.estimate) {
      const quota = await navigator.storage.estimate();
      // quota.usage -> Number of bytes used.
      // quota.quota -> Maximum number of bytes available.
      const percentageUsed = (quota.usage / quota.quota) * 100;
      console.log(`[storage service] You've used ${percentageUsed}% of the available storage.`);
      remaining = quota.quota - quota.usage;
      console.log(`[storage service] You can write up to ${remaining} more bytes.`);
    }
    return remaining;
  }

  async requestQuotaIncrease() {
    const requestedBytes = 1024 * 1024 * 1024 * 3; // 3GB

    // @ts-ignore
    if (navigator.webkitPersistentStorage && navigator.webkitPersistentStorage.requestQuota) {
      // @ts-ignore
      navigator.webkitPersistentStorage.requestQuota(
        requestedBytes, function (grantedBytes) {
          console.log("granted: " + grantedBytes);

        }, function (e) { console.log('Error', e); }
      );
    }
  }

  // Persistence applies to all site storage, for more on persistence see:
  // - https://dexie.org/docs/StorageManager,
  // - https://developer.chrome.com/docs/apps/offline_storage/
  // - https://web.dev/persistent-storage/
  //
  // Persistence in Chrome is tricky, and requires the user to allow 
  // notifications and popups in the site settings;
  // for example: https://stackoverflow.com/questions/51657388/request-persistent-storage-permissions

  async isStoragePersisted(): Promise<boolean | undefined> {
    if (navigator.storage && navigator.storage.persist) {
      const isPersisted = await navigator.storage.persisted();
      console.log(`Persisted storage granted: ${isPersisted}`);
      return isPersisted;
    } else {
      return undefined;
    }
  }

  async persist(): Promise<boolean | undefined> {
    if (navigator.storage && navigator.storage.persist) {
      const isPersisted = await navigator.storage.persist();
      console.log(`Persisted storage granted: ${isPersisted}`);
      return isPersisted;
    } else {
      return undefined;
    }
  }

  async tryPersistWithoutPromptingUser(): Promise<PersistenceResult> {
    if (!navigator.storage || !navigator.storage.persisted) {
      return PersistenceResult.Never;
    }
    let persisted = await navigator.storage.persisted();
    if (persisted) {
      return PersistenceResult.Persisted;
    }
    // @ts-ignore
    if (!navigator.permissions || !navigator.permissions.query) {
      return PersistenceResult.Prompt; // It MAY be successful to prompt. Don't know.
    }
    // @ts-ignore
    const permission = await navigator.permissions.query({
      name: "persistent-storage"
    });
    if (permission.state === "granted") {
      persisted = await navigator.storage.persist();
      if (persisted) {
        return PersistenceResult.Persisted;
      } else {
        throw new Error("Failed to persist");
      }
    }
    if (permission.state === "prompt") {
      return PersistenceResult.Prompt;
    }
    return PersistenceResult.Never;
  }

  async initStoragePersistence() {
    const persist = await this.tryPersistWithoutPromptingUser();
    switch (persist) {
      case PersistenceResult.Never:
        console.log("Not possible to persist storage");
        break;
      case PersistenceResult.Persisted:
        console.log("Successfully persisted storage silently");
        break;
      case PersistenceResult.Prompt:
        console.log("Not persisted, but we may prompt user when we want to.");
        break;
    }
  }
}