<input #input id="md-input-file" class="file" type="file" [accept]="accept" (change)="onChange($event)" multiple>
<div class="dropzone{{typeUploader || ''}}" [class.dragged]="dragged" [class.uploading]="uploading">
  <div class="dash-uploader-wrapper{{typeUploader || ''}}">
    <ng-container *ngIf="!uploading; else isUploading">
      <!-- <mat-icon class="icon">cloud_upload</mat-icon> -->
      <p>{{message || 'UPLOAD_PICTURE.DRAG_MESSAGE' | translate}} {{typeMessage}}</p>
    </ng-container>
    <ng-template #isUploading>
      uploading...
    </ng-template>
  </div>
</div>
<div class="filename" *ngIf="result !== '' && !typeUploader">{{result}}</div>
<div class="info" *ngIf="!typeUploader">
  <span *ngIf="uploading" class="info-progress">{{percentage}}%</span>
  <!-- <button mat-button mat-icon-button matSuffix *ngIf="!empty && !uploading" class="clear" (click)="onClear()">
      <mat-icon>close</mat-icon>
    </button> -->
</div>
