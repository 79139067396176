import { Pipe, PipeTransform } from '@angular/core';

import { ApiService } from '@dink/core/services/api.service';


@Pipe({
  name: 'fixImage'
})
export class FixImagePipe implements PipeTransform {

  constructor(private api: ApiService) {
  }


  transform(origin: string): string {
    if (origin) {
      if (!origin.startsWith('http')) {
        const slash = !origin.startsWith('/') ? '/' : '';
        origin = `${this.api.server}${slash}${origin}`;
      }

      return origin.replace(/^http\:\/\//g, 'https://');
    }

    return '';
  }

}
