import { Component, OnInit, OnDestroy, HostBinding, ViewChild, ElementRef, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { take, debounceTime } from 'rxjs/operators';

import { environment } from '@dink/env/environment';

import { DataService } from '@dink/core/services/data.service';
import { ApiService } from '@dink/core/services/api.service';
import { IEnterprise } from '@dink/core/models/enterprise.model';
import {
  IMicrositeCreationRequest, IMicrositeCreationContent, IMicrositeCreationCustomer,
  IMicrositeCacheItem, IMicrositeCreationAsset, IMicrositeMessage, IMicrositeFile
} from '@dink/core/models/account-hub.model';
import { CacheService } from '@dink/core/services/cache.service';
import { IAddPdfToMicrositeRequest } from '@dink/core/models/post-message.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';


const SNACK_OPTIONS = {
  announcementMessage: 'test',
  panelClass: 'dwa-login-error',
  duration: 5000
};


@Component({
  selector: 'dwa-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.sass']
})
export class ShareDialogComponent implements OnInit, OnDestroy {

  first = true;
  loading = false;
  valid = false;
  preview = false;
  uploading = {};
  model = <IMicrositeMessage>{ customers: [], language: '', message: '' };
  message$ = new BehaviorSubject<string>('');
  enterprise$: Observable<IEnterprise>;
  items$: Observable<IMicrositeCacheItem[]>;
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  @HostBinding('class.dwa-share-dialog-component') show = true;

  private subscription = new Subscription();

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      [
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };


  constructor(
    private data: DataService,
    private cache: CacheService,
    private api: ApiService,
    private detector: ChangeDetectorRef,
    private dialog: MatDialogRef<ShareDialogComponent>,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private input: IAddPdfToMicrositeRequest
  ) {
  }


  ngOnInit() {
    this.enterprise$ = this.data.getEnterprise();
    this.items$ = this.data.getShared();


    this.subscription.add(this.message$.pipe(debounceTime(500)).subscribe(() => {
      this.saveToCache();
    }));

    this.subscription.add(this.dialog.backdropClick().subscribe(click => {
      if (Object.keys(this.uploading).length > 0) {
        this.snack.open('Upload in progress...', 'CLOSE', SNACK_OPTIONS);
      } else {
        this.close();
      }
    }));


    this.cache.message$.pipe(take(1)).subscribe(message => {
      if (message) {
        this.model = message;

        if (!('customers' in message)) {
          this.model.customers = [<IMicrositeCreationCustomer>{ email: '', firstName: '', lastName: '' }];
        }
      } else {
        this.model.customers = [<IMicrositeCreationCustomer>{ email: '', firstName: '', lastName: '' }];
      }

      this.checkIfIsValid();
    });


    if (this.input) {
      const file = this.input;

      console.log('{share-dialog} auto upload', { file });

      this.doUpload(<IMicrositeFile>{
        name: file.fileName,
        base64: file.base64Data,
        type: file.mimeType
      }, true);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  onChangeCustomer() {
    this.message$.next(this.model.message);
    this.checkIfIsValid();
  }

  onRemoveCustomer(id: number) {
    this.model.customers = this.model.customers.filter((e, i) => i !== id);

    this.saveToCache();
    this.checkIfIsValid();
  }

  onAddCustomer() {
    this.model.customers.push(<IMicrositeCreationCustomer>{ email: '', firstName: '', lastName: '' });

    this.saveToCache();
    this.checkIfIsValid();
  }

  onChangeLanguage() {
    this.saveToCache();
    this.checkIfIsValid();
    this.getTemplate();
  }

  onChangeText() {
    this.message$.next(this.model.message);
  }

  trackBy(index: number): number {
    return index;
  }

  async onPreview() {
    this.preview = true;

    let message = await this.api.getAccountHubTemplate(this.model.language).toPromise();
    const element = this.iframe.nativeElement.contentWindow.document;

    const [enterprise, profile, items] = await Promise.all([
      this.data.getEnterprise().pipe(take(1)).toPromise(),
      this.api.getProfile().pipe(take(1)).toPromise(),
      this.items$.pipe(take(1)).toPromise()
    ]);

    message = message.replace(/\{\{ custom_message \}\}/g, this.model.message);
    message = message.replace(/\{\{ user_name \}\}/g, profile.name);
    message = message.replace(/\{\{ enterprise_name \}\}/g, enterprise.name);
    message = message.replace(/\{\{ publication_names \}\}/g, items.map(i => i.title).join(', '));
    message = message.replace(/\{\{ customer_name \}\}/g, this.model.customers[0].firstName + ' ' + this.model.customers[0].lastName);
    message = message.replace(/\{\{ account_hub_url \}\}/g, '#');

    element.open();
    element.write(message);
    element.close();
  }

  async onSubmit() {
    this.loading = true;

    const model = <IMicrositeCreationRequest>{ message: this.model.message, enterpriseLanguageId: this.model.language };
    const customers = this.model.customers;
    const items = await this.items$.pipe(take(1)).toPromise();

    model.contents = items.filter(c => c.type === 'content').map(c => (<IMicrositeCreationContent>{
      contentVersionId: c.id,
      comment: '',
      isSecured: c.isSecured
    }));

    model.assets = items.filter(c => c.type === 'asset').map(c => (<IMicrositeCreationAsset>{
      accountHubAssetId: c.id,
      comment: '',
      isSecured: c.isSecured
    }));

    model.customers = customers.map(c => (<IMicrositeCreationCustomer>{
      email: c.email,
      firstName: c.firstName,
      lastName: c.lastName,
      useAccountInfo: false
    }));

    model.keepOriginalMessage = true;

    try {
      await this.api.createMicrosite(model).toPromise();

      this.data.changeShared([]);
      this.cache.updateMessage(null);

      this.close();
    } catch (err) {
      this.snack.open('Error while creating a new Microsite!', 'CLOSE', SNACK_OPTIONS);
    }

    this.loading = false;
  }

  async onDelete(index: number) {
    const shared = await this.data.getShared().pipe(take(1)).toPromise();

    shared.splice(index, 1);
    this.data.changeShared(shared);

    if (shared.length > 0) {
      if (index in this.uploading) {
        delete this.uploading[index];
      }

      const uploads = Object.keys(this.uploading);

      if (uploads.length > 0) {
        this.uploading = uploads
          .map(item => ({ data: this.uploading[item], index: parseInt(item, 10) }))
          .map(item => ({ ...item.data, index: item.index < index ? item.index : item.index - 1 }))
          .reduce((a, b) => {
            a[b.index] = { progress: b.progress, rand: b.rand };
            return a;
          }, {});
      }

      this.checkIfIsValid();
    } else {
      this.close();
    }
  }

  async toggleLock(index: number) {
    const shared = await this.data.getShared().pipe(take(1)).toPromise();

    shared[index].isSecured = !shared[index].isSecured;

    this.data.changeShared(shared);
  }

  async onUpload(event: Event) {
    const upload = <HTMLInputElement>event.target;
    const file = upload.files[0];
    const max = environment.api.methods.accountHub.limit;

    if (file.size <= max) {
      this.doUpload(file);
    } else {
      const human = Math.round(max / 1024 / 1024);
      this.snack.open(`The file is bigger than ${human}MB!`, 'CLOSE', SNACK_OPTIONS);
    }
  }


  private close() {
    this.dialog.close();
  }

  private async updateStatus(rand: string, { id = null, progress = -1 }) {
    const indexes = Object.keys(this.uploading)
      .map(item => ({ index: item, data: this.uploading[item] }))
      .filter(item => item.data.rand === rand)
      .map(item => item.index);

    if (indexes.length > 0) {
      const index = indexes[0];

      if (id) {
        const shared = await this.data.getShared().pipe(take(1)).toPromise();

        shared[index].id = id;
        this.data.changeShared(shared);

        delete this.uploading[index];

        this.checkIfIsValid();
      } else if (progress !== -1) {
        this.uploading[index].progress = progress;
      }
    }
  }

  private async doUpload(file: File | IMicrositeFile, base64: boolean = false) {
    const shared = await this.data.getShared().pipe(take(1)).toPromise();
    const index = shared.length;
    const rand = (0x1000 + Math.round(Math.random() * 0xefff)).toString(16);


    shared.push(<IMicrositeCacheItem>{
      id: '',
      title: file.name,
      isSecured: false,
      comment: null,
      type: 'asset'
    });


    this.data.changeShared(shared);
    this.checkIfIsValid();


    this.uploading[index] = { rand, progress: 0 };

    this.api.uploadAsset(file, base64).subscribe(response => {
      if (!response.data) {
        console.log(`{share-dialog} uploading ${response.progress}%`);
        this.updateStatus(rand, { progress: response.progress });
      } else {
        console.log('{share-dialog} UPLOADED', response.data);
        this.updateStatus(rand, { id: response.data.id });
      }
    });
  }

  private checkIfIsValid() {
    const customers = this.model.customers;
    const language = this.model.language;
    const errors = customers.map(c => c.firstName.length > 1 && c.email.length > 2 ? 0 : 1);
    const uploads = Object.keys(this.uploading).length;

    this.valid = !!language && errors.reduce((a, b) => a + b, 0) === 0 && uploads === 0;

    this.detector.markForCheck();
  }

  private saveToCache() {
    this.cache.updateMessage(this.model);
  }

  async getTemplate() {
    this.model.message = '';
    let message = await this.api.getAccountHubTemplate(this.model.language).toPromise();

    message = message.replace(/(\\r|\\n)/g, '');
    message = message.substring(1);
    message = message.slice(0, -1);

    const [enterprise, profile, items] = await Promise.all([
      this.data.getEnterprise().pipe(take(1)).toPromise(),
      this.api.getProfile().pipe(take(1)).toPromise(),
      this.items$.pipe(take(1)).toPromise()
    ]);

    message = message.replace(/\{\{ custom_message \}\}/g, this.model.message);
    message = message.replace(/\{\{ user_name \}\}/g, profile.name);
    message = message.replace(/\{\{ enterprise_name \}\}/g, enterprise.name);
    message = message.replace(/\{\{ publication_names \}\}/g, items.map(i => i.title).join(', '));
    message = message.replace(/\{\{ customer_name \}\}/g, this.model.customers[0].firstName + ' ' + this.model.customers[0].lastName);
    message = message.replace(/\{\{ account_hub_url \}\}/g, '#');

    this.model.message = message;
  }
}
