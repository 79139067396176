const DATE_REGEX = /^(\d{4}\-\d{2}\-\d{2})T(\d{2}\:\d{2}:\d{2}(\.\d+)?)Z$/;


export function convertToDate(original: string): Date | any {
  if (original) {
    const iso = DATE_REGEX.exec(original);

    if (iso) {
      const [ , date, time ] = iso;
      return new Date(`${date}T${time}Z`);
    }
  }

  return original;
}

export function checkDatesInJSON<T>(value: any): T {
  const each = obj => {
    Object.keys(obj).forEach(key => {
      const prop = obj[key];
      const type = typeof prop;

      if (prop) {
        if (type === 'object') {
          each(prop);
        } else if (type === 'string') {
          obj[key] = convertToDate(prop);
        }
      }
    });
  };

  each(value);

  return value;
}
